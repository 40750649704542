import React from "react";
import ValueOf from "../../components/ValueOf";
import {useTranslation} from "react-i18next";
import {ReferralInfo} from "@services/referral-api";

export interface ReferralStatisticsProps {
  referralInfo: ReferralInfo;
}

export default function ReferralLevelStatistics({referralInfo}: ReferralStatisticsProps) {
  const {t} = useTranslation();

  const cardBlocks = [
    <ValueOf
      value={referralInfo.firstLineReferrals}
      text={t('pages.referrals.statistics.referrals') + ': '}
      valueClass="tx-primary"
    />,
    <ValueOf
      value={referralInfo.totalReferrals}
      text={t('pages.referrals.statistics.invited') + ': '}
      valueClass="tx-primary"
    />,
    <ValueOf
      value={referralInfo.personalDeposit}
      text={t('pages.referrals.statistics.personalDeposit') + ': '}
      format={true}
      sign={'$'}
      valueClass="tx-primary"
    />,
    <ValueOf
      value={referralInfo.referralsDeposit}
      text={t('pages.referrals.statistics.referralsDeposit') + ': '}
      format={true}
      sign={'$'}
      valueClass="tx-primary"
    />,
    <ValueOf
      value={referralInfo.totalTurnover}
      text={t('pages.referrals.statistics.totalTurnover') + ': '}
      format={true}
      sign={'$'}
      valueClass="tx-primary"
    />,
  ];

  return (
    <div className="card wd-100p wd-md-auto align-self-start d-inline-block mt-4 mb-4 mr-md-4">
      <div className="card-body">
        <h2>{t('pages.referrals.statistics.title')}</h2>

        {cardBlocks.map((el, index) => (
          <div key={'statistics-' + index}>{el}</div>
        ))}
      </div>
    </div>
  )
}
