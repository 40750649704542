import React, {ComponentType} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NotFound from "@pages/NotFound";
import SettingsPage from "@pages/account/settings/SettingsPage";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import WalletPage from "@pages/account/wallet/WalletPage";
import {faWallet} from "@fortawesome/free-solid-svg-icons";
import DepositPage from "@pages/account/deposit/DepositPage";
import {faDonate} from "@fortawesome/pro-solid-svg-icons";
import WithdrawalPage from "@pages/account/withdrawal/WithdrawalPage";
import {faHandHoldingUsd} from "@fortawesome/pro-regular-svg-icons/faHandHoldingUsd";
import ContractsPage from "@pages/account/contracts/ContractsPage";
import {faFileContract} from "@fortawesome/pro-solid-svg-icons/faFileContract";
import BuyContractPage from "@pages/account/contracts/BuyContractPage";
import ReferralsPage from "@pages/account/referrals/ReferralsPage";
import {faUsers} from "@fortawesome/pro-regular-svg-icons/faUsers";
import ReferralLevelPage from "@pages/account/referrals/ReferralLevelPage";
import P2pPage from "@pages/account/p2p/P2pPage";
import {faExchangeAlt} from "@fortawesome/pro-regular-svg-icons/faExchangeAlt";

export interface Page {
  id: string;
  component: ComponentType<any>;
  title: string;
  link: LandingLinks | AccountLinks | string;
  icon: JSX.Element;
  enabled: boolean;
  inSidenav: boolean;
}

export enum SocialRoutes {
  INSTAGRAM = '/instagram',
  YOUTUBE = '/youtube',
  TELEGRAM = '/telegram',
  FACEBOOK = '/facebook',
  VK = '/vkontakte',
  LINKED_IN = '/linkedin',
  MEDIUM = '/medium',
  TWITTER = '/twitter',
  REDDIT = '/reddit',
  TIKTOK = '/tiktok',
  BITCOIN_TALK = '/bitcoin-talk',
}

export enum SocialLinks {
  INSTAGRAM = 'https://instagram.com/aurous.finance',
  YOUTUBE = 'https://www.youtube.com/@aurous.finance',
  TELEGRAM = 'https://t.me/aurous_finance_official',
  FACEBOOK = 'http://www.facebook.com/groups/aurous.finance',
  VK = 'https://vk.com/aurous.finance',
  BITCOIN_TALK = 'https://bitcointalk.com/user/',
  LINKED_IN = 'https://www.linkedin.cn/',
  MEDIUM = 'https://medium.com/',
  TWITTER = 'https://twitter.com/aurous_finance',
  REDDIT = 'https://www.reddit.com/',
  TIKTOK = 'https://www.tiktok.com/@aurous.finance',
  SUPPORT = '',
}

export enum LandingLinks {
  HOME = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  REGISTER_CONFIRM = '/register/confirm',
  FORGOT = '/forgot',
  PASSWORD_RESET = '/password/reset',
  ERROR = '/error',
  NOT_FOUND = '/404',
  NEWS = '/news/:slug',
}

export enum AccountLinks {
  WALLET = '/wallet',
  BUY = '/buy',
  DEPOSIT = '/deposit',
  WITHDRAWAL = '/withdrawal',
  CONTRACTS = '/contracts',
  BUY_CONTRACT = '/contracts/buy',
  REFERRALS = '/referrals',
  REFERRALS_LEVEL = '/referrals/level/:level',
  SETTINGS = '/settings',
  P2P = '/p2p',
}

function getPage(pageId: AccountLinks):Page {
  const page: Page = {
    id: 'not-found',
    component: NotFound,
    title: 'error.not-found',
    link: '/404',
    icon: <></>,
    enabled: true,
    inSidenav: true,
  };

  switch (pageId) {
    case AccountLinks.WALLET:
      page.id = 'wallet';
      page.component = WalletPage;
      page.title = 'pages.wallet.title';
      page.icon = <FontAwesomeIcon icon={faWallet}/>;
      break;
    case AccountLinks.DEPOSIT:
      page.id = 'deposit';
      page.component = DepositPage;
      page.title = 'pages.deposit.title';
      page.icon = <FontAwesomeIcon icon={faDonate}/>;
      break;
    case AccountLinks.WITHDRAWAL:
      page.id = 'withdrawal';
      page.component = WithdrawalPage;
      page.title = 'pages.withdrawal.title';
      page.icon = <FontAwesomeIcon icon={faHandHoldingUsd}/>;
      break;
    case AccountLinks.CONTRACTS:
      page.id = 'contracts';
      page.component = ContractsPage;
      page.title = 'pages.contracts.title';
      page.icon = <FontAwesomeIcon icon={faFileContract}/>;
      break;
    case AccountLinks.BUY_CONTRACT:
      page.id = 'buy-contract';
      page.component = BuyContractPage;
      page.title = 'pages.buy-contract.title';
      page.icon = <FontAwesomeIcon icon={faFileContract}/>;
      page.inSidenav = false;
      break;
    case AccountLinks.REFERRALS:
      page.id = 'referrals';
      page.component = ReferralsPage;
      page.title = 'pages.referrals.title';
      page.icon = <FontAwesomeIcon icon={faUsers}/>;
      break;
    case AccountLinks.REFERRALS_LEVEL:
      page.id = 'referrals-level';
      page.component = ReferralLevelPage;
      page.title = 'pages.referrals-level.title';
      page.icon = <FontAwesomeIcon icon={faUsers}/>;
      page.inSidenav = false;
      break;
    case AccountLinks.SETTINGS:
      page.id = 'settings';
      page.component = SettingsPage;
      page.title = 'pages.settings.title';
      page.icon = <FontAwesomeIcon icon={faCog}/>;
      break;
    case AccountLinks.P2P:
      page.id = 'p2p';
      page.component = P2pPage;
      page.title = 'pages.p2p.title';
      page.icon = <FontAwesomeIcon icon={faExchangeAlt}/>;
      break;
  }

  if (page.title !== 'error.not-found') {
    page.link = pageId;
  }

  return page;
}

export const AccountPages: Page[] = [
  getPage(AccountLinks.WALLET),
  getPage(AccountLinks.DEPOSIT),
  getPage(AccountLinks.WITHDRAWAL),
  getPage(AccountLinks.CONTRACTS),
  getPage(AccountLinks.BUY_CONTRACT),
  getPage(AccountLinks.REFERRALS),
  getPage(AccountLinks.REFERRALS_LEVEL),
  getPage(AccountLinks.SETTINGS),
  getPage(AccountLinks.P2P),
];

export const socialPages: Array<{path: SocialRoutes; link: SocialLinks}> = [
  {path: SocialRoutes.INSTAGRAM, link: SocialLinks.INSTAGRAM},
  {path: SocialRoutes.FACEBOOK, link: SocialLinks.FACEBOOK},
  {path: SocialRoutes.TWITTER, link: SocialLinks.TWITTER},
  {path: SocialRoutes.LINKED_IN, link: SocialLinks.LINKED_IN},
  {path: SocialRoutes.REDDIT, link: SocialLinks.REDDIT},
  {path: SocialRoutes.TELEGRAM, link: SocialLinks.TELEGRAM},
  {path: SocialRoutes.TIKTOK, link: SocialLinks.TIKTOK},
  {path: SocialRoutes.MEDIUM, link: SocialLinks.MEDIUM},
  {path: SocialRoutes.BITCOIN_TALK, link: SocialLinks.BITCOIN_TALK},
  {path: SocialRoutes.YOUTUBE, link: SocialLinks.YOUTUBE},
  {path: SocialRoutes.VK, link: SocialLinks.VK},
];
