import React from 'react';
import {Helmet} from "react-helmet-async";
import MainScreen from "@pages/landing/home/components/MainScreen";
import AboutUs from "@pages/landing/home/components/AboutUs";
import Ecosystem from "@pages/landing/home/components/Ecosystem";
import RoadMap from "@pages/landing/home/components/RoadMap";
import OurTeam from "@pages/landing/home/components/OurTeam";
import Partners from "@pages/landing/home/components/Partners";
import NewsSlider from "@pages/landing/home/components/NewsSlider";
import Faq from "@pages/landing/home/components/Faq";
import DocAndVideo from "@pages/landing/home/components/DocumentAndVideo";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Aurous.Finance</title>
      </Helmet>
      <div id="home">
        <MainScreen/>
        <AboutUs/>
        <Ecosystem/>
        <RoadMap/>
        {/* <OurTeam/> */}
        <DocAndVideo/>
        <Partners/>
        <NewsSlider/>
        <Faq/>
      </div>
    </>
  );
}
