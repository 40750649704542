import React from "react";
import Translate from "@components/Translate";
import pic from "@assets/images/ecosystem.png";

export default function Ecosystem() {
  return (
    <section className="pos-relative" id="ecosystem">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 pl-0 order-2 order-md-1">
            <img src={pic} alt="Aurous Ecosystem"/>
          </div>

          <div className="col-md-6 d-flex align-items-center order-1 order-md-2">
            <div className="wd-md-450 mb-md-5 pb-md-5">
              <h2 className="mb-5 tx-lg-48">
                <Translate i18nKey={'pages.home.content.ecosystem.title'}/>
              </h2>
              <div className="mb-5">
                <Translate i18nKey={'pages.home.content.ecosystem.text'}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
