import {API_URL, handleData, handleGet, Network, PromiseStatus, Ticker} from "@services/api";
import fetchWithTimeout from "@util/fetchWithTimeout";

export interface WithdrawalInfo {
  currency: Ticker;
  feePercents: number;
  minWithdrawalAmount: number;
  metadata: {
    untilNextWithdrawal?: number;
  }
}

export interface WithdrawalData {
  currency: Ticker;
  amount: number;
  address: string;
  crypto: Ticker.BTC;
  network: Network.BITCOIN;
  tfaCode: string;
}

export interface WithdrawalInfoData {
  currency: Ticker;
}

export function getWithdrawalInfo({currency}: WithdrawalInfoData):PromiseStatus<WithdrawalInfo> {
  return fetchWithTimeout(API_URL + '/api/v1/wallet/withdrawal/info/' + currency, {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function cancelWithdrawal(trxId: number):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/transactions/withdrawal/' + trxId, {
    method: 'DELETE',
  }).then(data => handleData(data));
}

export function withdraw(data: WithdrawalData):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/wallet/withdrawal', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}
