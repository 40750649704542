import React, {useEffect, useState} from "react";
import Translate from "@components/Translate";
import FormattedNumber from "@components/FormattedNumber";
import {useTranslation} from "react-i18next";
import {CurrencyPrice, getCurrencyPriceList, CurrencyPrice2, getPriceList} from "@services/wallet-api";

export default function AssetsPrice() {
  const {t} = useTranslation();
  const [, setList] = useState<CurrencyPrice[]>([]);
  const [posts, setPosts] = useState<CurrencyPrice2[]>([]);

  useEffect(() => {
    getCurrencyPriceList()
      .then((response) => {
        if (response.success && response.data) {
          setList(response.data);
        }
      });
  }, []);

  useEffect(() => {
    getPriceList()
      .then((response) => {
        if (response.success && response.data) {
          setPosts(response.data);
        }
      });
  }, []);

  // const goldPrice = list.find((c) => c.currency === Ticker.GMT)?.usdPrice;
  // const aftPrice = list.find((c) => c.currency === Ticker.ASG)?.usdPrice;

  const aftPrice = posts.aufPrice || 0;
  const goldPrice = posts.gatPrice || 0;


  return (
    <section className="container-fluid bg-carbon" id="assets-price">
      <div className="container-lg">
        <div className="row pb-1 tx-center">
          <div className="col-md-4 justify-content-md-center tx-18 d-flex flex-column">
            <span className="pt-3"><Translate i18nKey={'pages.home.content.asg-asset-value'}/></span>
            <span className="tx-32 tx-lg-40 pt-md-1">
              {/* 0.1$ */}
              {aftPrice} $
              {/* <FormattedNumber value={aftPrice} suffix={'$ '}/> */}

              {/* <Popup
                tooltip={t('pages.home.content.asg-info')}
                iconClass="tx-16 tx-20 ml-2"
              /> */}
            </span>
          </div>

          <div className="col-md-4 justify-content-md-center tx-18 d-flex flex-column">
            <span className="pt-3"><Translate i18nKey={'pages.home.content.gmt-asset-value'}/></span>
            <span className="tx-32 tx-lg-40 pt-md-1">
              {goldPrice} $
              {/* <FormattedNumber value={goldPrice} suffix={'$ '}/> */}
            </span>
          </div>

          <div className="col-md-4 tx-18 d-flex flex-column">
            <span className="pt-3"><Translate i18nKey={'pages.home.content.gold-price'}/></span>
            <span className="tx-24 tx-lg-30 pt-2">
              {/*{(goldPrice * 100.121).toFixed(2)}$*/}
              <FormattedNumber value={goldPrice * 100} postfix={' $'} decimals={2}/>
              <sub>/{t('common.gram')}</sub>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
