import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {Link} from "react-router-dom";
import {AccountLinks} from "@pages/pages";
import useResponseHandler from "@hooks/useResponseHandler";
import {Contract, getContracts} from "@services/contracts-api";
import Preloader from "@components/Preloader";
import ContractItem from "@pages/account/contracts/components/ContractItem";
import Translate from "@components/Translate";

export default function ContractsPage() {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getContracts()
      .then((response) => {
        if (response.success && response.data) {
          setContracts(response.data.content);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [handleResponse]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.contracts.title')}</title>
      </Helmet>

      <div className="d-md-flex justify-content-between mb-md-4">
        <h1 className="mb-2 mb-md-0">{t('pages.contracts.title')}</h1>

        <Link to={AccountLinks.BUY_CONTRACT} className="btn btn-gold align-self-start mb-4 mb-md-0">
          <FontAwesomeIcon icon={faPlus} className="mr-2"/>
          {t('button.buy-contract')}
        </Link>
      </div>

      {isLoading && <Preloader inline={true}/>}

      {!isLoading && contracts.length > 0 && (
        <div className="table-wrapper">
          <table className="table table-striped mb-0">
            <thead>
              <tr>
                <th>{t('common.contract-template-name')}</th>
                <th>{t('common.status')}</th>
                <th>{t('common.cost')}</th>
                <th>{t('common.buy-date')}</th>
                <th>{t('common.ending')}</th>
                <th>{t('common.monthly-profit')} %</th>
              </tr>
            </thead>
            <tbody>
              {contracts.map((c) => (
                <ContractItem entry={c} key={c.id}/>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!isLoading && !contracts.length && (
        <div className="tx-bold">
          {t('common.no-contracts')}
        </div>
      )}

      <div className="mt-4">
        <Translate i18nKey={'pages.contracts.info'}/>
      </div>
    </>
  );
}
