import React from "react";
import logo1 from "@assets/images/partners/logo-grow-bank.png";
import logo2 from "@assets/images/partners/logo-morgan-space.png";
import logo3 from "@assets/images/partners/logo-jinpeng.png";
import logo4 from "@assets/images/partners/logo-xinhai.png";
import logo5 from "@assets/images/partners/logo-sudan.png";
import Translate from "@components/Translate";

export default function Partners() {
  const partnersLogo = [
    // {
    //   image: logo1,
    //   title: 'Grow Bank',
    // },
    {
      image: logo2,
      title: 'Morgan Space',
    },
    {
      image: logo3,
      title: 'Jinpeng',
    },
    {
      image: logo4,
      title: 'Xinhai',
    },
    {
      image: logo5,
      title: 'Sudan',
    },
  ];

  return (
    <section className="pt-lg-7 pb-lg-4 pt-md-2 pb-md-4 pt-sm-4 pb-sm-4 pt-4 pb-4" id="partners">
      <div className="container">
        <h2 className="mb-5"><Translate i18nKey={'pages.home.content.partners.title'}/></h2>

        <div className="d-flex justify-content-around justify-content-lg-between flex-wrap" id="partners-list">
          {partnersLogo.map(({image, title}, key) => (
            <div className="card flex-1 ht-100 ht-md-120 mb-4" key={'partners-logo-' + key}>
              <div className="card-body d-flex justify-content-center align-items-center">
                <img src={image} alt={title}/>
              </div>
            </div>
          ))}
        </div>

      </div>
    </section>
  );
}
