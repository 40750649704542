import {ContractTemplate, ContractType, createContract} from "@services/contracts-api";
import React, {useCallback, useMemo, useState} from "react";
import FormattedNumber from "@components/FormattedNumber";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import {faShoppingCart} from "@fortawesome/pro-regular-svg-icons/faShoppingCart";
import useResponseHandler from "@hooks/useResponseHandler";
import {Redirect} from "react-router-dom";
import {AccountLinks} from "@pages/pages";
import confirmAlert from "@components/ConfirmAlert";
import Translate from "@components/Translate";
import {Wallet} from "@services/wallet-api";
import {divide, multiply, subtract} from "@helpers/bignumber";
import formatNumber from "@util/format-number";

export interface ContractTemplateItemProps {
  template: ContractTemplate;
  wallet?: Wallet;
}

export default function ContractTemplateItem({template, wallet}: ContractTemplateItemProps) {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const [usdPrice, setUsdPrice] = useState<number>(template.usdPrices[0]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [useAsgTokens, setUseAsgTokens] = useState<boolean>(false);

  const titleColor = useMemo(() => {
    switch (template.id) {
      case ContractType.START:
        return 'bg-peach';
      case ContractType.MID:
        return 'bg-primary-gradient';
      case ContractType.INVEST:
        return 'bg-purple';
    }
  }, [template.id]);

  const color = useMemo(() => {
    switch (template.id) {
      case ContractType.START:
        return 'peach';
      case ContractType.MID:
        return 'pink';
      case ContractType.INVEST:
        return 'purple';
    }
  }, [template.id]);

  const asgInUsd = useMemo(() => {
    let result = '';
    if (wallet) {
      const usd = divide(multiply(usdPrice, 0.3), subtract(wallet.usdPrice, 0.002));
      result = ' (' + formatNumber(usd, 2) + ' ASG)'
    }

    return result;
  }, [wallet, usdPrice]);

  const handleBuy = useCallback(() => {
    setLoading(true);

    confirmAlert({
      confirmation: (
        <>
          <div className="mb-3">
            {t('alert.contract-buy-confirm')
              .replace('%name', template.name)
              .replace('%usd', usdPrice + '')
            }
          </div>

          <Translate i18nKey={'alert.contract-buy-terms'}/>
        </>
      ),
      okLabel: t('button.buy'),
      cancelLabel: t('button.cancel'),
    })
      .then((success) => {
        if (!!success) {
          createContract({contractTemplateId: template.id, usdPrice, useAsgTokens})
            .then((response) => {
              if (response.success) {
                setRedirect(true);
                handleResponse(
                  t('alert.contract-bought')
                    .replace('%name', template.name)
                    .replace('%usd', usdPrice + '')
                );
              } else {
                handleResponse(response);
              }

              setLoading(false);
            })
            .catch((response) => {
              handleResponse(response);
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      });
  }, [template, usdPrice, t, useAsgTokens, handleResponse]);

  const divider = <span className="wd-100 ht-1 d-inline-block bg-primary-gradient"/>;

  if (redirect) {
    return (
      <Redirect to={AccountLinks.CONTRACTS}/>
    )
  }

  return (
    <div className="card tx-center wd-300 overflow-hidden">
      <div className={'card-header ' + titleColor}>
        <h4 className="mb-0">{template.name}</h4>
      </div>
      <div className="card-body pt-0">
        <div className="mt-2">
          <div>{t('common.monthly-income')}</div>
          <FormattedNumber
            className="d-block tx-semibold tx-40 lh--9 mt-1"
            value={template.monthlyProfitPercents}
            postfix={'%'}
          />
          {divider}
        </div>

        <div className="mt-2">
          <div>{t('common.yearly-income')}</div>
          <FormattedNumber
            className={'d-block tx-semibold tx-40 lh--9 mt-1 tx-' + color}
            value={template.monthlyProfitPercents * 12}
            postfix={'%'}
            decimals={1}
            floor={false}
          />
          {divider}
        </div>

        <div className="mt-2">
          <div>{t('common.passive-income-withdraw')}</div>
          <FormattedNumber
            className={'d-block tx-semibold tx-30 lh--9 mt-1'}
            value={template.moratoriumDays}
            postfix={t('date.days') + '*'}
            postfixClass="tx-lowercase"
            floor={false}
          />
          {divider}
        </div>

        <div className="mt-2">
          <div>{t('common.investment-period')}</div>
          <FormattedNumber
            className={'d-block tx-semibold tx-30 lh--9 mt-1'}
            value={360}
            postfix={t('date.days')}
            postfixClass="tx-lowercase"
            floor={false}
          />
          {divider}
        </div>

        <div className="mt-2">
          <div>{t('common.investments')}</div>

          {template.usdPrices.map((p) => (
            <div className="mt-1" key={p}>
              <label className="ckbox">
                <input
                  type="checkbox"
                  onChange={(event) => event.target.checked && setUsdPrice(p)}
                  checked={usdPrice === p}
                  disabled={isLoading}
                />
                <span className="pl-4 tx-20 pos-relative">{p}$</span>
              </label>
            </div>
          ))}
        </div>

        <div className="mt-4">
          <label className="ckbox">
            <input
              type="checkbox"
              onChange={(event) => setUseAsgTokens(event.target.checked)}
              checked={useAsgTokens}
              disabled={isLoading}
            />
            <span className="pl-4 tx-20 pos-relative">
              {t('pages.contracts.pay-30-asg')}
              {asgInUsd}
            </span>
          </label>
        </div>

        <button
          className={'btn btn-lg mt-3 btn-' + color}
          disabled={isLoading}
          onClick={handleBuy}
        >
          <FontAwesomeIcon icon={isLoading ? faCircleNotch : faShoppingCart} className="mr-2" spin={isLoading}/>
          {t('button.buy-contract')}
        </button>
      </div>
    </div>
  )
}
