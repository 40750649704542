import React from "react";
import {TransactionStatus, TransactionType} from "@services/api";
import Translate from "@components/Translate";
import Popup from "@pages/account/components/Popup";

export interface TransactionTooltipProps {
  type: TransactionType;
  status: TransactionStatus;
}

export default function TransactionTooltip(props: TransactionTooltipProps) {
  const type = props.type;
  const status = props.status.toLowerCase().replace(/ /g, '-');
  const typeStatus = type + '.' + status;

  const availableTooltips = [
    'WITHDRAWAL.in-progress',
    'WITHDRAWAL.complete',
  ];

  return (
    <span className="transaction-tooltip">
      {availableTooltips.indexOf(typeStatus) !== -1 && (
        <Popup
          tooltip={<Translate i18nKey={'transaction.alert.' + typeStatus}/>}
          iconClass="tx-primary mr-2"
        />
      )}
    </span>
  );
}
