import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import FormattedNumber from "@components/FormattedNumber";
import {getWalletList, Wallet} from "@services/wallet-api";
import useResponseHandler from "@hooks/useResponseHandler";
import PageHeader from "@pages/account/components/PageHeader";
import Preloader from "@components/Preloader";
import {Ticker} from "@services/api";
import TransactionsTable from "@pages/account/components/transactions/TransactionsTable";
import {ContractEarnings, getContractEarnings} from "@services/contracts-api";
import WalletList from "@pages/account/wallet/components/WalletList";

const WalletPage = () => {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [earnings, setEarnings] = useState<ContractEarnings>();

  useEffect(() => {
    getWalletList()
      .then((response) => {
        if (response.success && response.data) {
          setWallets(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch(handleResponse);
  }, [handleResponse]);

  useEffect(() => {
    getContractEarnings()
      .then((response) => {
        if (response.success && response.data) {
          setEarnings(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch(handleResponse);
  }, [handleResponse]);

  const header = <PageHeader title={'pages.wallet.title'}/>;

  if (!wallets.length) {
    return (
      <>
        {header}
        <Preloader inline={true}/>
      </>
    )
  }

  return (
    <>
      {header}

      <div className="row mb-3">
        <div className="col-md-6 col-lg-3 mb-4">
          <div className={'card card-gradient-danger ht-100p'}>
            <div className="card-body">
              <h5 className="mb-1">{t('common.invested')}</h5>
              <div className="wd-100p tx-right tx-18">
                <div className="tx-left">
                  <FormattedNumber
                    value={earnings?.usdInvestments}
                    decimals={2}
                    floor={true}
                    postfix={'$'}
                    postfixClass={'tx-bold'}
                  />
                </div>

                <h6 className="mt-2 mb-0">{t('common.average-monthly-earnings')}</h6>

                <FormattedNumber
                  className="d-inline mr-2"
                  value={earnings?.averageMonthlyProfitPercents}
                  decimals={2}
                  postfix={'%'}
                  postfixClass={'tx-bold'}
                />

                (
                <FormattedNumber
                  className="d-inline"
                  value={
                    earnings
                      ? earnings.usdInvestments / 100 * earnings.averageMonthlyProfitPercents
                      : undefined
                  }
                  floor={true}
                  decimals={2}
                  postfix={'$'}
                  postfixClass={'tx-bold'}
                />
                )
              </div>
            </div>
          </div>
        </div>

        <WalletList
          allowedWallets={[Ticker.ASG, Ticker.USDT, Ticker.USDT_PROFIT]}
          wallets={wallets}
        />
      </div>

      <TransactionsTable/>
    </>
  );
}

export default React.memo(WalletPage);
