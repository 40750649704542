import {API_URL, handleGet, Network, PromiseStatus, Ticker} from "@services/api";
import fetchWithTimeout from "@util/fetchWithTimeout";

export interface CurrencyPrice {
  currency: Ticker;
  usdPrice: number;
}

export interface CurrencyPrice2 {
  aufPrice: number,
  gatPrice: number,
  nextAufUpdateIn: number,
  gatSoldCount: number,
  maxNumberGat: number
}

export interface WalletDepositOption {
  id: string;
  crypto: Ticker;
  network: Network;
  address: string;
  usdPrice: number;
}

export interface Wallet {
  id: string;
  currency: Ticker;
  balance: number;
  lockedBalance: number;
  balanceInUsd: number;
  usdPrice: number;
  depositOptions: WalletDepositOption[];
}

export function getWalletList():PromiseStatus<Wallet[]> {
  return fetchWithTimeout(API_URL + '/api/v1/wallet', {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function getCurrencyPriceList():PromiseStatus<CurrencyPrice[]> {
  return fetchWithTimeout(API_URL + '/api/v1/wallet/currency/price', {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function getPriceList():PromiseStatus<CurrencyPrice2[]> {
  return fetchWithTimeout('https://api.aurousfinance.com/api/v1/token-bank', {
    method: 'GET',
  }).then(data => handleGet(data));
}
