import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import newsList, {News} from "@stores/news";
import {Redirect, useParams} from "react-router-dom";
import {LandingLinks} from "@pages/pages";
import Preloader from "@components/Preloader";
import NewsView from "@pages/news/components/NewsView";

export interface NewsUrlParams {
  slug: string;
}

export default function NewsPage() {
  const {t} = useTranslation();
  const [news, setNews] = useState<News>();
  const {slug} = useParams<NewsUrlParams>();
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (!news && slug) {
      const found = newsList.find((n) => n.slug === slug);

      if (found) {
        setNews(found);
      } else {
        setNotFound(true);
      }
    } else if (!slug) {
      setNotFound(true);
    }
  }, [news, slug]);

  if (notFound) {
    return (
      <Redirect to={LandingLinks.NOT_FOUND}/>
    )
  }

  return (
    <>
      <Helmet>
        <title>Aurous | {news?.title || t('pages.home.content.news.title')}</title>
      </Helmet>
      <div id="news" className="flex-1 p-4 p-md-5 pt-navbar">
        {!news && <Preloader inline={true}/>}
        {news && <NewsView item={news}/>}
      </div>
    </>
  );
}
