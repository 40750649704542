import React, {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {OrEmpty, SortDirection, Ticker} from "@services/api";
import {useRecoilState} from "recoil";
import {
  orderAmountFilter,
  orderAmountSort,
  orderCurrencyFromFilter,
  orderDateSort, orderPriceFilter,
  orderPriceSort,
  orderStatusFilter
} from "@stores/orders";
import { OrderStatus } from "@services/p2p-api";
import {DebounceInput} from "react-debounce-input";

export interface OrderFiltersProps {
  isLoading: boolean;
  isMyOrders: boolean;
  isListView: boolean;
}

type SortOrderOrEmpty = OrEmpty<SortDirection>

export default function OrderFilters({isLoading, isMyOrders, isListView}: OrderFiltersProps) {
  const {t} = useTranslation();
  const [currencyFromFilter, setCurrencyFromFilter] = useRecoilState(orderCurrencyFromFilter);
  const [statusFilter, setStatusFilter] = useRecoilState(orderStatusFilter);
  const [dateSort, setDateSort] = useRecoilState(orderDateSort);
  const [amountSort, setAmountSort] = useRecoilState(orderAmountSort);
  const [priceSort, setPriceSort] = useRecoilState(orderPriceSort);
  const [amountRange, setAmountRange] = useRecoilState(orderAmountFilter);
  const [priceRange, setPriceRange] = useRecoilState(orderPriceFilter);

  useEffect(() => {
    if (isMyOrders) {
      setCurrencyFromFilter('');
    } else {
      setCurrencyFromFilter(Ticker.USDT);
    }
  }, [isMyOrders, setCurrencyFromFilter])

  const forMyOrders = useMemo(() => {
    if (isMyOrders) {
      return 'd-none';
    }

    if (!isListView) {
      return 'd-inline-block d-lg-none mr-4';
    }

    return 'd-inline-block mr-4';
  }, [isMyOrders, isListView]);

  return (
    <div>
      <div className="wd-100p mb-1">
        {isMyOrders && (
          <button
            className={'btn tx-bold tx-14-f mr-3 mb-2 btn-' + (!currencyFromFilter ? 'blue' : 'gray')}
            disabled={isLoading}
            onClick={() => {
              setCurrencyFromFilter('');
            }}
          >
            {t('common.all')}
          </button>
        )}

        <button
          className={'btn tx-bold tx-14-f mr-3 mb-2 btn-' + (currencyFromFilter === Ticker.USDT ? 'blue' : 'gray')}
          disabled={isLoading}
          onClick={() => {
            setCurrencyFromFilter(Ticker.USDT);
          }}
        >
          {t('order.type.buy')}
        </button>

        <button
          className={'btn tx-bold tx-14-f mr-3 mb-2 btn-' + (currencyFromFilter === Ticker.ASG ? 'blue' : 'gray')}
          disabled={isLoading}
          onClick={() => {
            setCurrencyFromFilter(Ticker.ASG);
          }}
        >
          {t('order.type.sell')}
        </button>
      </div>

      <div className={isListView ? 'd-inline' : 'd-inline d-lg-none'}>
        <div className="d-inline-block mr-4">
          <div className="tx-semibold tx-14">
            {t('sort.by-date')}
          </div>
          <div className="form-group">
            <select
              className="form-control pl-0"
              value={dateSort}
              onChange={(event) => setDateSort(event.target.value as SortOrderOrEmpty)}
              disabled={isLoading}
            >
              <option value={''}>{t('common.default').toLowerCase()}</option>
              <option value={SortDirection.ASC}>{t('sort.asc')}</option>
              <option value={SortDirection.DESC}>{t('sort.desc')}</option>
            </select>
          </div>
        </div>

        <div className={forMyOrders}>
          <div className="tx-semibold tx-14">
            {t('sort.by-amount')}
          </div>

          <div className="form-group">
            <select
              className="form-control pl-0"
              value={amountSort}
              onChange={(event) => setAmountSort(event.target.value as SortOrderOrEmpty)}
              disabled={isLoading}
            >
              <option value={''}>{t('common.default').toLowerCase()}</option>
              <option value={SortDirection.ASC}>{t('sort.asc')}</option>
              <option value={SortDirection.DESC}>{t('sort.desc')}</option>
            </select>
          </div>
        </div>

        <div className={forMyOrders}>
          <div className="tx-semibold tx-14">
            {t('sort.by-price')}
          </div>
          <div className="form-group">
            <select
              className="form-control pl-0"
              value={priceSort}
              onChange={(event) => setPriceSort(event.target.value as SortOrderOrEmpty)}
              disabled={isLoading}
            >
              <option value={''}>{t('common.default').toLowerCase()}</option>
              <option value={SortDirection.ASC}>{t('sort.asc')}</option>
              <option value={SortDirection.DESC}>{t('sort.desc')}</option>
            </select>
          </div>
        </div>
      </div>

      <div className={!isMyOrders ? 'd-none' : 'd-inline-block mr-4'}>
        <div className="tx-semibold tx-14">
          {t('common.status')}
        </div>
        <div className="form-group">
          <select
            className="form-control pl-0"
            value={statusFilter}
            onChange={(event) => setStatusFilter(event.target.value as OrEmpty<OrderStatus>)}
            disabled={isLoading}
          >
            <option value={''}>{t('common.all')}</option>
            <option value={OrderStatus.OPEN}>{t('order.status.OPEN')}</option>
            <option value={OrderStatus.EXECUTED}>{t('order.status.EXECUTED')}</option>
            <option value={OrderStatus.CANCELED}>{t('order.status.CANCELED')}</option>
          </select>
        </div>
      </div>

      <div className={isMyOrders ? 'd-none' : 'd-inline-block mr-4 wd-300 mb-3'}>
        <div className="tx-semibold tx-14">
          {t('pages.p2p.content.asg-amount')}
        </div>
        <div className="d-flex wd-100p align-items-start justify-content-between">
          <div className="input-group mr-2 mb-0 wd-150">
            <div className="input-group-prepend">
              <div className="input-group-text pl-0">{t('common.from')}</div>
            </div>
            <DebounceInput
              debounceTimeout={1000}
              className="form-control appearance-none"
              type="number"
              value={amountRange[0]}
              onChange={(event) => setAmountRange([event.target.value, amountRange[1]])}
            />
          </div>

          <div className="input-group ml-2 mb-0">
            <div className="input-group-prepend">
              <div className="input-group-text pl-0">{t('common.to')}</div>
            </div>
            <DebounceInput
              debounceTimeout={1000}
              className="form-control appearance-none"
              type="number"
              value={amountRange[1]}
              onChange={(event) => setAmountRange([amountRange[0], event.target.value])}
            />
            <div className="input-group-append">
              <div className="input-group-text pr-0">ASG</div>
            </div>
          </div>
        </div>
      </div>

      <div className={isMyOrders ? 'd-none' : 'd-inline-block mr-4 wd-300 mb-3'}>
        <div className="tx-semibold tx-14">
          {t('pages.p2p.content.price-per-1-asg')}
        </div>
        <div className="d-flex wd-100p align-items-start justify-content-between">
          <div className="input-group mr-2 mb-0 wd-150">
            <div className="input-group-prepend">
              <div className="input-group-text pl-0">{t('common.from')}</div>
            </div>
            <DebounceInput
              debounceTimeout={1000}
              className="form-control appearance-none"
              type="number"
              value={priceRange[0]}
              onChange={(event) => setPriceRange([event.target.value, priceRange[1]])}
            />
          </div>

          <div className="input-group ml-2 mb-0">
            <div className="input-group-prepend">
              <div className="input-group-text pl-0">{t('common.to')}</div>
            </div>
            <DebounceInput
              debounceTimeout={1000}
              className="form-control appearance-none"
              type="number"
              value={priceRange[1]}
              onChange={(event) => setPriceRange([priceRange[0], event.target.value])}
            />
            <div className="input-group-append">
              <div className="input-group-text pr-0">$</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
