import React, {useCallback, useMemo} from "react";
import QRCode from "qrcode.react";
import logo from "@assets/images/logo-bw.png";
import CopyToClipboard from "react-copy-to-clipboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/pro-regular-svg-icons/faCopy";
import {useTranslation} from "react-i18next";
import {ReferralInfo} from "@services/referral-api";
import useResponseHandler from "@hooks/useResponseHandler";

interface ReferralInfoViewProps {
  referralInfo: ReferralInfo;
  isRoot: boolean;
}

export default function ReferralInfoView({referralInfo, isRoot = true}: ReferralInfoViewProps) {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();

  const refLink = useMemo(() => {
    let origin = document.location.origin;

    return origin + '/?ref=' + referralInfo.refId;
  }, [referralInfo]);

  const handleCopy = useCallback((copyCode = false) => {
    console.log({copyCode});
    handleResponse(t(copyCode ? 'alert.ref-id-copied' : 'alert.ref-link-copied'));
  }, [t, handleResponse]);

  return (
    <div>
      <h4>{t(!isRoot ? 'common.ref-link' : 'pages.referrals.content.ref-link')}</h4>

      <div className="d-lg-flex flex-row">
        <div className="wd-200 mr-3">
          <QRCode value={refLink} size={200} imageSettings={{src: logo, width: 40, height: 40}}/>
        </div>

        <div>
          <div className="mb-2">
            <CopyToClipboard text={refLink} onCopy={() => handleCopy(false)}>
              <button className="btn btn-link word-break tx-28-f p-0">
                {refLink}
              </button>
            </CopyToClipboard>
          </div>

          <CopyToClipboard text={refLink} onCopy={() => handleCopy(false)}>
            <button className="btn btn-gold d-block">
              <FontAwesomeIcon icon={faCopy}/> {t('button.copy-link')}
            </button>
          </CopyToClipboard>

          <h6 className="mt-4 mb-0">{t(!isRoot ? 'common.ref-id' : 'pages.referrals.content.ref-id')}:</h6>

          <div>
            <CopyToClipboard text={referralInfo.refId} onCopy={() => handleCopy(true)}>
              <button className="btn btn-link word-break tx-28-f p-0">
                {referralInfo.refId}
              </button>
            </CopyToClipboard>
          </div>

          <CopyToClipboard text={referralInfo.refId} onCopy={() => handleCopy(true)}>
            <button className="btn btn-gold d-block">
              <FontAwesomeIcon icon={faCopy}/> {t('button.copy-code')}
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}
