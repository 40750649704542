import {atom, selector} from "recoil";
import {AccountInfo, getAccountInfo} from "@services/settings-api";

export const refAtom = atom<string | null>({
  key: 'refId',
  default: localStorage.getItem('referral'),
});

export const loginAtom = atom<boolean>({
  key: 'isLoggedIn',
  default: localStorage.getItem('loggedIn')
    ? JSON.parse(localStorage.getItem('loggedIn')!)
    : false,
});

export const authTokenAtom = atom<string>({
  key: 'authToken',
  default: localStorage.getItem('authToken')
    ? localStorage.getItem('authToken')!
    : '',
});

export const lastAccountUpdate = atom<number>({
  key: 'lastAccountUpdate',
  default: Date.now(),
});

export const accountInfo = selector<AccountInfo | null>({
  key: 'accountInfo',
  get: async ({get}) => {
    let result: AccountInfo | null = null;
    const isLoggedIn = get(loginAtom);
    get(lastAccountUpdate);

    if (isLoggedIn) {
      const response = await getAccountInfo();
      if (response.data) {
        result = response.data;
      }
    } else {
      result = null;
    }

    return result;
  },
});

export const loginState = selector<boolean>({
  key: 'loginState',
  get: ({get}) => {
    return get(loginAtom);
  },
  set: ({set}, newValue) => {
    set(loginAtom, newValue);
    localStorage.setItem('loggedIn', newValue ? 'true' : 'false');

    if (!newValue) {
      set(tokenState, '');
      set(lastAccountUpdate, Date.now());
    }
  },
});

export const tokenState = selector<string>({
  key: 'tokenState',
  get: ({get}) => {
    return get(authTokenAtom);
  },
  set: ({set}, newValue) => {
    set(authTokenAtom, newValue);
    localStorage.setItem('authToken', newValue as string);
  },
});

export const refState = selector<string | null>({
  key: 'refState',
  get: ({get}) => {
    return get(refAtom);
  },
  set: ({set}, newValue) => {
    set(refAtom, newValue);
    localStorage.setItem('referral', newValue ? newValue as string : '');
  },
});
