import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import useResponseHandler from "@hooks/useResponseHandler";
import confirmAlert from "@components/ConfirmAlert";
import {OrdersTableRowProps} from "@pages/account/p2p/orders/OrdersTableRow";
import {executeOrder} from "@services/p2p-api";
import {Ticker} from "@services/api";
import {faShoppingCart} from "@fortawesome/pro-regular-svg-icons/faShoppingCart";
import FormattedNumber from "@components/FormattedNumber";

export interface ExecuteOrderButtonProps {
  props: OrdersTableRowProps;
}

export default function ExecuteOrderButton({props}: ExecuteOrderButtonProps) {
  const {entry, updateWallets} = props;

  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const [isLoading, setLoading] = useState<boolean>(false);

  const buySell = t(entry.currencyFrom === Ticker.ASG ? 'button.buy' : 'button.sell');
  const coins = entry.currencyFrom === Ticker.USDT
    ? entry.currencyToAmount + ' ' + entry.currencyTo
    : entry.currencyFromAmount + ' ' + entry.currencyFrom;

  const alertBody = (
    <>
      <div className="tx-bold">
        {t('pages.p2p.content.you-will-receive')}
      </div>

      <FormattedNumber
        value={entry.currencyFromAmount}
        postfix={entry.currencyFrom}
        className="d-block"
      />

      <div className="tx-bold mt-3">
        {t('pages.p2p.content.you-will-spend')}
      </div>

      <FormattedNumber
        value={entry.currencyToAmountWithFee}
        postfix={entry.currencyTo}
        className="d-block"
      />

      <div className="text-muted tx-14">
        <FormattedNumber
          value={entry.currencyToFeeAmount}
          suffix={t('common.fee') + ' '}
          postfix={entry.currencyTo + ' (1%)'}
          className="d-block"
        />
      </div>
    </>
  );

  const handleCancelWithdrawal = useCallback(() => {
    setLoading(true);

    confirmAlert({
      title: buySell + ' ' + coins + '?',
      confirmation: alertBody,
      okLabel: buySell,
      okIcon: faShoppingCart,
    })
      .then((success) => {
        if (!!success) {
          executeOrder(entry.id)
            .then((response) => {
              setLoading(false);
              if (response.success) {
                handleResponse(
                  t('alert.order-complete')
                    .replace('%amount', entry.currencyToAmount + '')
                    .replace('%currency', entry.currencyTo)
                );
                updateWallets();
              } else {
                handleResponse(response);
              }
            })
            .catch((response) => {
              setLoading(false);
              handleResponse(response);
            });
        } else {
          setLoading(false);
        }
      });
  }, [updateWallets, coins, buySell, entry, handleResponse, t]);

  return (
    <>
      <button className="btn btn-sm btn-gold" onClick={handleCancelWithdrawal} disabled={isLoading}>
        <FontAwesomeIcon icon={isLoading ? faCircleNotch : faShoppingCart} spin={isLoading} className="mr-1"/>
        <span>{buySell}</span>
      </button>
    </>
  );
}
