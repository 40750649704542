import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {getWalletList, Wallet} from "@services/wallet-api";
import useResponseHandler from "@hooks/useResponseHandler";
import PageHeader from "@pages/account/components/PageHeader";
import Preloader from "@components/Preloader";
import {Ticker, TransactionType} from "@services/api";
import {DepositOptions, getDepositOptions} from "@services/deposit-api";
import DepositForm from "@pages/account/deposit/components/DepositForm";
import TransactionsTable from "@pages/account/components/transactions/TransactionsTable";

const DepositPage = () => {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [depositOptions, setDepositOptions] = useState<DepositOptions[]>([]);
  const [tab, setTab] = useState<Ticker>(Ticker.USDT);
  const allowedWallets: Ticker[] = [Ticker.USDT, Ticker.ASG];

  const loadWallets = useCallback(() => {
    getWalletList()
      .then((response) => {
        if (response.success && response.data) {
          setWallets(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch(handleResponse);
  }, [handleResponse]);

  useEffect(() => {
    loadWallets();
  }, [loadWallets]);

  useEffect(() => {
    getDepositOptions()
      .then((response) => {
        if (response.success && response.data) {
          setDepositOptions(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch(handleResponse);
  }, [handleResponse]);

  const header = <PageHeader title={'pages.deposit.title'}/>;

  if (!wallets.length || !depositOptions.length) {
    return (
      <>
        {header}
        <Preloader inline={true}/>
      </>
    )
  }

  return (
    <>
      {header}

      <div className="mb-4">
        {wallets.filter((w) => allowedWallets.indexOf(w.currency) !== -1).map((w) => (
          <button
            key={w.currency}
            className={'btn btn-lg tx-bold tx-16-f mr-3 btn-' + (tab === w.currency ? 'primary' : 'gray')}
            onClick={() => {
              setTab(w.currency);
            }}
          >
            {t('wallet.title.' + w.currency)}
          </button>
        ))}
      </div>

      <DepositForm
        wallet={wallets.find((w) => w.currency === tab)!}
        depositOptions={depositOptions.find((option) => option.currency === tab)}
        requestWalletUpdate={loadWallets}
      />

      <TransactionsTable
        types={[TransactionType.ADMIN_DEPOSIT, TransactionType.DEPOSIT]}
        className="mt-5"
      />
    </>
  );
}

export default React.memo(DepositPage);
