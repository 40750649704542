import fetchWithTimeout from "@util/fetchWithTimeout";
import {
  API_URL,
  Code,
  handleData,
  handleGet,
  Password,
  PromiseStatus,
  TFAData,
} from "@services/api";

export interface AccountInfoData {
  id?: number; // Actually 'id' is required field, so Demyd please refactor it :)
  email: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
}

export interface AccountInfo extends AccountInfoData {
  tfaEnabled: boolean;
  metadata: {
    p2pEnabled?: boolean;
  }
}

export interface ChangePasswordData {
  oldPassword: string;
  newPassword: string;
  code?: string;
}

export interface TFASetupInfo {
  code: string;
  qrContent: string;
}

export interface ResetData {
  token: string;
  newPassword: string;
}

export function getAccountInfo():PromiseStatus<AccountInfo> {
  return fetchWithTimeout(API_URL + '/api/v1/account/settings', {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function saveAccountInfo(data: AccountInfoData):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/account/settings/personal', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}

export function changePassword(data: ChangePasswordData):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/account/settings/password', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}

export function tfaSetup(data: Password):PromiseStatus<TFASetupInfo> {
  return fetchWithTimeout(API_URL + '/api/v1/account/settings/tfa/secret', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}

export function tfaEnable(data: Code):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/account/settings/tfa/enable', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}

export function tfaDisable(data: TFAData):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/account/settings/tfa/disable', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}
