import React, {useCallback, useMemo, useState} from 'react';
import bg from '@assets/images/bg.jpg';
import {Link, Redirect} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {requestReset} from "@services/api";
import {AccountLinks, LandingLinks} from "@pages/pages";
import {Helmet} from "react-helmet-async";
import useResponseHandler from "@hooks/useResponseHandler";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {useRecoilValue} from "recoil";
import {loginState} from "@stores/account";
import {useTranslation} from "react-i18next";

export default function Forgot() {
  const {t} = useTranslation();
  const isLoggedIn = useRecoilValue(loginState);
  const handleResponse = useResponseHandler();
  const [email, setEmail] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSucceed, setSucceed] = useState<boolean>(false);

  const emailRx = useMemo(() => {
    return RegExp(/(.+)@(.+){2,}\.(.+){2,}/)
  }, []);

  const isEmailValid = useCallback(() => {
    if (email.length < 6 || !(emailRx.test(email))) {
      handleResponse('error.email-not-valid');
      return false;
    }

    return true;
  }, [email, emailRx, handleResponse]);

  const submitForm = useCallback(() => {
    if (!isEmailValid()) {
      return;
    }

    setLoading(true);

    requestReset({email})
      .then((response) => {
        if (response.success) {
          setSucceed(true);
        } else {
          setLoading(false);
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [email, isEmailValid, handleResponse]);

  if (isLoggedIn) {
    return (
      <Redirect to={AccountLinks.WALLET}/>
    )
  }

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.forgot.title')}</title>
      </Helmet>
      <div id="forgot" className="flex flex-1 bg-image-center bg-image-cover bg-overlay p-4 p-md-5 pt-navbar align-items-center pb-5"
           style={{backgroundImage: `url(${bg})`}}>
        <div className="container">
          <form id="reset-form" className="wd-100p wd-md-500" onSubmit={(e) => {e.preventDefault(); submitForm();}}>
            <h2 className="mb-4">{t('pages.forgot.content.heading')}</h2>

            {isSucceed && (
              <>
                <span className="tx-semibold tx-24">
                  {t('message.forgot-sent')}
                </span>
              </>
            )}

            {!isSucceed && (
              <>
                <div className="input-group input-group-lg mb-4">
                  <div className="input-group-prepend">
                    <div className="input-group-text"><FontAwesomeIcon icon={faEnvelope}/></div>
                  </div>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    placeholder={t('input.email')}
                    value={email}
                    id="email"
                    onChange={event => {
                      const value = event.target.value;

                      if (value.length - email.length < 3) {
                        setEmail(value)
                      } else {
                        if (emailRx.test(value)) {
                          setEmail(value);
                        } else {
                          setEmail(email);
                        }
                      }
                    }}
                    disabled={isLoading}
                  />
                </div>

                <button
                  className="btn btn-lg btn-primary wd-100p mb-4"
                  type="submit"
                  onClick={submitForm}
                  disabled={email.length < 6 || isLoading}
                >
                  {isLoading && (<FontAwesomeIcon icon={faCircleNotch} className="mr-2" spin/>)}
                  {t('button.recover-access')}
                </button>

                <div className="mb-2">
                  {t('pages.forgot.content.no-account')} <Link to={LandingLinks.REGISTER}>{t('button.register')}</Link>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
