import {API_URL, handleData, handleGet, Paged, PromiseStatus} from "@services/api";
import fetchWithTimeout from "@util/fetchWithTimeout";

export enum ContractType {
  START = 'start-contract',
  MID = 'mid-contract',
  INVEST = 'invest-contract',
}

export enum ContractStatus {
  MORATORIUM = 'MORATORIUM',
  ACTIVE = 'ACTIVE',
  RETURN_IN_PROGRESS = 'RETURN_IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface ContractTemplate {
  id: ContractType;
  name: string;
  moratoriumDays: number;
  monthlyProfitPercents: number;
  usdPrices: number[];
  fundPayoutPercents: number;
  metadata: any;
  returnInvestmentSteps: any;
  fundPayoutDays: number[];
  durationDays: number;
}

export interface CreateContractData {
  contractTemplateId: ContractType;
  usdPrice: number;
  useAsgTokens: boolean;
}

export interface Contract {
  id: string;
  name: string;
  status: ContractStatus;
  templateId: ContractType;
  accountId: number;
  startDate: string;
  endDate: string;
  usdPrice: number;
  monthlyProfitPercents: number;
  metadata: any;
}

export interface ContractEarnings {
  usdInvestments: number;
  averageMonthlyProfitPercents: number;
}

export function getContractTemplates():PromiseStatus<ContractTemplate[]> {
  return fetchWithTimeout(API_URL + '/api/v1/contract/template', {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function getContracts():PromiseStatus<Paged<Contract>> {
  return fetchWithTimeout(API_URL + '/api/v1/contract?page=0&size=100', {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function getContractEarnings():PromiseStatus<ContractEarnings> {
  return fetchWithTimeout(API_URL + '/api/v1/contract/earning/active', {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function createContract(data: CreateContractData):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/contract', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}
