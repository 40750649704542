import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import useResponseHandler from "@hooks/useResponseHandler";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/pro-regular-svg-icons/faUser";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {saveP2pAccount} from "@services/p2p-api";
import {faSave} from "@fortawesome/pro-regular-svg-icons/faSave";

export interface P2pNicknameProps {
  reload: () => void;
}

export default function P2pNickname({reload}: P2pNicknameProps) {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const [nickname, setNickname] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const saveNickname = useCallback(() => {
    if (!nickname) {
      handleResponse('error.empty-fields');
      return;
    }

    setLoading(true);

    saveP2pAccount(nickname)
      .then((response) => {
        setLoading(false);

        if (response.success) {
          reload();
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [nickname, reload, handleResponse]);

  return (
    <div className="wd-md-300">
      <div className="mb-3 tx-warning">
        {t('pages.p2p.content.no-nickname')}
      </div>

      <div className="input-group input-group-lg">
        <div className="input-group-prepend">
          <div className="input-group-text"><FontAwesomeIcon icon={faUser}/></div>
        </div>
        <input
          type="text"
          className="form-control form-control-lg"
          placeholder={t('input.nickname')}
          value={nickname}
          id="nickname"
          onChange={event => setNickname(event.target.value)}
          disabled={isLoading}
        />
      </div>

      <button
        className="btn btn-lg btn-primary wd-100p mt-4"
        disabled={isLoading}
        onClick={saveNickname}
      >
        <FontAwesomeIcon icon={isLoading ? faCircleNotch : faSave} className="mr-2" spin={isLoading}/>
        {t('button.save')}
      </button>
    </div>
  )
}
