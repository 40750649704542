import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Element, scroller} from "react-scroll/modules";
import TransactionsTableRow from "./TransactionsTableRow";
// import {faFileDownload} from "@fortawesome/free-solid-svg-icons/faFileDownload";
import {faThList} from "@fortawesome/free-solid-svg-icons/faThList";
import {faTable} from "@fortawesome/free-solid-svg-icons/faTable";
// import {Link} from 'react-router-dom';
import {getTransactions, Transaction, TransactionType} from "@services/api";
import useResponseHandler from "@hooks/useResponseHandler";
import Preloader from "@components/Preloader";
import Placeholder from "@components/Placeholder";
import Translate from "@components/Translate";

export interface TransactionsTableProps {
  types?: TransactionType[];
  id?: string;
  className?: string;
  scrollContainer?: string;
  customTitle?: JSX.Element;
  noTitle?: boolean;
}

export interface TransactionsTableRef {
  refresh: () => void;
}

export const tableHeaders = [
  'transaction.table.date',
  'transaction.table.type',
  'transaction.table.quantity',
  'common.wallet',
  'common.id',
  'transaction.table.status',
  'button.details',
];

const TransactionsTable = forwardRef<TransactionsTableRef, TransactionsTableProps>(
  (props: TransactionsTableProps, ref) => {
    const {
      id = 'transactions-table',
      className,
      scrollContainer,
      customTitle,
      noTitle = false,
      types = [],
    } = props;
    const {t} = useTranslation();
    const handleResponse = useResponseHandler();
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isInitialLoading, setInitialLoading] = useState<boolean>(true);
    const [transactionsLoaded, setTransactionsLoaded] = useState<boolean>(false);
    const [listView, setListView] = useState<boolean>(false);

    const loadTransactions = useCallback((nextPage: number) => {
      setLoading(true);
      getTransactions(nextPage, types)
        .then((response) => {
          if (response.success && response.data) {
            if (scrollContainer && !isInitialLoading && transactionsLoaded && transactions.length && response.data?.content.length) {
              scroller.scrollTo(id, {
                duration: 500,
                smooth: true,
                offset: -75,
                containerId: scrollContainer,
              });
            }

            setTransactions(response.data.content);
            setTotal(response.data.totalElements);
          } else {
            handleResponse(response);
          }
          setLoading(false);
          setTransactionsLoaded(true);
          setInitialLoading(false);
        })
        .catch((response) => {
          handleResponse(response);
          setLoading(false);
          setTransactionsLoaded(true);
          setInitialLoading(false);
        });
    }, [id, transactions.length, transactionsLoaded, types, isInitialLoading, scrollContainer, handleResponse]);

    useImperativeHandle(
      ref,
      () => ({
        refresh() {
          loadTransactions(page);
        }
      }),
    );

    useEffect(() => {
      if (!isLoading && !transactionsLoaded && !transactions.length) {
        loadTransactions(0);
      }
    }, [isLoading, transactionsLoaded, transactions.length, loadTransactions]);

    const handlePageChange = useCallback((newPage: number) => {
      setPage(newPage);
      loadTransactions(newPage);
    }, [loadTransactions]);

    return (
      <div className={className}>
        <div className="d-md-flex flex-md-row align-items-start justify-content-end mb-2 mb-md-4">
          <h2 className="mb-4 mb-md-0 mr-auto">
            <button
              className="btn btn-link p-0 tx-30-f mr-3 d-none d-lg-inline"
              onClick={() => setListView(!listView)}
            >
              <FontAwesomeIcon icon={faTable} className={'mr-2 ' + (listView ? 'tx-gray-600' : 'tx-primary')}/>
              <FontAwesomeIcon icon={faThList} className={!listView ? 'tx-gray-600' : 'tx-primary'}/>
            </button>

            {!noTitle && customTitle ? customTitle : t('common.transactions')}
          </h2>

          {/*<TrackTransaction updateTable={() => loadTransactions(page)}/>*/}

          {/*<Link*/}
          {/*  className="btn btn-sm btn-info mb-3 mb-md-0"*/}
          {/*  to="/api/v1/transactions/export"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <FontAwesomeIcon icon={faFileDownload} className="mr-2"/>*/}
          {/*  {t('transaction.export')}*/}
          {/*</Link>*/}
        </div>

        <Element name={id} id={id}>
          <div className={'table-wrapper-responsive' + (listView ? ' list-view' : '')}>
            {isLoading && transactions.length > 0 && <Preloader/>}
            <table className="table table-striped mb-0">
              <thead>
              <tr>
                {tableHeaders.map((header, index) => {
                  const classList = ['bd-t-0-f'];

                  if (index === 0) {
                    classList.push('wd-lg-200');
                  }

                  if (index === 1) {
                    classList.push('wd-lg-250');
                  }

                  return (
                    <th className={classList.join(' ')} key={'transactions-th-' + index}>{t(header)}</th>
                  )
                })}
              </tr>
              </thead>
              <tbody>
                {!transactionsLoaded && (<>
                  <tr>
                    {tableHeaders.map((header, index) => (
                      <td key={'transactions-placeholder-1-' + index}><Placeholder width={190}/></td>
                    ))}
                  </tr>
                  <tr>
                    {tableHeaders.map((header, index) => (
                      <td key={'transactions-placeholder-2-' + index}><Placeholder width={190}/></td>
                    ))}
                  </tr>
                </>)}
                {transactions.length > 0 && transactions.map((entry, index) => (
                  <TransactionsTableRow
                    entry={entry}
                    updateTable={() => loadTransactions(page)}
                    key={'table-transaction-' + index + '-' + entry.creationTimestamp}
                  />
                ))}
                {transactionsLoaded && transactions.length === 0 && (
                  <tr>
                    <td colSpan={tableHeaders.length}>
                      <Translate i18nKey={'common.no-transactions'}/>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Element>

        {total > 10 && (
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faArrowLeft}/>}
            nextLabel={<FontAwesomeIcon icon={faArrowRight}/>}
            pageCount={total / 10}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            containerClassName={'pagination mt-4 justify-content-center'}
            activeClassName={'active'}
            onPageChange={(data) => handlePageChange(data.selected)}
            eventListener={isLoading ? '' : 'onClick'}
          />
        )}
      </div>
    );
  }
);

export default TransactionsTable;
