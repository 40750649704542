import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {getWalletList, Wallet} from "@services/wallet-api";
import useResponseHandler from "@hooks/useResponseHandler";
import PageHeader from "@pages/account/components/PageHeader";
import Preloader from "@components/Preloader";
import {Ticker} from "@services/api";
import P2pNickname from "@pages/account/p2p/components/P2pNickname";
import {getP2pAccount, P2PAccount} from "@services/p2p-api";
import WalletList from "@pages/account/wallet/components/WalletList";
import {Helmet} from "react-helmet-async";
import CreateOrderModal from "@pages/account/p2p/components/CreateOrderModal";
import OrdersTable, {OrdersTableRef} from "@pages/account/p2p/orders/OrdersTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit} from "@fortawesome/pro-regular-svg-icons/faEdit";
import useLoadableValue from "@hooks/useLoadableValue";
import {accountInfo} from "@stores/account";
import {AccountLinks} from "@pages/pages";
import {Redirect} from "react-router-dom";

const P2pPage = () => {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [account, setAccount] = useState<P2PAccount | null>(null);
  const [accountLoaded, setAccountLoaded] = useState<boolean>(false);
  const ordersTable = useRef<OrdersTableRef>(null);
  const settings = useLoadableValue(accountInfo);

  const loadWallets = useCallback(() => {
    return getWalletList()
      .then((response) => {
        if (response.success && response.data) {
          setWallets(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch(handleResponse)
  }, [handleResponse]);

  useEffect(() => {
    loadWallets();
  }, [loadWallets]);

  const refreshData = useCallback(() => {
    ordersTable.current?.refresh();
    loadWallets();
  }, [ordersTable, loadWallets]);

  useEffect(() => {
    if(!account && !accountLoaded) {
      getP2pAccount()
        .then((response) => {
          if (response.code === 200) {
            setAccount(response.data || null);
            setAccountLoaded(true);
          } else {
            handleResponse(response);
          }
        })
        .catch(handleResponse);
    }
  }, [account, accountLoaded, handleResponse]);

  const header = <PageHeader title={'pages.p2p.title'}/>;

  if (settings && !settings.metadata.p2pEnabled) {
    return (
      <>
        <Redirect to={AccountLinks.WALLET}/>
      </>
    )
  }

  if (!accountLoaded || (account && !wallets.length)) {
    return (
      <>
        {header}
        <Preloader inline={true}/>
      </>
    )
  }

  if (!account) {
    return (
      <>
        {header}

        <P2pNickname
          reload={() => setAccountLoaded(false)}
        />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.p2p.title')}</title>
      </Helmet>

      <div className="d-md-flex justify-content-between mb-2">
        <h1 className="mb-2">{t('pages.p2p.title')}</h1>

        <CreateOrderModal
          updateWallets={refreshData}
          wallets={wallets}
        />
      </div>

      <div className="tx-20 tx-semibold mb-2">
        <span className="mr-2">{t('input.nickname')}:</span>
        <button
          className="btn btn-link tx-bold m-0 p-0"
          onClick={() => setAccount(null)}
        >
          {account.nickname}
          <FontAwesomeIcon icon={faEdit} className="ml-3"/>
        </button>
      </div>

      <div className="row mb-3">
        <WalletList
          allowedWallets={[Ticker.ASG, Ticker.USDT]}
          wallets={wallets}
        />
      </div>

      <OrdersTable
        ref={ordersTable}
        account={account}
        updateWallets={refreshData}
      />
    </>
  );
}

export default React.memo(P2pPage);
