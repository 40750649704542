import React, {Suspense} from 'react';
import 'react-phone-input-2/lib/style.css';
import './assets/styles/global.scss';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import './App.css';
import {AccountLinks, AccountPages, LandingLinks, SocialLinks, socialPages} from "@pages/pages";
import Sidenav from "@components/Sidenav";
import Header from "@components/Header";
import Login from "@components/pages/landing/Login";
import Register from "@pages/landing/register/Register";
import RegisterConfirm from "@components/pages/landing/RegisterConfirm";
import Home from "@pages/landing/home/Home";
import NotFound from "@pages/NotFound";
import Forgot from "@components/pages/landing/Forgot";
import PasswordReset from "@components/pages/landing/PasswordReset";
import {useRecoilValue} from "recoil";
import {loginState} from "@stores/account";
import Preloader from "@components/Preloader";
import Stores from "@stores/Stores";
import NewsPage from "@pages/news/NewsPage";
import Error from "@pages/Error";
import Footer from "@pages/landing/home/components/Footer";
import LanguageSwitcher from "@components/common/language-switcher/LanguageSwitcher";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTelegramPlane} from "@fortawesome/free-brands-svg-icons/faTelegramPlane";

function App() {
  const isLoggedIn = useRecoilValue(loginState);

  const toggleMenu = () => {
    document.body.classList.toggle('open-nav')
  };

  return (
    <BrowserRouter>
      <Stores/>
      <Toaster/>
      <Switch>
        <Route
          path={[...AccountPages.map(page => page.link)]}
          exact={true}
          render={() =>
            !isLoggedIn
              ? (
                <Redirect to={LandingLinks.LOGIN}/>
              )
              : (<>
                <div id="topbar" className="bg-gray-900">
                  <button onClick={toggleMenu} className="hamburger"><div/></button>
                  <LanguageSwitcher/>
                </div>
                <div className="d-flex flex-row flex-grow-1 w-100p app-height bg-gray-900 outer-menu">
                  <Sidenav/>
                  <div className="d-flex flex-column flex-grow-1 overflow-auto" id="dashboard-content">
                    {AccountPages.map((page, index) => (
                      <Route path={page.link} exact={true} key={'app-route-' + index}>
                        <div className="flex-page bg-gray-800" id={page.id}>
                          <Suspense fallback={<Preloader inline={true}/>}>
                            <page.component/>
                          </Suspense>
                        </div>
                      </Route>
                    ))}
                  </div>
                  <div onClick={toggleMenu} className="overlay"/>
                </div>
              </>)
          }
        />

        <Route path={[
          LandingLinks.LOGIN,
          LandingLinks.REGISTER,
          LandingLinks.REGISTER_CONFIRM,
          LandingLinks.HOME,
          LandingLinks.FORGOT,
          LandingLinks.PASSWORD_RESET,
          LandingLinks.ERROR,
          LandingLinks.NOT_FOUND,
          LandingLinks.NEWS,
        ]} exact={true}>
          <Header/>

          <Route
            path={[LandingLinks.LOGIN, LandingLinks.REGISTER, LandingLinks.REGISTER_CONFIRM, LandingLinks.FORGOT, LandingLinks.PASSWORD_RESET]}
            exact={true}
            render={() =>
              !isLoggedIn
                ? (<>
                  <Route path={LandingLinks.LOGIN} component={Login} exact={true}/>
                  <Route path={LandingLinks.REGISTER} component={Register} exact={true}/>
                  <Route path={LandingLinks.REGISTER_CONFIRM} component={RegisterConfirm} exact={true}/>
                  <Route path={LandingLinks.FORGOT} component={Forgot} exact={true}/>
                  <Route path={LandingLinks.PASSWORD_RESET} component={PasswordReset} exact={true}/>
                </>)
                : (
                  <Redirect to={AccountLinks.WALLET}/>
                )
            }
          />
          <Route path={LandingLinks.NEWS} component={NewsPage} exact={true}/>
          <Route path={LandingLinks.ERROR} component={Error} exact={true}/>
          <Route path={LandingLinks.NOT_FOUND} component={NotFound} exact={true}/>
          <Route path={LandingLinks.HOME} component={Home} exact={true}/>

          <Footer/>
        </Route>

        {socialPages.map((page) => (
          <Route
            key={page.path}
            path={page.path}
            exact={true}
            render={() => {
              window.location.href = page.link;
              return null;
            }}
          />
        ))}

        <Route>
          <Header/>
          <Route component={NotFound}/>
        </Route>
      </Switch>

      {/* <a href={SocialLinks.SUPPORT} id="support-link" title="Telegram Support" target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faTelegramPlane}/>
      </a> */}
    </BrowserRouter>
  );
}

export default App;
