import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HelmetProvider} from "react-helmet-async";
import {RecoilRoot} from "recoil";
import SimpleReactLightbox from 'simple-react-lightbox';
import LogRocket from 'logrocket';

if (window.location.href.indexOf('aurous.finance') !== -1) {
  LogRocket.init('s3myal/aurous-finance');
}

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <HelmetProvider>
        <RecoilRoot>
          <App/>
        </RecoilRoot>
      </HelmetProvider>
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
