export function getAccountUrl(path = '') {
  const url = new URL(window.location.href);
  const ref = url.searchParams.get("ref") || localStorage.getItem('ref');
  let query = '';
  if (ref) {
    query = `/?ref=${ref}`;
    localStorage.setItem('ref', ref);
  }

  let hostname = url.host;
  if (hostname.indexOf('www.') === 0) {
    hostname = hostname.slice(4);
  }

  return `${url.protocol}//app.${hostname}${path}${query}`;
}
