export function toFixed(x: string | number, fixed?: number, floor?: boolean): string {
  x = parseFloat(x.toString());

  if (x === Infinity) {
    x = 0;
  }

  if (fixed === undefined) {
    return x.toString();
  } else if (!floor) {
    return x.toFixed(fixed);
  }
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');

  return x.toString().match(re)![0];
}

function addZeros(x: string, n?: number): string {
  if (!n) return x;

  if (x.length < n) {
    x += '0';
  }

  return x.length === n ? x : addZeros(x, n);
}

export default function formatNumber(
  x: string | number,
  fixed?: number,
  floor = false,
  thousandSeparator: string = ',',
  digitsSeparator: string = '.',
  dropZeros: boolean = false,
):string {
  x = typeof x === 'string' ? parseFloat(x) : x;

  if (isNaN(x)) return '';
  x = toFixed(x, fixed, floor);

  const integer = parseInt(x.toString());
  const decimal = (parseFloat(x.toString()) - integer);
  const integerString = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  const decimalString = decimal !== 0 && x.indexOf('.') !== -1 ? x.split('.')[1] : '';

  return decimal === 0 ? integerString : integerString + digitsSeparator + addZeros(decimalString, fixed);
}
