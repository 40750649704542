import React from "react";
import {useTranslation} from "react-i18next";
import ValueOf from "@pages/account/components/ValueOf";
import {ReferralLevelRule} from "@services/referral-api";

export interface ReferralNextLevelConditionsProps {
  nextLevelRule: ReferralLevelRule | null;
}

export default function ReferralNextLevelConditions({nextLevelRule}: ReferralNextLevelConditionsProps) {
  const {t} = useTranslation();

  if (!nextLevelRule) {
    return null;
  }

  return (
    <>
    <h2>{t('pages.referrals.content.next-level-conditions')}:</h2>

    <ul className="tx-20">
      <ValueOf
        value={nextLevelRule.firstLineUsdTurnover}
        of={nextLevelRule.requiredFirstLineUsdTurnover}
        text={t('pages.referrals.conditions.first-line-turnover') + ': '}
        format={true}
        sign={'$'}
        asLi={true}
      />
      {nextLevelRule.requiredTotalUsdTurnover > 0 && (
        <ValueOf
          value={nextLevelRule.totalUsdTurnover}
          of={nextLevelRule.requiredTotalUsdTurnover}
          text={t('pages.referrals.conditions.total-turnover') + ': '}
          format={true}
          sign={'$'}
          asLi={true}
        />
      )}
      {nextLevelRule.requiredFirstLinePersonalDeposit > 0 && (
        <ValueOf
          value={nextLevelRule.firstLineReferralsAmount}
          of={nextLevelRule.requiredFirstLineReferralsAmount}
          text={
            t('pages.referrals.conditions.first-line-referrals') + ` ${nextLevelRule.requiredFirstLinePersonalDeposit}$: `
          }
          asLi={true}
        />
      )}
      <ValueOf
        value={nextLevelRule.personalUsdDeposit}
        of={nextLevelRule.requiredPersonalUsdDeposit}
        text={t('pages.referrals.conditions.personal-deposit') + ': '}
        format={true}
        sign={'$'}
        asLi={true}
      />
    </ul>
  </>
  );
}
