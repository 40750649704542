import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getReferralRewardBonuses, ReferralRewardBonus, RewardType} from "@services/referral-api";
import {faUserCrown} from "@fortawesome/pro-solid-svg-icons/faUserCrown";
import useResponseHandler from "@hooks/useResponseHandler";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/pro-solid-svg-icons/faCoins";
import ValueOf from "@pages/account/components/ValueOf";
import {faUsers} from "@fortawesome/pro-regular-svg-icons/faUsers";

const ReferralRewards = () => {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();

  const [bonuses, setBonuses] = useState<ReferralRewardBonus[]>([]);

  useEffect(() => {
    getReferralRewardBonuses()
      .then((response) => {
        if (response.success && response.data) {
          setBonuses(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch(handleResponse)
  }, [handleResponse]);

  return (
    <>
      {bonuses.map((b) => {
        let icon = faUserCrown;

        if (b.rewardType === RewardType.BUY_CONTRACT) {
          icon = faUsers;
        } else if (b.rewardType === RewardType.MATCHING_BONUS) {
          icon = faCoins;
        }

        return (
          <div className="card wd-100p wd-md-300 align-self-start d-inline-block mt-4 mb-4 mr-md-4" key={'bonus-' + b.rewardType}>
            <div className="card-body">
              <h4>
                <FontAwesomeIcon icon={icon} className="mr-2"/>
                {t('pages.referrals.rewards.' + b.rewardType)}
              </h4>

              <ValueOf
                value={b.rewardsCount}
                text={t('common.amount') + ':'}
              />
              <ValueOf
                value={b.usdAmount}
                text={t('common.earned') + ':'}
                floor={true}
                fixed={2}
                sign={'$'}
              />
            </div>
          </div>
        )
      })}
    </>
  )
}

export default React.memo(ReferralRewards);
