import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import logo from "@assets/images/logo.png";
import {faEnvelope, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import Translate from "@components/Translate";
import {useRecoilValue} from "recoil";
import {currentLanguage} from "@stores/language";
import SocialLinks from "@pages/landing/home/components/components/SocialLinks";
// import {SRLWrapper} from 'simple-react-lightbox';
// import docNew from "@assets/images/documents/doc-new.jpg";
// import doc1 from "@assets/images/documents/doc1.jpeg";
// import doc2 from "@assets/images/documents/doc2.jpeg";
// import doc3 from "@assets/images/documents/doc3.jpeg";
// import doc4 from "@assets/images/documents/doc4.jpeg";
// import doc5 from "@assets/images/documents/doc5.jpeg";
// import doc6 from "@assets/images/documents/doc6.jpeg";
// import doc7 from "@assets/images/documents/doc7.jpeg";
// import doc8 from "@assets/images/documents/doc8.jpeg";
// import doc9 from "@assets/images/documents/doc9.jpeg";
// import docEco from "@assets/images/documents/doc-eco.jpg";
// import certificate from "@assets/images/documents/certificate.jpg";

export default function Footer() {
  const language = useRecoilValue(currentLanguage);
  let whitePaper;
  if (language == 'ru' || language == 'en') {
    whitePaper = process.env.PUBLIC_URL + '/aurous-finance-' + language + '.pdf';
  } else {
    whitePaper = process.env.PUBLIC_URL + '/aurous-finance-en.pdf';
  }
  // const feasibilityStudy = process.env.PUBLIC_URL + '/FeasibiltyStudy.pdf';

  // const elements = [
  //   {src: doc1},
  //   {src: doc2},
  //   {src: docNew},
  //   {src: doc3},
  //   {src: doc4},
  //   {src: doc5},
  //   {src: doc6},
  //   {src: doc7},
  //   {src: doc8},
  //   // {src: doc9},
  //   {src: docEco},
  //   // {src: certificate},
  // ];

  return (
    <section id="footer" className="bg-black-2 pt-3 pt-md-6 pb-4">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-lg-2 text-center mt-4 mt-md-0 mb-md-0 order-last order-md-0">
            <img src={logo} width={100} alt="Aurous"/>
            <div className="tx-14 tx-gray-700 mt-3 mt-md-5 pt-md-5">
              &copy; {(new Date()).getFullYear()} All rights reserved.
            </div>
          </div>

          <div className="col-md-4 col-lg-2 mb-5">
            <div className="tx-18 tx-bold mb-3">
              BUILD
            </div>

            {/* <SRLWrapper>
              <a href={elements[0].src} className="tx-white pos-relative" target="_blank" rel="noreferrer">
                <Translate i18nKey={'common.documents'}/>
                <img src={elements[0].src} className="absolute-stretch" alt={'Document 1'}/>
              </a>

              {elements.filter((image, i) => i !== 0).map((image, key) => (
                <a href={image.src} key={'document-' + key} className="d-none">
                  <img src={image.src} className="absolute-stretch" alt={'Document ' + (key + 1)}/>
                </a>
              ))}
            </SRLWrapper> */}

            <div className="mt-2">
              <a href={whitePaper} className="tx-white" target="_blank" rel="noreferrer">
                <Translate i18nKey={'button.whitepaper'}/>
              </a>
            </div>

            {/* <div className="mt-2">
              <a href={feasibilityStudy} className="tx-white" target="_blank" rel="noreferrer">
                <Translate i18nKey={'button.feasibility-study'}/>
              </a>
            </div> */}

            <SocialLinks/>
          </div>

          <div className="col-md-3 col-lg-2 mb-5">
            <div className="tx-18 tx-bold mb-3">
              LEARN
            </div>

            <div className="mt-2">
              <a href="https://dzen.ru/aurous_finance" className="tx-white" target="_blank" rel="noreferrer">
                <Translate i18nKey={'common.blog'}/>
              </a>
            </div>

            <div className="mt-2">
              <a href="https://t.me/AUROUS_FINANCE_CHANNEL" className="tx-white" target="_blank" rel="noreferrer">
                <Translate i18nKey={'common.news'}/>
              </a>
            </div>

            <div className="mt-2">
              <a href="https://www.youtube.com/@aurous.finance" className="tx-white" target="_blank" rel="noreferrer">
                <Translate i18nKey={'common.videos'}/>
              </a>
            </div>

            {/* <div className="mt-2">
              <a href="https://www.linkedin.cn/" className="tx-white" target="_blank" rel="noreferrer">
                <Translate i18nKey={'common.network'}/>
              </a>
            </div> */}
          </div>

          <div className="col-md-12 col-lg-5">
            <div className="tx-18 tx-bold mb-3">
              <Translate i18nKey={'common.contacts'}/>
            </div>

            <div className="d-flex justify-content-between">
              <div className="tx-16 tx-gold mr-3">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
              </div>
              <div className="flex-1 tx-gray-400">
                <p>Rainbow Minerals Mining (сertificate #57510, Sudan)</p>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="tx-16 tx-gold mr-3">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
              </div>
              <div className="flex-1 tx-gray-400">
                <p>12 Sheikh Hospital Street, Al Khurtum, Sudan</p>
              </div>
            </div>

            {/* <div className="d-flex justify-content-between mb-2">
              <div className="tx-16 tx-gold mr-3">
                <FontAwesomeIcon icon={faEnvelope}/>
              </div>
              <div className="flex-1">
                <a className="tx-gray-400" href="mailto:pr@assetg.global">pr@assetg.global</a>
              </div>
            </div> */}

            <div className="d-flex justify-content-between mb-4">
              <div className="tx-16 tx-gold mr-3">
                <FontAwesomeIcon icon={faEnvelope}/>
              </div>
              <div className="flex-1">
                <a className="tx-gray-400" href="mailto:info@aurous.finance">info@aurous.finance</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
