import React, {useCallback, useEffect, useMemo, useState} from "react";
import QRCode from "qrcode.react";
import logo from "@assets/images/logo-bw.png";
import CopyToClipboard from "react-copy-to-clipboard";
import {useTranslation} from "react-i18next";
import {toast} from "react-hot-toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/pro-regular-svg-icons/faCopy";
import {Network, Ticker} from "@services/api";
import {WalletDepositOption} from "@services/wallet-api";
import formatNumber from "@util/format-number";
import {divide, multiply} from "@helpers/bignumber";
import {DepositOptions} from "@services/deposit-api";
import {faAngleDoubleDown} from "@fortawesome/pro-regular-svg-icons/faAngleDoubleDown";
import {faAngleDoubleRight} from "@fortawesome/pro-regular-svg-icons/faAngleDoubleRight";

export interface DepositAddressProps {
  walletOption?: WalletDepositOption;
  currency: Ticker;
  depositOptions?: DepositOptions;
}

const DepositAddress = ({walletOption, currency, depositOptions}: DepositAddressProps) => {
  const {t} = useTranslation();
  const [usd, setUsd] = useState<string>('0');
  const [amount, setAmount] = useState<string>('0');
  const address = walletOption?.address;
  const crypto = walletOption?.crypto;

  const network = useMemo(() => {
    let result: string = walletOption?.network + '' || '';

    if (walletOption?.crypto === Ticker.ETH && walletOption?.network === Network.ETHEREUM) {
      result += ' (ERC-20)';
    }

    return result;
  }, [walletOption]);

  const handleCopy = useCallback(() => {
    toast.success(t('alert.address-copied') + '', {duration: 5000});
  }, [t]);

  const handleUsd = useCallback((value: string, isUsdInput: boolean = false) => {
    if (!isUsdInput) {
      setAmount(value);
    } else {
      setUsd(value);
    }

    if (depositOptions && walletOption) {
      const price = depositOptions.options.find((o) => o.crypto === walletOption.crypto)!.usdPrice;

      if (!isUsdInput) {
        setUsd(formatNumber(multiply(value, price), 2, true, ''));
      } else {
        setAmount(divide(value, price));
      }
    }
  }, [walletOption, depositOptions]);

  useEffect(() => {
    if (address) {
      handleUsd(amount);
    }
  }, [amount, address, handleUsd]);

  if (!address || !crypto) {
    return null;
  }

  return (
    <>
      <div className="d-lg-flex flex-row mt-4">
        <div className="wd-175 mr-3">
          <QRCode value={address} size={175} imageSettings={{src: logo, width: 31, height: 31}}/>
        </div>

        <div>
          <div className="mb-2">
            <span className="tx-bold">{t('common.network')}: </span>
            <span>{network}</span>
          </div>

          <p className="mb-2">
            {t('pages.deposit.content.address-title')
              .replace('%currency', crypto)
              .replace('%wallet', currency)
            }
          </p>

          <div className="mb-2 wd-300 wd-lg-750 tx-left">
            <CopyToClipboard text={address} onCopy={handleCopy}>
              <button className="btn btn-link wd-100p word-break tx-md-28-f p-0 tx-left">{address}</button>
            </CopyToClipboard>
          </div>

          <CopyToClipboard text={address} onCopy={handleCopy}>
            <button className="btn btn-primary">
              <FontAwesomeIcon icon={faCopy}/> {t('button.copy')}</button>
          </CopyToClipboard>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-body">
          <h2 className="mb-2">{t('pages.deposit.content.calculator-title')}</h2>
          <p>{t('pages.deposit.content.calculator-text').replace('%crypto', crypto)}</p>

          <div
            className="d-flex flex-column flex-lg-row justify-content-between mt-4 mr-4 mr-lg-0 wd-100p wd-sm-300 wd-lg-650"
          >
            <div className="input-group">
              <input
                type="number"
                className="form-control"
                value={amount}
                onChange={event => handleUsd(event.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">{crypto}</div>
              </div>
            </div>

            <div className="tx-center tx-24 py-3 d-lg-none"><FontAwesomeIcon icon={faAngleDoubleDown}/></div>
            <div className="tx-center tx-24 px-3 d-none d-lg-block"><FontAwesomeIcon icon={faAngleDoubleRight}/></div>

            <div className="input-group">
              <input
                type="number"
                className="form-control"
                value={usd}
                onChange={event => handleUsd(event.target.value, true)}
              />
              <div className="input-group-append">
                <div className="input-group-text">USDT</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(DepositAddress);
