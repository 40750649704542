import React, {useEffect, useState} from 'react';
import bg from '../../assets/images/bg.jpg';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import {LandingLinks, SocialLinks} from "./pages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import {RedirectLocation} from "@helpers/types";

export default function Error() {
  const {t} = useTranslation();
  const location: RedirectLocation = useLocation();
  const [messages, setMessages] = useState<string[]>([]);

  useEffect(() => {
    if (location.state && location.state.messages) {
      setMessages(location.state.messages);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('common.error')}</title>
      </Helmet>
      <div
        id="error"
        className="flex-1 bg-image-center bg-image-cover bg-overlay p-4 p-md-5 pt-navbar d-flex flex-column justify-content-center align-items-center"
        style={{backgroundImage: `url(${bg})`}}
      >
        <h1 className="tx-28 tx-center">
          <div className="tx-bold tx-primary tx-44 mb-2">{t('common.error')}</div>
          {messages.map((error) => (
            <div key={error}>
              {t(error)}
            </div>
          ))}
        </h1>

        <div className="d-flex flex-column flex-md-row mt-4">
          <Link
            to={LandingLinks.HOME}
            className="btn btn-primary mb-2 mb-md-0 mr-md-3"
          >
            <FontAwesomeIcon icon={faHome} className="mr-2"/>
            {t('pages.home.title')}
          </Link>

          <a
            href={SocialLinks.TELEGRAM}
            className="btn btn-blue"
          >
            {t('button.contact-support')}
          </a>
        </div>
      </div>
    </>
  );
}
