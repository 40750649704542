import React from "react";
import Translate from "@components/Translate";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {loginState} from "@stores/account";
import AssetsPrice from "@pages/landing/home/components/AssetsPrice";
import Particles from "react-tsparticles";
import config from "./components/particles-config.json";
import {ISourceOptions} from "tsparticles";
import pic from "@assets/images/main-image.jpg";
import {getAccountUrl} from "@helpers/getAccountUrl";

export default function MainScreen() {
  const {t} = useTranslation();
  const isLoggedIn = useRecoilValue(loginState);

  return (
    <section className="pt-navbar pos-relative" id="main">
      <div className="shapes-wrapper">
        <Particles
          id="particles"
          options={config as ISourceOptions}
          canvasClassName="pos-absolute-f"
          style={{zIndex: -1}}
        />

        {/* <span className="blurred-shape shape-purple wd-450 ht-500"/>
        <span className="blurred-shape shape-pink wd-450 ht-500 animation-delay-2000"/>
        <span className="blurred-shape shape-dark wd-550 ht-550 animation-delay-4000"/> */}
      </div>

      <div className="container">
        <div className="row pt-6 pb-5">
          <div className="col-md-6 d-flex justify-content-center align-items-center">

            <div>
              <img src={pic} alt="" />

              {/* <iframe
                src={t('pages.home.content.video')}
                frameBorder="0"
                title="AssetG Global"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              /> */}
            </div>
          </div>

          <div className="col-md-6">
            <h1 className="wd-lg-500 tx-32 tx-lg-48 pt-5 mb-4">
              <Translate i18nKey={'pages.home.content.heading'}/>
            </h1>
            <h3 className="tx-18 mb-4">
              <Translate i18nKey={'pages.home.content.subheading'}/>
            </h3>

            {!isLoggedIn && (
              <div className="tx-center tx-md-left">
                <a
                  href={getAccountUrl()}
                  className="wd-150 btn btn-lg btn-primary ml-sm-2 mr-2 login"
                >
                  {t('pages.login.title')}
                </a>

                <a
                  href={getAccountUrl('/register')}
                  className="wd-150 btn btn-lg btn-outline-primary login"
                >
                  {t('pages.register.title')}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-5 z-index-10">
        <AssetsPrice/>
      </div>
    </section>
  );
}
