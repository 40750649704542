import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import {
  convertStringToNumber,
  divide,
  greaterThan,
  multiply,
  smallerThan,
  smallerThanOrEqual,
} from "@helpers/bignumber";
import useResponseHandler from "@hooks/useResponseHandler";
import {Ticker} from "@services/api";
import {createOrder} from "@services/p2p-api";
import {useTranslation} from "react-i18next";
import {faPlus} from "@fortawesome/pro-regular-svg-icons/faPlus";
import FormattedNumber from "@components/FormattedNumber";
import {Wallet} from "@services/wallet-api";
import formatNumber from "@util/format-number";
import confirmAlert from "@components/ConfirmAlert";

enum OrderType {
  BUY = 'BUY',
  SELL = 'SELL',
}

export interface CreateOrderModalProps {
  updateWallets: () => void;
  wallets: Wallet[];
}

export default function CreateOrderModal({updateWallets, wallets}: CreateOrderModalProps) {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const [orderType, setOrderType] = useState<OrderType>(OrderType.BUY);
  const [currencyFrom, setCurrencyFrom] = useState<Ticker>(Ticker.USDT);
  const [currencyTo, setCurrencyTo] = useState<Ticker>(Ticker.ASG);
  const [cryptoAmount, setCryptoAmount] = useState<string>('');
  const [pricePerCoin, setPricePerCoin] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isConfirmShown, setConfirmShown] = useState<boolean>(false);
  const allowedWallets = [Ticker.ASG, Ticker.USDT];
  const min = 0.01;
  const max = 2.5;

  const balance = wallets.find((w) => w.currency === currencyFrom)!.balance;

  const resetForm = useCallback(() => {
    setOrderType(OrderType.BUY);
    setCurrencyFrom(Ticker.USDT);
    setCurrencyTo(Ticker.ASG);
    setCryptoAmount('');
    setPricePerCoin('');
  }, []);

  const handleClose = useCallback(() => {
    if (isLoading) {
      return;
    }

    setShow(false);
    resetForm();
  }, [isLoading, resetForm]);

  const maxAmount = formatNumber(
    currencyFrom === Ticker.USDT
      ? pricePerCoin ? divide(divide(balance, pricePerCoin), 1.01) : 0
      : balance ? divide(balance, 1.01) : 0,
  2,
  true,
  '',
  '.'
  );

  const isAmountValid = cryptoAmount && (
    !greaterThan(cryptoAmount, maxAmount) && !smallerThanOrEqual(convertStringToNumber(cryptoAmount), 0)
  );

  const handleShow = () => setShow(true);

  const totalUsdt = useMemo(() => {
    return convertStringToNumber(multiply(pricePerCoin || 0, cryptoAmount || 0)) || 0;
  }, [pricePerCoin, cryptoAmount]);

  const isPriceValid = useMemo(() => {
    const isInvalid = pricePerCoin && (
      smallerThan(pricePerCoin, min) || greaterThan(pricePerCoin, max)
    );

    if (isInvalid) {
      return false;
    }

    return pricePerCoin && !isInvalid ? true : null;
  }, [pricePerCoin]);

  const orderSummary = (
    <>
      <div className="tx-20 tx-bold">
        {t(orderType === OrderType.BUY
          ? 'pages.p2p.content.you-will-spend'
          : 'pages.p2p.content.you-will-receive'
        )}
      </div>

      <div>
        <FontAwesomeIcon icon={faCoins} className="mr-2"/>
        {orderType === OrderType.BUY ? multiply(totalUsdt, 1.01) : totalUsdt} USDT
        {orderType === OrderType.SELL && (
          <div>
            <div className="tx-semibold">{t( 'pages.p2p.content.you-will-spend')}:</div>
            {multiply(cryptoAmount || 0, 1.01)} ASG
          </div>
        )}
        <div className="tx-gray-500">
          {t('common.fee')}: {multiply(
            orderType === OrderType.BUY ? totalUsdt : cryptoAmount,
          0.01,
          )} {orderType === OrderType.BUY ? 'USDT' : 'ASG'}
        </div>
      </div></>
  )

  const alertBody = (
    <>
      <div className="tx-bold mb-2">{t('alert.confirm-order-create')}</div>

      <div>
        <span className="tx-bold mr-2">
          {t('common.type')}:
        </span>
        <span>{t(orderType === OrderType.BUY ? 'order.type.buy' : 'order.type.sell')}</span>
      </div>

      <div>
        <span className="tx-bold mr-2">
          {t('common.coin-amount')}:
        </span>
        <FormattedNumber
          value={cryptoAmount}
          postfix={'ASG'}
        />
      </div>

      <div>
        <span className="tx-bold mr-2">
          {t('pages.p2p.content.price-per-1-asg')}:
        </span>
        <FormattedNumber
          value={pricePerCoin}
          postfix={'$'}
        />
      </div>

      {orderSummary}
    </>
  );

  const handleCreate = useCallback(() => {
    if ((totalUsdt && !isPriceValid) || !pricePerCoin) {
      handleResponse(
        t('error.invalid-price-range')
          .replace('%from', min + '$')
          .replace('%to', max + '$'),
        true
      );
      return;
    }

    if (
      greaterThan(cryptoAmount, maxAmount) ||
      (currencyFrom === Ticker.USDT && greaterThan(totalUsdt, wallets.find(w => w.currency === Ticker.USDT)!.balance))
    ) {
      handleResponse('err.contract.not_enough_balance', true);
      return;
    }

    if (!isAmountValid) {
      handleResponse('error.invalid-tokens-amount', true);
      return;
    }

    if (!totalUsdt) {
      handleResponse('error.empty-fields');
      return;
    }

    setConfirmShown(true)

    confirmAlert({
      confirmation: alertBody,
      okLabel: t('common.create-order'),
      cancelLabel: t('button.cancel'),
      title: t('common.create-order'),
    }).then((success) => {
      setConfirmShown(false);

      if (!!success) {
        setLoading(true);
        createOrder({
          currencyFrom,
          currencyTo,
          currencyFromAmount: orderType === OrderType.BUY ? totalUsdt : convertStringToNumber(cryptoAmount),
          currencyToAmount: orderType === OrderType.SELL ? totalUsdt : convertStringToNumber(cryptoAmount),
        })
          .then((response) => {
            setLoading(false);

            if (response.success) {
              handleClose();
              handleResponse(t('alert.p2p-order-created'));
              updateWallets();
            } else {
              handleResponse(response);
            }
          })
          .catch((response) => {
            handleResponse(response);
            setLoading(false);
          });
      }
    });
  }, [
    pricePerCoin,
    isAmountValid,
    wallets,
    isPriceValid,
    orderType,
    currencyFrom,
    currencyTo,
    totalUsdt,
    cryptoAmount,
    updateWallets,
    handleClose,
    handleResponse,
    t,
  ]);


  useEffect(() => {
    setCurrencyFrom(orderType === OrderType.BUY ? Ticker.USDT : Ticker.ASG);
    setCurrencyTo(orderType === OrderType.BUY ? Ticker.ASG : Ticker.USDT);
  }, [orderType])

  return (
    <>
      <button
        className="btn btn-primary align-self-start"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2"/>
        {t('common.create-order')}
      </button>

      <Modal show={show} onHide={handleClose} className={isConfirmShown ? 'd-none' : ''}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('common.create-order')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <div className="tx-bold">
              {t('pages.buy.content.balance')}:
            </div>
            {wallets.filter(w => allowedWallets.indexOf(w.currency) !== -1).map((w) => (
              <div key={'create-order-balance-' + w.currency} className="tx-semibold">
                <FormattedNumber
                  value={w.balance}
                  decimals={2}
                  floor={true}
                  postfix={w.currency}
                />
              </div>
            ))}
          </div>

          <h5>{t('pages.p2p.content.i-want-to')}</h5>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <div className="input-group-text wd-50 justify-content-center">
                <FontAwesomeIcon icon={faShoppingCart}/>
              </div>
              <FontAwesomeIcon icon={faChevronDown} className="input-group-select-icon"/>
            </div>
            <select
              className="form-control appearance-none"
              value={orderType}
              onChange={(e) => setOrderType(e.target.value as OrderType)}
              disabled={isLoading}
            >
              <option value={OrderType.BUY}>{t('button.buy')} ASG</option>
              <option value={OrderType.SELL}>{t('button.sell')} ASG</option>
            </select>
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <div className="input-group-text">
                {t('common.for-price')}
              </div>
            </div>
            <input
              type="number"
              placeholder={t('common.price')}
              className={[
                'form-control',
                isPriceValid === true ? 'is-valid' : '',
                isPriceValid === false ? 'is-invalid' : '',
              ].join(' ')}
              value={pricePerCoin}
              onChange={(e) => setPricePerCoin(e.target.value)}
              disabled={isLoading}
            />
            <div className="input-group-append">
              <div className="input-group-text text-lowercase">
                $ {t('common.per-coin')}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3">
            <span>
              {t('common.min')}:
              <span
                className="tx-primary clickable mx-1"
                onClick={() => setPricePerCoin(min + '')}
              >
                <FormattedNumber
                  value={min}
                  postfix={'$'}
                />
              </span>
            </span>
            <span>
              {t('common.max')}:
              <span
                className="tx-primary clickable mx-1"
                onClick={() => setPricePerCoin(max + '')}
              >
                <FormattedNumber
                  value={max}
                  postfix={'$'}
                />
              </span>
            </span>
          </div>

          <div className="input-group mb-0">
            <input
              type="number"
              className={[
                'form-control',
                cryptoAmount !== '' && isAmountValid ? 'is-valid' : '',
                pricePerCoin !== '' && cryptoAmount !== '' && !isAmountValid ? 'is-invalid' : '',
              ].join(' ')}
              placeholder={t('common.amount')}
              value={cryptoAmount}
              onChange={(e) => setCryptoAmount(e.target.value)}
              disabled={isLoading}
            />
            <div className="input-group-append">
              <div className="input-group-text">
                ASG
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mb-3">
            <span className={convertStringToNumber(maxAmount) !== 0 ? '' : 'd-none'}>
              {t('common.max')}:
              <span
                className="tx-primary clickable mx-1"
                onClick={() => {
                  setCryptoAmount(
                    maxAmount
                  )
                }}
              >
                <FormattedNumber
                  value={maxAmount}
                  decimals={2}
                  postfix={'ASG'}
                />
              </span>
            </span>
          </div>

          {orderSummary}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
            {t('button.close')}
          </Button>

          <Button variant="success" onClick={handleCreate} disabled={isLoading}>
            <FontAwesomeIcon icon={isLoading ? faCircleNotch : faPlus} className="mr-2" spin={isLoading}/>
            {t('common.create-order')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
