import React, {useCallback, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import Translate from "@components/Translate";
import newsList from "@stores/news";
import {faArrowCircleLeft} from "@fortawesome/pro-light-svg-icons/faArrowCircleLeft";
import {faArrowCircleRight} from "@fortawesome/pro-light-svg-icons/faArrowCircleRight";
import NewsView from "@pages/news/components/NewsView";

export default function NewsSlider() {
  const newsSlider = useRef<Slider>(null);

  const prev = useCallback(() => {
    newsSlider.current!.slickPrev();
  }, [newsSlider]);

  const next = useCallback(() => {
    newsSlider.current!.slickNext();
  }, [newsSlider]);

  return (
    <section className="pt-lg-7 pb-lg-4 pt-md-2 pb-md-4 pt-sm-4 pb-sm-4 pt-4 pb-4" id="news-slider">
      <div className="container">
        <div className="d-flex justify-content-between mb-5">
          <h2><Translate i18nKey={'pages.home.content.news.title'}/></h2>
          <div>
            <button className="slick-prev mr-3" onClick={prev}>
              <FontAwesomeIcon icon={faArrowCircleLeft} className="tx-50"/>
            </button>
            <button className="slick-next" onClick={next}>
              <FontAwesomeIcon icon={faArrowCircleRight} className="tx-50"/>
            </button>
          </div>
        </div>

        <Slider
          ref={newsSlider}
          dots={false}
          arrows={false}
          infinite={true}
          speed={300}
          slidesToShow={2}
          slidesToScroll={1}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              }
            },
          ]}
        >
          {newsList.map((news, key) => (
            <NewsView item={news} short={true} key={'news-slider-' + key}/>
          ))}
        </Slider>
      </div>
    </section>
  );
}
