import React, {useCallback} from 'react';
import {Link, Redirect, useLocation} from "react-router-dom";
import {AccountLinks, AccountPages, LandingLinks} from "@pages/pages";
import logo from "@assets/images/logo.png";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {accountInfo, loginState} from "@stores/account";
import {useTranslation} from "react-i18next";
import useLoadableValue from "@hooks/useLoadableValue";

export default function Sidenav() {
  const {t} = useTranslation();
  const location = useLocation();
  const [isLoggedIn, setLoggedIn] = useRecoilState(loginState);
  const account = useLoadableValue(accountInfo);

  const closeMenu = useCallback(() => {
    document.body.classList.remove('open-nav');
  }, []);

  const signOut = useCallback(() => {
    closeMenu();
    setLoggedIn(false);
  }, [closeMenu, setLoggedIn]);

  if (!isLoggedIn) {
    return (
      <Redirect to={LandingLinks.HOME}/>
    )
  }

  return (
    <nav id="sidenav">
      <Link to={LandingLinks.HOME} className="dashboard-logo" onClick={closeMenu}>
        <img src={logo} width={65} alt="Aurous"/>
      </Link>

      <div className="sidenav-links">
        {AccountPages.filter(p => p.enabled && p.inSidenav).map((page, index) => {
          const linkClass = location.pathname === page.link ? 'sidenav-link active' : 'sidenav-link';

          if (page.link === AccountLinks.P2P && !account?.metadata.p2pEnabled) {
            return null;
          }

          return (
            <Link
              to={page.link}
              className={linkClass}
              key={'sidenav-link-' + index}
              onClick={closeMenu}
            >
              <div className="sidenav-link-icon">{page.icon}</div>
              <span>{t(page.title)}</span>
            </Link>
          );
        })}
      </div>

      <button
        className="btn btn-danger"
        onClick={signOut}
      >
        <FontAwesomeIcon icon={faSignOutAlt}/> {t('button.sign-out')}
      </button>
    </nav>
  );
}
