import React from 'react';
import bg from '@assets/images/bg.jpg';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet-async";
import {LandingLinks} from "@pages/pages";

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>Aurous | Страница не найдена</title>
      </Helmet>
      <div id="not-found" className="flex-1 bg-image-center bg-image-cover bg-overlay p-4 p-md-5 pt-navbar d-flex flex-column justify-content-center align-items-center"
           style={{backgroundImage: `url(${bg})`}}>
        <h1 className="tx-36 tx-lg-42 tx-center">
          <div className="tx-bold tx-primary tx-80">404</div>
          Страница не найдена
        </h1>
        <Link to={LandingLinks.HOME} className="btn btn-primary btn-lg tx-20 mt-4">На главную страницу</Link>
      </div>
    </>
  );
}
