import {API_URL, handleGet, Network, PromiseStatus, Ticker} from "@services/api";
import fetchWithTimeout from "@util/fetchWithTimeout";
import {WalletDepositOption} from "@services/wallet-api";

export interface DepositOptionListItem {
  crypto: Ticker;
  networks: Network[];
  usdPrice: number;
}

export interface DepositOptions {
  currency: Ticker;
  options: DepositOptionListItem[];
}

export interface CreateDepositOptionData {
  currency: Ticker;
  crypto: Ticker;
  network: Network;
}

export function getDepositOptions():PromiseStatus<DepositOptions[]> {
  return fetchWithTimeout(API_URL + '/api/v1/wallet/deposit/options', {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function createDepositOption({currency, crypto, network}: CreateDepositOptionData):PromiseStatus<WalletDepositOption> {
  const urlParts = [
    '/api',
    'v1',
    'wallet',
    'deposit',
    currency,
    crypto,
    network,
  ].join('/');

  return fetchWithTimeout(API_URL + urlParts, {
    method: 'GET',
  }).then(data => handleGet(data));
}
