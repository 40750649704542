import React, {useCallback, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import logo from '@assets/images/logo.png';
import {AccountLinks, LandingLinks} from "@pages/pages";
import {useRecoilValue} from "recoil";
import {loginState} from "@stores/account";
import {useTranslation} from "react-i18next";
import {Link as ScrollLink} from "react-scroll";
import LanguageSwitcher from "@components/common/language-switcher/LanguageSwitcher";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faWallet} from "@fortawesome/free-solid-svg-icons/faWallet";
import {faChevronLeft} from "@fortawesome/pro-regular-svg-icons/faChevronLeft";

export default function Header() {
  const collapseId = 'navbarNavDropdown';
  const isLoggedIn = useRecoilValue(loginState);

  const {t} = useTranslation();
  const location = useLocation();
  const isHome = location.pathname === LandingLinks.HOME;

  const toggleNav = useCallback(() => {
    const collapse = document.getElementById(collapseId);
    const navButton = document.getElementById('toggleNavbar');
    const navbar = document.getElementById('navbar');

    collapse?.classList.toggle('show');
    navButton?.classList.toggle('opened');

    if (navbar?.classList.contains('navbar-transparent')) {
      navbar?.classList.remove('navbar-transparent');
    } else if (!collapse?.classList.contains('show') && window.scrollY < 25) {
      navbar?.classList.add('navbar-transparent');
    }
  }, []);

  const logoClick = useCallback(() => {
    const collapse = document.getElementById(collapseId);

    if (collapse?.classList.contains('show')) {
      toggleNav();
    }
  }, [toggleNav]);

  const changeBackground = () => {
    const navbar = document.getElementById('navbar');
    const collapse = document.getElementById(collapseId);

    navbar?.classList.toggle(
      'navbar-transparent',
      !collapse?.classList.contains('show') && window.scrollY < 25
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground)
  }, []);

  const landingBlocks = [
    {
      id: 'about-us',
      title: t('navigation.about'),
    },
    {
      id: 'ecosystem',
      title: t('navigation.ecosystem'),
    },
    {
      id: 'road-map',
      title: t('navigation.road-map'),
    },
    // {
    //   id: 'our-team',
    //   title: t('navigation.team'),
    // },
    {
      id: 'partners',
      title: t('navigation.partners'),
    },
    {
      id: 'news-slider',
      title: t('navigation.news'),
    },
    {
      id: 'faq',
      title: t('navigation.faq'),
    },
  ];

  const Logo = <img src={logo} width={100} alt="Aurous"/>;

  return (
    <nav className="navbar navbar-fixed wd-100p navbar-expand-lg dark px-md-5 navbar-transparent" id="navbar">
      <div className="container px-md-0">
        {!isHome && (
          <Link to={LandingLinks.HOME} className="navbar-brand" onClick={toggleNav}>
            {Logo}
          </Link>
        )}
        {isHome && (
          <ScrollLink
            to="main"
            offset={-100}
            smooth={true}
            className="navbar-brand cur-pointer"
            onClick={logoClick}
          >
            {Logo}
          </ScrollLink>
        )}
        <button
          className="navbar-toggler"
          type="button"
          aria-controls={collapseId}
          aria-label="Toggle navigation"
          onClick={toggleNav}
          id="toggleNavbar"
        >
          <span className="navbar-toggler-span navbar-toggler-span-top">
						<span />
					</span>
          <span className="navbar-toggler-span navbar-toggler-span-bottom">
						<span />
					</span>
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse d-md-flex" id={collapseId}>
          <ul className="navbar-nav ml-lg-auto mr-auto mr-lg-5">
            {isHome && landingBlocks.map((block, key) => (
              <li className="nav-item" key={'nav-link-' + key}>
                <ScrollLink
                  to={block.id}
                  offset={-100}
                  className="nav-link cur-pointer"
                  smooth={true}
                  onClick={toggleNav}
                >
                  {block.title}
                </ScrollLink>
              </li>
            ))}
            {!isHome && (
              <Link to={LandingLinks.HOME} className="nav-link cur-pointer" onClick={toggleNav}>
                <FontAwesomeIcon icon={faChevronLeft} className="mr-2"/>
                {t('navigation.to-home')}
              </Link>
            )}
          </ul>

          <div className="side-nav ml-md-2 text-md-right text-lg-left">
            <LanguageSwitcher/>
            {/* {!isLoggedIn && (<>
              <Link to={LandingLinks.LOGIN} className="btn btn-primary ml-sm-2 mr-2" onClick={toggleNav}>
                {t('pages.login.title')}
              </Link>
            </>)}
            {isLoggedIn && (<>
              <Link to={AccountLinks.WALLET} className="btn btn-primary">
                <FontAwesomeIcon icon={faWallet} className="mr-2"/>
                {t('navigation.account')}
              </Link>
            </>)} */}
          </div>
        </div>
      </div>
    </nav>
  );
}
