import {RecoilValue, useRecoilValueLoadable} from "recoil";

export default function useLoadableValue<T>(recoilValue: RecoilValue<T>) {
  const {contents, state} = useRecoilValueLoadable<T>(recoilValue);

  if (state === "hasValue") {
    return contents;
  }

  return undefined;
}
