import React from "react";
import Translate from "@components/Translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowRight} from "@fortawesome/pro-regular-svg-icons/faLongArrowRight";
import {News} from "@stores/news";
import {format, parseISO} from "date-fns";

export interface NewsViewProps {
  item: News;
  short?: boolean;
}

export default function NewsView({item, short = false}: NewsViewProps) {
  const {image, title, slug, shortDescription, description, date} = item;
  return (
    <article id={'news-' + slug} className="clearfix">
      <img src={image} alt={title} className={short ? '' : 'float-md-left wd-md-500 mr-3 newsImage'}/>

      {!short && (
        <div className="mt-5 tx-gray-600">
          {format(parseISO(date), 'dd.MM.yyyy HH:mm')}
        </div>
      )}
      <h3 className={short ? 'tx-26 mt-5 mb-4' : 'tx-26 mt-2 mb-4'}>{title}</h3>
      <div className="tx-gray-400 mb-3">{short ? shortDescription : description}</div>

      {short && (
        <a href={'/news/' + slug} className="read-more d-flex align-items-center wd-150">
          <Translate i18nKey={'button.read-more'}/>
          <FontAwesomeIcon icon={faLongArrowRight} className="ml-2"/>
        </a>
      )}
    </article>
  );
}
