import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import useResponseHandler from "@hooks/useResponseHandler";
import {getWalletList, Wallet} from "@services/wallet-api";
import {Network, Ticker, TransactionType} from "@services/api";
import PageHeader from "@pages/account/components/PageHeader";
import Preloader from "@components/Preloader";
import {getWithdrawalInfo, withdraw, WithdrawalInfo} from "@services/withdraw-api";
import Placeholder from "@components/Placeholder";
import FormattedNumber from "@components/FormattedNumber";
import formatNumber from "@util/format-number";
import {convertStringToNumber, greaterThan, smallerThan, smallerThanOrEqual} from "@helpers/bignumber";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import {faCoin} from "@fortawesome/pro-regular-svg-icons/faCoin";
import {faCreditCard} from "@fortawesome/pro-regular-svg-icons/faCreditCard";
import useLoadableValue from "@hooks/useLoadableValue";
import {accountInfo} from "@stores/account";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import TransactionsTable, {TransactionsTableRef} from "@pages/account/components/transactions/TransactionsTable";

export default function WithdrawalPage() {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const account = useLoadableValue(accountInfo);
  const transactions = useRef<TransactionsTableRef>(null);
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [withdrawalInfos, setWithdrawalInfos] = useState<WithdrawalInfo[]>([]);
  const [tab, setTab] = useState<Ticker>(Ticker.USDT);
  const [amount, setAmount] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [tfaCode, setTfaCode] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const allowedWallets: Ticker[] = [Ticker.USDT, Ticker.USDT_PROFIT];

  const loadWallets = useCallback(() => {
    setLoading(true);

    getWalletList()
      .then((response) => {
        if (response.success && response.data) {
          setWallets(response.data);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [handleResponse]);

  useEffect(() => {
    loadWallets();
  }, [loadWallets]);

  const currentInfo = useMemo(() => {
    return withdrawalInfos.find((i) => i.currency === tab);
  }, [withdrawalInfos, tab]);

  const currentWallet = useMemo(() => {
    return wallets.find((w) => w.currency === tab);
  }, [wallets, tab]);

  const isBtcAddressValid = useMemo((): boolean => {
    return RegExp(/^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/).test(address);
  }, [address]);

  useEffect(() => {
    if (!currentInfo) {
      setLoading(true);
      getWithdrawalInfo({currency: tab})
        .then((response) => {
          if (response.success && response.data) {
            setWithdrawalInfos((old) => [...old, response.data!]);
          } else {
            handleResponse(response);
          }
          setLoading(false);
        })
        .catch((response) => {
          handleResponse(response);
          setLoading(false);
        });
    }
  }, [tab, currentInfo, handleResponse]);

  const onClickCallback = useCallback(() => {
    if (currentWallet) {
      setAmount(formatNumber(currentWallet.balance, 3, true, ''));
    }
  }, [currentWallet]);

  const submitWithdrawal = useCallback(() => {
    if (!amount || !address) {
      handleResponse('error.empty-fields');
      return;
    }

    if (!isBtcAddressValid) {
      handleResponse('err.withdrawal.invalid-address');
      return;
    }

    if (currentInfo && smallerThan(amount, currentInfo.minWithdrawalAmount)) {
      handleResponse(
        t('pages.withdrawal.content.min') + ': ' + currentInfo.minWithdrawalAmount + '$',
        true
      );
      return;
    }

    if (currentWallet && greaterThan(amount, currentWallet.balance)) {
      handleResponse('err.tokens.insufficient-available-balance', true);
      return;
    }

    setLoading(true);
    withdraw({
      amount: convertStringToNumber(amount),
      address,
      currency: tab,
      network: Network.BITCOIN,
      crypto: Ticker.BTC,
      tfaCode,
    })
      .then((response) => {
        if (response.success) {
          loadWallets();
          setAmount('');
          setAddress('');
          setTfaCode('');

          handleResponse(
            t('alert.withdrawal-success')
              .replace('%amount', amount)
              .replace('%currency', currentInfo!.currency)
          );

          transactions.current?.refresh();
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [tfaCode, amount, address, tab, currentInfo, currentWallet, isBtcAddressValid, loadWallets, transactions, t, handleResponse]);

  const header = <PageHeader title={'pages.withdrawal.title'}/>;

  if (!wallets.length) {
    return (
      <>
        {header}
        <Preloader inline={true}/>
      </>
    )
  }

  return (
    <>
      {header}

      <div className="mb-2">
        {wallets.filter((w) => allowedWallets.indexOf(w.currency) !== -1).map((w) => (
          <button
            key={w.currency}
            className={'btn btn-lg tx-bold tx-16-f mr-3 mb-2 btn-' + (tab === w.currency ? 'primary' : 'gray')}
            onClick={() => {
              setTab(w.currency);
            }}
          >
            {t('wallet.title.' + w.currency)}
          </button>
        ))}
      </div>

      <div className="card wd-100p wd-md-350 d-block">
        <div className="card-body">
          <div>
            {t('pages.withdrawal.content.balance')}
            {': '}
            <span className="tx-bold">
              {!currentWallet
                ? <Placeholder/>
                : (
                  <button
                    className="btn btn-link tx-bold p-0"
                    onClick={onClickCallback}
                  >
                    <FormattedNumber
                      value={currentWallet.balance}
                      decimals={2}
                      floor={true}
                      postfix={currentWallet.currency}
                    />
                  </button>
                )
              }
            </span>
          </div>

          <div>
            {t('common.fee')}
            {': '}
            <FormattedNumber
              className="tx-bold"
              value={currentInfo?.feePercents}
              decimals={1}
              floor={true}
              postfix={'%'}
            />
          </div>

          <div>
            {t('pages.withdrawal.content.min')}
            {': '}
            <FormattedNumber
              className="tx-bold"
              value={currentInfo?.minWithdrawalAmount}
              decimals={3}
              floor={true}
              postfix={currentInfo?.currency}
            />
          </div>
        </div>

        {isLoading && <Preloader/>}
      </div>

      <div className="card with-button wd-100p wd-md-600 d-block mt-4">
        <div className="card-body">
          <div className="input-group">
            <input
              type="number"
              className={
                [
                  'form-control',
                  (amount !== '' && currentInfo && currentWallet && (parseFloat(amount) < parseFloat(formatNumber(currentInfo.minWithdrawalAmount, 2, true, '')) || parseFloat(amount) <= 0 || greaterThan(amount, currentWallet.balance)) ? 'is-invalid' : ''),
                  (amount !== '' && currentInfo && currentWallet && parseFloat(amount) >= parseFloat(formatNumber(currentInfo.minWithdrawalAmount, 2, true, '')) && parseFloat(amount) > 0 && smallerThanOrEqual(convertStringToNumber(amount), currentWallet.balance) ? 'is-valid' : ''),
                ].join(' ')
              }
              value={amount}
              disabled={isLoading || !currentInfo || !currentWallet}
              onChange={event => setAmount(event.target.value)}
            />
            <div className="input-group-prepend">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faCoin} className="mr-2"/>
                USDT
              </div>
            </div>
          </div>

          <div className="input-group mt-md-2">
            <input
              type="text"
              className={'form-control ' + (isBtcAddressValid ? 'is-valid' : '')}
              value={address}
              disabled={isLoading}
              onChange={event => setAddress(event.target.value)}
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faCreditCard} className="mr-2"/>
                {t('input.btc-address')}
              </div>
            </div>
          </div>

          {account?.tfaEnabled && (
            <div className="input-group mt-md-2">
              <input
                type="number"
                className="form-control"
                id="tfa-code"
                value={tfaCode}
                disabled={isLoading}
                onChange={event => setTfaCode(event.target.value)}
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faKey} className="mr-2"/>
                  {t('input.auth-code')}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="card-button">
          <button
            className="btn btn-gold mt-4"
            type="button"
            onClick={submitWithdrawal}
            disabled={isLoading || !account}
          >
            {(isLoading || !account) && (
              <FontAwesomeIcon icon={faCircleNotch} className="mr-2" spin/>
            )} {t('button.withdraw')}
          </button>
        </div>
      </div>

      <TransactionsTable
        types={[TransactionType.WITHDRAWAL]}
        ref={transactions}
        className="mt-5"
      />
    </>
  );
}
