import {API_URL, handleGet, Paged, PagedProps, PromiseStatus} from "@services/api";
import fetchWithTimeout from "@util/fetchWithTimeout";

export interface ReferralLevelRule {
  requiredFirstLinePersonalDeposit: number;
  firstLineUsdTurnover: number;
  requiredFirstLineUsdTurnover: number;
  totalUsdTurnover: number;
  requiredTotalUsdTurnover: number;
  firstLineReferralsAmount: number;
  requiredFirstLineReferralsAmount: number;
  personalUsdDeposit: number;
  requiredPersonalUsdDeposit: number;
  firstLineUsdTurnoverDone: boolean;
  totalUsdTurnoverDone: boolean;
  firstLineReferralsAmountDone: boolean;
  personalUsdDepositDone: boolean;
}

export interface ReferralInfo {
  accountId: number;
  level: number;
  refId: string;
  personalDeposit: number;
  referralsDeposit: number;
  totalTurnover: number;
  firstLineReferrals: number;
  totalReferrals: number;
  parentFullName: string | null;
  parentRefId: string | null;
  nextLevelRule: ReferralLevelRule | null;
}

export interface ReferralLine {
  line: number;
  referralsCount: number;
  turnover: number;
  rewardPercents: number;
}

export interface ReferralLevelInfo extends ReferralLevelRule {
  level: number;
  complete: boolean;
}

export interface Referral {
  accountId: number;
  registrationDate: string;
  refId: string;
  referralLevel: number;
  fullName: string;
  line: number;
  invitedByFullName: string;
  invitedByAccountId: number;
  personalDeposit: number;
  referralsDeposit: number;
  totalTurnover: number;
}

export enum ReferralListSort {
  PERSONAL_DEPOSIT_ASC= 'personalDeposit,asc',
  PERSONAL_DEPOSIT_DESC= 'personalDeposit,desc',
  REFERRAL_DEPOSIT_ASC= 'referralsDeposit,asc',
  REFERRAL_DEPOSIT_DESC= 'referralsDeposit,desc',
}

export interface ReferralListData extends PagedProps {
  line: string;
  referralAccountId?: number;
  sort?: ReferralListSort;
}

export interface ReferralParent {
  accountId: number;
  fullName: string;
  rewardLine: number;
  refId: string;
}

export enum RewardType {
  BUY_CONTRACT = 'BUY_CONTRACT',
  MATCHING_BONUS = 'MATCHING_BONUS',
  LEADER_BONUS = 'LEADER_BONUS',
}

export interface ReferralRewardBonus {
  rewardsCount: number;
  usdAmount: number;
  rewardType: RewardType;
}

const referralLink = (link: string, user?: number) => {
  let result = link;

  if (user) {
    result += '/user/' + user;
  }

  return result;
}

export function getReferralInfo(referralAccountId?: number):PromiseStatus<ReferralInfo> {
  return fetchWithTimeout(referralLink(API_URL + '/api/v1/referral', referralAccountId), {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function getReferralLineList(referralAccountId?: number):PromiseStatus<ReferralLine[]> {
  return fetchWithTimeout(referralLink(API_URL + '/api/v1/referral/line', referralAccountId), {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function getReferralLevel(level: string, referralAccountId?: number):PromiseStatus<ReferralLevelInfo> {
  return fetchWithTimeout(referralLink(API_URL + '/api/v1/referral/level/' + level, referralAccountId), {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function getReferralParents(referralAccountId: number):PromiseStatus<ReferralParent[]> {
  return fetchWithTimeout(referralLink(API_URL + '/api/v1/referral/parents/' + referralAccountId), {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function getReferralList(data: ReferralListData):PromiseStatus<Paged<Referral>> {
  const {line, page, size = 10, referralAccountId, sort} = data;

  const path = [
    API_URL,
    '/api',
    '/v1',
    '/referral',
    '/line/',
    line,
  ];

  if (referralAccountId) {
    path.push('/user/' + referralAccountId);
  }

  const props = [
    'page=' + page,
    'size=' + size,
  ];

  if (sort) {
    props.push('sort=' + sort);
  }

  const link = path.join('') + '?' + props.join('&');

  return fetchWithTimeout(link, {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function getReferralRewardBonuses():PromiseStatus<ReferralRewardBonus[]> {
  return fetchWithTimeout(API_URL + '/api/v1/wallet/transaction/referral/statistic', {
    method: 'GET',
  }).then(data => handleGet(data));
}
