import React from "react";
import {Transaction, TransactionType} from "@services/api";
import {useTranslation} from "react-i18next";
import CopyButton from "@components/common/CopyButton";

export interface TransactionDetailsProps {
  entry: Transaction;
}

export default function TransactionDetails({entry}: TransactionDetailsProps) {
  const {t} = useTranslation();
  let element;
  let prefix;
  let withCopy = false;

  if (entry.type === TransactionType.DEPOSIT) {
    element = entry.metadata?.transactionHash;
    prefix = t('common.transaction-hash');
    withCopy = true;
  }

  if (entry.type === TransactionType.WITHDRAWAL) {
    element = entry.metadata?.withdrawalAddress;
    prefix = t('common.address');
    withCopy = true;
  }

  if (entry.type === TransactionType.BUY_CONTRACT) {
    element = entry.metadata?.contractTemplateName;
    prefix = t('common.contract-template-name');
  }

  if (entry.type === TransactionType.REFERRAL_REWARD) {
    element =
      t('transaction.details.referral-reward')
        .replace('%line', entry.metadata?.rewardLine + '')
        .replace('%usd', entry.metadata?.usdBuyPrice + '');
  }

  if (element) {
    return (
      <span className="transaction-details word-break">
        {!!prefix && (
          <div className="tx-semibold">
            {prefix}:
          </div>
        )}
        {withCopy ? <CopyButton text={element}/> : <>{element}</>}
      </span>
    )
  }

  return null;
}
