import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Wallet} from "@services/wallet-api";
import {createDepositOption, DepositOptions} from "@services/deposit-api";
import {useTranslation} from "react-i18next";
import {Network, OrEmpty, Ticker} from "@services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronDown, faCoin} from "@fortawesome/pro-regular-svg-icons";
import DepositAddress from "@pages/account/deposit/components/DepositAddress";
import {faNetworkWired} from "@fortawesome/pro-regular-svg-icons/faNetworkWired";
import {faPlus} from "@fortawesome/pro-regular-svg-icons/faPlus";
import {faCircleNotch} from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import useResponseHandler from "@hooks/useResponseHandler";
import Translate from "@components/Translate";
import Placeholder from "@components/Placeholder";
import CopyButton from "@components/common/CopyButton";
import useLoadableValue from "@hooks/useLoadableValue";
import {accountInfo} from "@stores/account";

export interface DepositFormProps {
  wallet: Wallet;
  depositOptions?: DepositOptions;
  requestWalletUpdate: () => void;
}

const DepositForm = ({wallet, depositOptions, requestWalletUpdate}: DepositFormProps) => {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const account = useLoadableValue(accountInfo);
  const [crypto, setCrypto] = useState<OrEmpty<Ticker>>('');
  const [network, setNetwork] = useState<OrEmpty<Network>>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const walletOption = useMemo(() => {
    return wallet.depositOptions.find((o) => o.crypto === crypto);
  }, [wallet, crypto]);

  useEffect(() => {
    if (depositOptions && !crypto) {
      setCrypto(depositOptions.options[0].crypto);
    }
  }, [depositOptions, crypto]);

  useEffect(() => {
    if (depositOptions && crypto) {
      setNetwork(depositOptions.options.find((o) => o.crypto === crypto)!.networks[0]);
    }
  }, [depositOptions, crypto]);

  useEffect(() => {
    if (wallet) {
      setLoading(false);
    }
  }, [wallet]);

  const handleCreate = useCallback(() => {
    if (!crypto || !network) {
      return;
    }

    setLoading(true);

    createDepositOption({currency: wallet.currency, crypto, network})
      .then((response) => {
        if (!response.success || !response.data) {
          handleResponse(response);
          setLoading(false);
        } else {
          requestWalletUpdate();
        }
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [wallet, crypto, network, requestWalletUpdate, handleResponse]);

  if (!depositOptions) {
    return (
      <>
        {wallet.currency === Ticker.ASG ? (
          <div className="tx-semibold mb-4 tx-gold">
            <span className="mr-2">
              <Translate i18nKey="pages.deposit.content.transfer-from-assetg-finance"/>
            </span>
            {!account ? (
              <Placeholder/>
            ) : (
              <CopyButton text={account.email}/>
            )}
          </div>
        ) : (
          <div className="tx-semibold tx-warning">
            {t('error.no-available-deposit-methods')}
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <label className="tx-bold mt-3 mb-1">
        {t('common.currency')}
      </label>

      <div className="input-group wd-300 mb-3">
        <div className="input-group-prepend">
          <div className="input-group-text wd-50 justify-content-center">
            <FontAwesomeIcon icon={faCoin}/>
          </div>
          <FontAwesomeIcon icon={faChevronDown} className="input-group-select-icon"/>
        </div>

        <select
          className="form-control appearance-none"
          value={crypto}
          onChange={(e) => setCrypto(e.target.value as OrEmpty<Ticker>)}
          disabled={isLoading}
        >
          {depositOptions.options.map((option) => (
            <option key={option.crypto} value={option.crypto}>{option.crypto}</option>
          ))}
        </select>
      </div>

      {!walletOption && (
        <>
          <label className="tx-bold mt-2 mb-1">
            {t('common.network')}
          </label>

          <div className="input-group wd-300 mb-3">
            <div className="input-group-prepend">
              <div className="input-group-text wd-50 justify-content-center">
                <FontAwesomeIcon icon={faNetworkWired}/>
              </div>
              <FontAwesomeIcon icon={faChevronDown} className="input-group-select-icon"/>
            </div>

            <select
              className="form-control appearance-none"
              value={network}
              onChange={(e) => setNetwork(e.target.value as OrEmpty<Network>)}
              disabled={isLoading}
            >
              {depositOptions.options.find((o) => o.crypto === crypto)?.networks.map((n) => (
                <option key={n} value={n}>{n.replace('_', ' ')}</option>
              ))}
            </select>
          </div>

          <button className="btn btn-gold mt-2" onClick={handleCreate} disabled={isLoading}>
            <FontAwesomeIcon icon={isLoading ? faCircleNotch : faPlus} className="mr-2" spin={isLoading}/>
            {t('button.generate-address')}
          </button>
        </>
      )}

      <DepositAddress
        walletOption={walletOption}
        currency={wallet.currency}
        depositOptions={depositOptions}
      />
    </>
  )
}

export default React.memo(DepositForm);
