import React, {useCallback, useState} from 'react';
import bg from '@assets/images/bg.jpg';
import {Redirect} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  checkReferral,
  RefInfo,
} from "@services/api";
import {AccountLinks} from "@pages/pages";
import {Helmet} from "react-helmet-async";
import useResponseHandler from "@hooks/useResponseHandler";
import {useRecoilState, useRecoilValue} from "recoil";
import {loginState, refState} from "@stores/account";
import {useTranslation} from "react-i18next";
import {faUser} from "@fortawesome/pro-regular-svg-icons/faUser";
import RegisterForm from "@pages/landing/register/components/RegisterForm";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";

export default function Register() {
  const {t} = useTranslation();
  const [ref, setRef] = useRecoilState(refState);
  const isLoggedIn = useRecoilValue(loginState);
  const [refInfo, setRefInfo] = useState<RefInfo>();
  const handleResponse = useResponseHandler();
  const [invitedBy, setInvitedBy] = useState<string>(ref ? ref : '');
  const [isLoading, setLoading] = useState<boolean>(false);

  const checkRefId = useCallback(() => {
    if (!invitedBy) {
      handleResponse('error.empty-fields');
      return;
    }

    setLoading(true);

    checkReferral(invitedBy)
      .then((response) => {
        setLoading(false);

        if (response.data) {
          setRef(invitedBy);
          setRefInfo(response.data);
        } else {
          handleResponse('error.ref-user-not-found');
        }
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [setRef, invitedBy, handleResponse]);

  if (isLoggedIn) {
    return (
      <Redirect to={AccountLinks.WALLET}/>
    )
  }

  if (!ref || !refInfo) {
    return (
      <>
        <Helmet>
          <title>Aurous | {t('pages.register.title')}</title>
        </Helmet>
        <form id="form-register" className="flex flex-1 bg-image-center bg-image-cover bg-overlay p-4 p-md-5 pt-navbar align-items-center pb-5"
              style={{backgroundImage: `url(${bg})`}}
              onSubmit={(e) => {e.preventDefault(); checkRefId();}}>
          <div className="container">
            <div id="register-form" className="wd-100p wd-md-500">
              <h2 className="tx-22 tx-sm-26 tx-md-32 mb-4">{t('pages.register.content.heading')}</h2>

              <div className="input-group input-group-lg">
                <div className="input-group-prepend">
                  <div className="input-group-text"><FontAwesomeIcon icon={faUser}/></div>
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder={t('input.partner-id')}
                  value={invitedBy}
                  id="partner-id"
                  onChange={event => setInvitedBy(event.target.value)}
                  disabled={isLoading}
                />
              </div>

              <button
                className="btn btn-lg btn-primary wd-100p mt-4 mb-4"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && (<FontAwesomeIcon icon={faCircleNotch} className="mr-2" spin/>)}
                {t('button.continue')}
              </button>
            </div>
          </div>
        </form>
      </>
    )
  }

  return (
    <>
      <RegisterForm refInfo={refInfo}/>
    </>
  );
}
