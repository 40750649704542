import LanguageStore from "@stores/language";
import {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {accountInfo, refState} from "@stores/account";
import useQuery from "@hooks/useQuery";
import LogRocket from 'logrocket';
import useLoadableValue from "@hooks/useLoadableValue";

export default function Stores() {
  const [ref, setRef] = useRecoilState(refState);
  const [isIdentified, setIdentified] = useState<boolean>(false);
  const account = useLoadableValue(accountInfo);
  const searchParams = useQuery();

  useEffect(() => {
    if (searchParams.get('ref')) {
      setRef(searchParams.get('ref'));
    }
  }, [ref, setRef, searchParams]);

  useEffect(() => {
    if (!isIdentified && account) {
      setIdentified(true);

      LogRocket.identify(account.id + '', {
        name: account.firstName + ' ' + account.lastName,
        email: account.email,
        phone: account.phone || false,
      });
    }
  }, [isIdentified, account]);

  return (
    <>
      <LanguageStore/>
    </>
  )
}
