import React from 'react';
import {useTranslation} from "react-i18next";
import FormattedDate from "@util/FormattedDate";
import {Order, P2PAccount} from "@services/p2p-api";
import {orderHeaders, OrdersTableColumnMode} from "@pages/account/p2p/orders/OrdersTable";
import {Ticker} from "@services/api";
import FormattedNumber from "@components/FormattedNumber";
import OrderAction from "@pages/account/p2p/orders/OrderAction";
import CopyButton from "@components/common/CopyButton";

export interface OrdersTableRowProps {
  entry: Order;
  account: P2PAccount;
  updateWallets: () => void;
  mode: OrdersTableColumnMode;
}

export default function OrdersTableRow(props: OrdersTableRowProps) {
  const {entry, mode} = props;
  const {currencyFrom, currencyTo, currencyFromAmount, currencyToAmount} = entry;
  const {t} = useTranslation();

  const isBuy = currencyFrom === Ticker.USDT;
  const from = isBuy ? currencyTo : currencyFrom;
  const to = isBuy ? currencyFrom : currencyTo;
  const fromAmount = !isBuy ? currencyFromAmount : currencyToAmount;
  const toAmount = isBuy ? currencyFromAmount : currencyToAmount;

  const columnContent = (id: string) => {
    let result = null;

    switch (id) {
      case 'common.date':
        result = (
          <FormattedDate date={entry.creationTimestamp}/>
        );
        break;
      case 'common.id':
        result = (
          <CopyButton text={entry.id} element={entry.id.split('-')[0]}/>
        );
        break;
      case 'common.sender':
        result = (
          <span>{entry.creatorNickname}</span>
        );
        break;
      case 'common.executor':
        result = (
          <span>{entry.executorNickname}</span>
        );
        break;
      case 'common.type':
        result = (
          <span>{t(isBuy ? 'order.type.buy' : 'order.type.sell')}</span>
        );
        break;
      case 'common.coin-amount':
        result = (
          <FormattedNumber
            value={fromAmount}
            postfix={from}
          />
        );
        break;
      case 'pages.p2p.content.price-per-1-asg':
        result = (
          <FormattedNumber
            value={entry.usdPrice}
            postfix={Ticker.USDT}
          />
        );
        break;
      case 'common.total':
        result = (
          <FormattedNumber
            value={toAmount}
            postfix={to}
          />
        );
        break;
      case 'common.status':
        result = (
          <>
            <div>{t('order.status.' + entry.status)}</div>
            {mode === OrdersTableColumnMode.ONLY_MY && <OrderAction props={props}/>}
          </>
        );
        break;
      case 'common.actions':
        result = (
          <OrderAction props={props}/>
        );
        break;
    }

    return result;
  };

  return (
    <tr>
      {orderHeaders.filter(h => !h.displayMode || h.displayMode === mode).map((h, i) => (
        <td key={'orders-table-row-' + i + '-' + h.id}>
          <div className="list-view-heading">
            {t(h.id)}
          </div>
          {columnContent(h.id)}
        </td>
      ))}
    </tr>
  );
}
