import React from "react";
import news1 from "@assets/images/news/news1.jpeg";
import news2 from "@assets/images/news/news2.jpeg";
import news3 from "@assets/images/news/news3.jpeg";
import news4 from "@assets/images/news/news4.jpeg";
import news5 from "@assets/images/news/news5.jpeg";

export interface News {
  slug: string;
  image: string;
  title: string;
  shortDescription: React.ReactNode;
  description: React.ReactNode;
  date: string;
}

const newsList: News[] = [
  {
    image: news1,
    slug: '1',
    title: 'CIP / CIL gilding factory.',
    shortDescription: (
      <>
        <p>The CIP/CIL gold treatment plant is a cyanide pulp filtration-free process.</p>
      </>
    ),
    description: (
      <>
        <p>The CIP/CIL gold treatment plant is a cyanide pulp filtration-free process that uses activated carbon to directly absorb and recover gold from cyanide pulp, including seven steps: leach pulp preparation, cyanide leaching, absorbed carbon capture, desorption of gold absorbed coal. , preparation of gold mud by current, processing of coal to extract gold and processing of leaching pulp.</p>
      </>
    ),
    date: '2023-02-02 08:11:00',
  },
  {
    image: news2,
    slug: '2',
    title: 'The process of obtaining gold from ore.',
    shortDescription: (
      <>
        <p>Currently, gold recovery technologies have been supplemented by cyanide and bacterial heap leaching, underground chloride leaching, and bacterial vat leaching. </p>
      </>
    ),
    description: (
      <>
        <p>Currently, gold recovery technologies have been supplemented by cyanide and bacterial heap leaching, underground chloride leaching, and bacterial vat leaching. Due to this, you can extract gold from almost any ore. Modern equipment makes it possible to conduct processes with greater efficiency and productivity.

However, the ability of specialists of the last century to implement complex processes with the simplest technical means in wooden resin barrels, in our opinion, is surprising and admirable.

To anyone interested in the history of gold mining, we recommend the book by Yu.E. Rukosuev "Gold and Platinum of the Urals: the history of mining in the late 19th - early 20th centuries." Yekaterinburg - 2004, 196 p. Below is a chapter from this book.</p>
        <p>In 1862, a Swedish subject, Nordstrom, conducted experiments in the chemical laboratory of Yekaterinburg to extract gold from the dumping sands of the Berezovsky fields using chlorine. For processing, he required sands that would contain at least 48 shares (1.32 g / t) of gold in the form of dust in 100 pounds. It was impossible to fulfill these conditions at the Berezovsky mines, since sands with a content of 20 and even 16 shares / 1 / (0.55-0.44 g / t) were washed there at that time. The experiment did not give any useful results.</p>
        <p>In 1886, engineer E.P. Zelenkov leased all his dumps from the owner of the Uspensky mine Novikov in the Troitsky district of the Orenburg province and built the first plant in the Urals for the chemical extraction of gold by chlorination according to the Platner method. This method included the following operations: ore roasting, gold chlorination in special vats, chlorine gold leaching and gold precipitation from solutions /2/.</p>
        <p>The first good results gave impetus to the construction of chlorination plants in the Urals in all areas where there were more dumps, mainly mined underground. All these plants were quite simple in design, they were all divided into three departments: enrichment, roasting and chlorination. Horizontal millstones were installed in the beneficiation department, which served to grind the ephels, which were packed into lumps. After washing, the enriched ephels, which contained mainly pyrites, entered the second department - the burning one. Roasting was carried out in reverberatory furnaces. His result was the conversion of sulfur compounds into sulfate. Throughout this process pyrites were mixed with iron combs to increase the speed and uniformity of firing, as well as to protect them from sintering /3/. After cooling, the ore entered the third section - chlorination, where chlorination, settling and sedimentation vats made of wood were installed.</p>
        <p>Chlorinated wooden vats were tarred inside and had a double bottom with holes. A layer of pure quartz was poured on it or asbestos cardboard was laid, which played the role of a filter. From below, two taps were connected to the vats: one served to release water, the other to let in chlorine. The second tap was connected by a lead pipe to an apparatus in which chlorine was obtained from a mixture of manganese peroxide, common salt (sodium chloride) and sulfuric acid. Up to 160 pounds of burnt pyrites were poured into the vats, covered with a lid and covered tightly, after which the chlorine supply valve was opened.</p>
        <p>Chlorination continued up to 6 days. Its duration depended on the firing temperature and the quality of pyrites. The time when the contents of the vat are chlorinated, i.e. when chlorine came to the surface of the ore, it was determined using ammonia, which was brought to a special hole in the lid of the vat. If white smoke began to rise, chlorination was considered complete. Then the valve of the chlorine pipeline was closed and the hole was covered up /4/. 12 hours after the chlorination ceased, water was pumped into the vat to dissolve the resulting chloride gold; the resulting solution was discharged through rubber pipes into a settling tank. The ore that remained in the vat was unloaded and taken to the dump. After a few days, the resulting precipitate was transferred to a settling tank, where gold was isolated using ferrous sulfate (iron sulfate), collected, dried and melted in special furnaces.</p>
        <p>18 chlorination tanks, 12 settling tanks and 8 settling tanks were installed at the plant of E.P. Zelenkov. For 5 years of work, from 1886 to 1890, 17 pounds, 2 pounds 83 spools 30 shares of gold were obtained /5/. It was obtained from dumps that were previously considered useless.</p>
        <p>In the 90s. 19th century in the Urals, another method of chemical extraction of gold began to be used - cyanization (cyanidation), which has become quite widespread. This was largely facilitated by the government decree of August 23, 1891, according to which the gold miner, who built a factory for the chemical extraction of gold and began to process old dumps, was exempted from paying land tax /6/.</p>
        <p>The cyanization method was invented in 1843 by engineer P.R.Bagration, who revealed the ability of gold to dissolve in aqueous solutions of alkaline cyanides /7/. The gold contained in the ephels.</p>
      </>
    ),
    date: '2023-02-23 13:42:00',
  },
  {
    image: news3,
    slug: '3',
    title: 'Interesting facts about Sudan.',
    shortDescription: (
      <>
        <p>Sudan is a country with a rich but difficult history...</p>
      </>
    ),
    description: (
      <>
        <p>Sudan is a country with a rich but difficult history. The irresponsible actions of the colonialists led to the fact that Sudan turned into a continuously seething hotbed of rebels and rebels of all stripes for many decades, and only recently the situation here has at least relatively calmed down. Tourists in Sudan have nothing to do, although there are brave travelers who are not afraid to visit this little-studied corner of the Earth.</p>
        <p>Interesting facts about Sudan.</p>
        <p>Western colonialists established the borders of Sudan, completely ignoring the interfaith and economic ties that existed in the region. As a result, the country has been in a state of civil war for almost its entire history. In 2011, the feuds culminated in the secession of South Sudan, which became an independent state in a subsequent referendum.</p>
        <p>On the border of Egypt and Sudan, there is an area of   more than 2 thousand kilometers, which belongs to no one. Initially, it was the territory of Sudan, but then the British government changed the administrative border between the countries, giving the area into the possession of Egypt. Later, Cairo demanded to return to the old borders and refused the territory granted to it, while Sudan preferred to leave everything as it was and did not return the land to itself.</p>
        <p>Tourists with an Israeli visa in their passport are denied entry to Sudan, as well as to many other Muslim countries. The ban applies even to those foreigners who have a Sudanese visa (see interesting facts about Israel).</p>
        <p>In Sudan, marriages with the dead are allowed - the so-called "ghost marriages" are often entered into by pregnant women in order to legitimize the children of their dead loved ones.
Forests cover only 10% of the territory of Sudan (see interesting facts about forests).</p>
<p>Most of the roads in Sudan are unpaved and therefore completely unsuitable for driving during the rainy season.
Cargo transportation in Sudan is poorly developed - either porters or pack animals are involved in the delivery of goods.
The name of the capital of Sudan, the city of Khartoum, is translated from Arabic as "elephant's trunk". The fact is that the city was founded on Cape Ras al-Khartoum (“Tip of an elephant's trunk”).
The largest and most popular museum in the country is the National Museum of Sudan, located in the capital. Part of the exposition, located in the gardens around the museum building, are two religious buildings of the ancient Egyptians: Buhen Temple and Semna Temple. They were moved to Khartoum due to the construction of a dam and the flooding of the area where the temples were originally built.</p>
<p>In the capital, near the confluence of the White and Blue Nile, there is an unusual amusement park, which, moreover, offers a beautiful view of the river (see interesting facts about the Nile).</p>
<p>Sudan is the world's largest importer of gum arabic, which is used to make medicines and paints.</p>
<p>Contrary to popular belief, most of the pyramids are located in Sudan, not Egypt. According to archaeologists, in the expanses of the former Nubia there are more than 220 pyramids with a height of 20 to 30 meters.</p>
<p>Tourists are not advised to travel outside of Khartoum, as cases of kidnapping of foreigners are not uncommon in the country.
The basis of the national cuisine of Sudan are beans.
In Sudan, it is not customary to leave tips for waiters, maids and other service personnel.</p>
<p>In Sudan, the sale and consumption of alcohol is prohibited, as the country lives according to the laws of Islam.</p>
<p>In Sudan, you even need to wash your face with boiled water, as tap water is teeming with germs. Swimming in local rivers and other fresh water bodies is also strongly discouraged (see interesting facts about the rivers of Africa).</p>
<p>On the territory of Sudan there is a large-scale archaeological complex "The Great Wall" - these are the ruins of ancient monuments erected around the 3rd century BC. Scientists have not yet come to a final conclusion about the purpose of these buildings.</p>
<p>Divers from all over the world come to Sudan for the opportunity to dive in the untouched part of the Red Sea (see interesting facts about the Red Sea).</p>
      </>
    ),
    date: '2023-03-12 11:37:00',
  },
  // {
  //   image: news2,
  //   slug: '4',
  //   title: 'COMPANY NEWS',
  //   shortDescription: (
  //     <>
  //       <p>🤝Implemented the ability to transfer GMT from cabinet to cabinet.</p>
  //       <p>💻Soon the new site will be available – the work on it is almost completed.</p>
  //       <p>👌P2P is 70% ready and our team is actively working on its completion.</p>
  //       <p>...</p>
  //     </>
  //   ),
  //   description: (
  //     <>
  //       <p>🤝Implemented the ability to transfer GMT from cabinet to cabinet.</p>
  //       <p>💻Soon the new site will be available – the work on it is almost completed.</p>
  //       <p>👌P2P is 70% ready and our team is actively working on its completion.</p>
  //       <p>⛏In Sudan, the mining process is in full swing.</p>
  //       <p>🎉 We are completing the rebranding of the company and in two weeks there will be a massive opening.</p>
  //       <p>👥 In India and other Asian countries, work is under way, every day leaders hold negotiations in different cities to develop and grow teams.</p>
  //     </>
  //   ),
  //   date: '2021-10-28 19:15:00',
  // },
  // {
  //   image: news1,
  //   slug: '5',
  //   title: '𝗟𝗜𝗡𝗞𝗦',
  //   shortDescription: (
  //     <>
  //       <p>Dear partners,</p>
  //       <p>You can save all contacts via the links below:</p>
  //       <p>🤳Instagram</p>
  //       <p>https://instagram.com/</p>
  //       <p>...</p>
  //     </>
  //   ),
  //   description: (
  //     <>
  //       <p>Dear partners,</p>
  //       <p>You can save all contacts via the links below:</p>
  //       <p>🤳Instagram</p>
  //       <p>https://instagram.com/</p>
  //       <p>🧑‍💻Facebook</p>
  //       <p>https://www.facebook.com/</p>
  //       <p>🐦Twitter</p>
  //       <p>https://mobile.twitter.com/</p>
  //       <p>✳️WhatsApp</p>
  //       <p>https://chat.whatsapp.com/</p>
  //       <p>✈️Telegram</p>
  //       <p>https://t.me/aurous_finance_official</p>
  //       <p>🔲Medium</p>
  //       <p>https://medium.com/</p>
  //       <p>🤖Reddit</p>
  //       <p>https://www.reddit.com/</p>
  //       <p>💬Bitcointalk</p>
  //       <p>https://bitcointalk.com/</p>
  //       <p>🆕https://github.com/</p>
  //     </>
  //   ),
  //   date: '2021-10-28 10:58:00',
  // },
];

export default newsList;
