import {OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface PopupProps {
  tooltip: JSX.Element;
  children?: JSX.Element;
  iconClass?: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}

export default function Popup({tooltip, children, iconClass, placement = 'top'}: PopupProps) {
  const now = Date.now();

  return (
    <>
      <OverlayTrigger placement={placement} overlay={
        <Tooltip id={'tooltip-' + now}>
          {tooltip}
        </Tooltip>
      }>
        {children || <FontAwesomeIcon icon={faInfoCircle} className={iconClass}/>}
      </OverlayTrigger>
    </>
  );
}
