import React from 'react';
import {format} from 'date-fns';
import {ru, enUS} from 'date-fns/locale';

export interface FormattedDateProps {
  date: string;
}

export default function FormattedDate({date}: FormattedDateProps) {
  const dateLanguages = {
    'ru': ru,
    'en': enUS,
  };

  return (
    <>
      {format(new Date(date), 'dd MMM yyyy, HH:mm', {locale: dateLanguages['ru']})}
    </>
  );
}
