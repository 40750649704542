import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useState} from "react";
import {
  // getReferralInfo,
  getReferralLevel,
  getReferralList,
  ReferralInfo,
  ReferralLevelInfo,
  Referral,
} from "@services/referral-api";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import useResponseHandler from "@hooks/useResponseHandler";
import PageHeader from "@pages/account/components/PageHeader";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import Placeholder from "@components/Placeholder";
import Preloader from "@components/Preloader";
import ReferralListRow from "@pages/account/referrals/components/ReferralListRow";
import ValueOf from "@pages/account/components/ValueOf";
import {faChevronLeft} from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
// import FormattedNumber from "@components/FormattedNumber";

interface ReferralLevelPageRouterParams {
  level?: string;
}

interface ReferralLevelPageRedirectLocation {
  referralInfo?: ReferralInfo;
  referralAccountId?: number;
}

export default function ReferralLevelPage() {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const routerParams = useParams<ReferralLevelPageRouterParams>();
  const history = useHistory();
  const location = useLocation<ReferralLevelPageRedirectLocation>();
  // const [referralInfo, setReferralInfo] = useState<ReferralInfo>();
  const [levelInfo, setLevelInfo] = useState<ReferralLevelInfo>();
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [accountId, setAccountId] = useState<number>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLocationHandled, setLocationHandled] = useState<boolean>(false);
  // const [sort, setSort] = useState<ReferralListSort | ''>('');

  useEffect(() => {
    if (location.state.referralAccountId) {
      setAccountId(location.state.referralAccountId);
    }

    setLocationHandled(true);
    // if (location.state.referralInfo) {
    //   setReferralInfo(location.state.referralInfo);
    // } else {
    //   getReferralInfo()
    //     .then((response) => {
    //       if (response.success && response.data) {
    //         setReferralInfo(response.data);
    //       } else {
    //         handleResponse(response);
    //       }
    //     })
    //     .catch(handleResponse);
    // }
  }, [location.state, handleResponse]);

  const loadReferrals = useCallback((page: number) => {
    setLoading(true);

    getReferralList({line: routerParams.level!, page, referralAccountId: accountId})
      .then((response) => {
        setLoading(false);
        if (response.success && response.data) {
          setReferrals(response.data.content);
          setTotal(response.data.totalElements);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        setLoading(false);
        handleResponse(response);
      });
  }, [accountId, routerParams.level, handleResponse]);

  useEffect(() => {
    if (!isLocationHandled) {
      return;
    }

    loadReferrals(0);
  }, [isLocationHandled, loadReferrals]);

  useEffect(() => {
    if (!isLocationHandled) {
      return;
    }

    getReferralLevel(routerParams.level!, accountId)
      .then((response) => {
        if (response.success && response.data) {
          setLevelInfo(response.data);
        } else {
        handleResponse(response);
      }
    })
    .catch(handleResponse);
  }, [isLocationHandled, routerParams.level, accountId, handleResponse]);

  const header = <PageHeader title={'pages.referrals-level.title'}/>;

  if (!referrals || !levelInfo) {
    return (
      <>
        {header}
      </>
    );
  }

  return (
    <>
      {header}

      <button
        className="btn btn-lg btn-link p-0 m-0 tx-left tx-bold"
        onClick={() => history.goBack()}
      >
        <FontAwesomeIcon icon={faChevronLeft} className="mr-2"/>
        {t('common.back')}
      </button>

      <h3 className="d-block mt-2 mb-4">
        {t('pages.referrals-level.content.level')} {routerParams.level}
      </h3>

      <div className="row">
        <div className="col-md-6 mb-3">
          <div className="card">
            <div className="card-body">
              <h5>{t('pages.referrals-level.content.conditions')}:</h5>

              <ul>
                <ValueOf
                  value={levelInfo.firstLineUsdTurnover}
                  of={levelInfo.requiredFirstLineUsdTurnover}
                  text={t('pages.referrals.conditions.first-line-turnover') + ': '}
                  format={true}
                  sign={'$'}
                  asLi={true}
                />

                {levelInfo.requiredTotalUsdTurnover > 0 && (
                  <ValueOf
                    value={levelInfo.totalUsdTurnover}
                    of={levelInfo.requiredTotalUsdTurnover}
                    text={t('pages.referrals.conditions.total-turnover') + ': '}
                    format={true}
                    sign={'$'}
                    asLi={true}
                  />
                )}

                {levelInfo.requiredFirstLinePersonalDeposit > 0 && (
                  <ValueOf
                    value={levelInfo.firstLineReferralsAmount}
                    of={levelInfo.requiredFirstLineReferralsAmount}
                    text={t('pages.referrals.conditions.first-line-referrals') + ` ${levelInfo.requiredFirstLinePersonalDeposit}$: `}
                    asLi={true}
                  />
                )}

                <ValueOf
                  value={levelInfo.personalUsdDeposit}
                  of={levelInfo.requiredPersonalUsdDeposit}
                  text={t('pages.referrals.conditions.personal-deposit') + ': '}
                  format={true}
                  sign={'$'}
                  asLi={true}
                />
              </ul>
            </div>
          </div>
        </div>

        {/*<div className="col-md-6 mb-3">*/}
        {/*  <div className="card">*/}
        {/*    <div className="card-body">*/}
        {/*      <h5>{t('pages.referrals.statistics.title')}:</h5>*/}

        {/*      <div className="mb-1">*/}
        {/*        <span className="mr-2">{t('pages.referrals-level.content.referrals')}:</span>*/}
        {/*        <span className="tx-bold tx-primary">*/}
        {/*          {referralInfo.totalReferrals}*/}
        {/*        </span>*/}
        {/*      </div>*/}
        {/*      <div className="mb-1">*/}
        {/*        <span className="mr-2">{t('pages.referrals-level.content.total-turnover')}:</span>*/}
        {/*        <span className="tx-bold tx-primary">*/}
        {/*          <FormattedNumber*/}
        {/*            value={referralInfo.totalTurnover}*/}
        {/*            decimals={2}*/}
        {/*            postfix={'$'}*/}
        {/*          />*/}
        {/*        </span>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      {!isLoading && !referrals.length && (
        <>{t('pages.referrals-level.content.no-referrals')}</>
      )}

      {(isLoading || referrals.length > 0) && (
        <>
          <div className="table-wrapper">
            {isLoading && referrals.length > 0 && <Preloader/>}
            <table className="table table-striped mb-0 tx-center">
              <thead>
              <tr>
                <th className="bd-t-0-f">{t('pages.referrals-level.table.date')}</th>
                <th className="bd-t-0-f">{t('pages.referrals-level.table.level')}</th>
                <th className="bd-t-0-f">{t('pages.referrals-level.table.name')}</th>
                <th className="bd-t-0-f">{t('pages.referrals-level.table.invited-by')}</th>
                <th className="bd-t-0-f">{t('pages.referrals-level.table.deposits')}</th>
                <th className="bd-t-0-f">{t('pages.referrals-level.table.referrals-deposit')}</th>
                <th className="bd-t-0-f">{t('pages.referrals-level.table.total-turnover')}</th>
                <th className="bd-t-0-f">{t('common.ref-id')}</th>
              </tr>
              </thead>
              <tbody>
              {!referrals.length && (<>
                <tr>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                </tr>
                <tr>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                  <td><Placeholder width={100}/></td>
                </tr>
              </>)}
              {referrals.length > 0 && referrals.map((entry, index) => (
                <ReferralListRow
                  entry={entry}
                  key={'dashboard-transaction-' + index + '-' + entry.registrationDate}
                />
              ))}
              </tbody>
            </table>
          </div>

          {total > 10 && (
            <ReactPaginate
              previousLabel={<FontAwesomeIcon icon={faArrowLeft}/>}
              nextLabel={<FontAwesomeIcon icon={faArrowRight}/>}
              pageCount={total / 10}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              containerClassName={'pagination mt-4 justify-content-center'}
              activeClassName={'active'}
              onPageChange={(data) => loadReferrals(data.selected)}
              eventListener={isLoading ? '' : 'onClick'}
            />
          )}
        </>
      )}
    </>
  );
}
