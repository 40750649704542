import React from "react";
import {SocialRoutes} from "@pages/pages";
import {faTelegramPlane} from "@fortawesome/free-brands-svg-icons/faTelegramPlane";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons/faFacebook";
import {faTwitter} from "@fortawesome/free-brands-svg-icons/faTwitter";
// import {faLinkedin} from "@fortawesome/free-brands-svg-icons/faLinkedin";
// import {faReddit} from "@fortawesome/free-brands-svg-icons/faReddit";
import {faTiktok} from "@fortawesome/free-brands-svg-icons/faTiktok";
// import {faMedium} from "@fortawesome/free-brands-svg-icons/faMedium";
import {faVk} from "@fortawesome/free-brands-svg-icons/faVk";

export default function SocialLinks() {
  const socials = [
    {
      icon: faInstagram,
      id: 'instagram',
      link: SocialRoutes.INSTAGRAM,
      title: 'Instagram',
    },
    {
      icon: faFacebook,
      id: 'facebook',
      link: SocialRoutes.FACEBOOK,
      title: 'Facebook',
    },
    {
      icon: faTwitter,
      id: 'twitter',
      link: SocialRoutes.TWITTER,
      title: 'Twitter',
    },
    // {
    //   icon: faLinkedin,
    //   id: 'linkedin',
    //   link: SocialRoutes.LINKED_IN,
    //   title: 'LinkedIn',
    // },
    // {
    //   icon: faReddit,
    //   id: 'reddit',
    //   link: SocialRoutes.REDDIT,
    //   title: 'Reddit',
    // },
    {
      icon: faTelegramPlane,
      id: 'telegram',
      link: SocialRoutes.TELEGRAM,
      title: 'Telegram',
    },
    {
      icon: faTiktok,
      id: 'tiktok',
      link: SocialRoutes.TIKTOK,
      title: 'TikTok',
    },
    // {
    //   icon: faMedium,
    //   id: 'medium',
    //   link: SocialRoutes.MEDIUM,
    //   title: 'Medium',
    // },
    {
      icon: faVk,
      id: 'vk',
      link: SocialRoutes.VK,
      title: 'VK',
    },
  ];

  return (
    <div className="d-flex flex-wrap mt-3">
      {socials.map((social) => (
        <a
          className={'footer-link tx-20 mr-2 tx-' + social.id}
          href={social.link}
          target="_blank"
          rel="noreferrer"
          title={social.title}
          key={social.title}
        >
          <FontAwesomeIcon icon={social.icon}/>
        </a>
      ))}
    </div>
  )
}
