import React, {useCallback, useEffect, useState} from 'react';
import {
  saveAccountInfo,
} from "@services/settings-api";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faEnvelope,
  faSave,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet-async";
import useResponseHandler from "@hooks/useResponseHandler";
import Tfa from "./components/Tfa";
import Preloader from "@components/Preloader";
import {useAccountInfoRefresher} from "@hooks/recoil-hooks";
import {accountInfo} from "@stores/account";
import PasswordChange from "@pages/account/settings/components/PasswordChange";
import useLoadableValue from "@hooks/useLoadableValue";
import {useTranslation} from "react-i18next";
import PhoneInput from "react-phone-input-2";

export default function SettingsPage() {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const account = useLoadableValue(accountInfo);
  const refreshAccountInfo = useAccountInfoRefresher();
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(true);

  const emailRx = RegExp(/(.+)@(.+){2,}\.(.+){2,}/);

  const isEmailValid = (value: string) => {
    return value.length > 5 && emailRx.test(value);
  };

  const isPhoneValid = (value: string) => {
    return value.length > 6;
  };

  useEffect(() => {
    if (account) {
      setLoading(false);

      setEmail(account.email || '');
      setFirstName(account.firstName || '');
      setLastName(account.lastName || '');
      setPhone(account.phone || '');
    }
  }, [account, handleResponse]);

  const submitAccountUpdate = useCallback(() => {
    if (!isEmailValid(email)) {
      handleResponse('error.email-not-valid');
      return;
    }

    if (phone.length && !isPhoneValid(phone)) {
      handleResponse('error.phone-not-valid');
      return;
    }

    setLoading(true);
    saveAccountInfo({
      email,
      phone,
      firstName,
      lastName,
    })
      .then((response) => {
        if (response.success) {
          handleResponse('alert.personal-info-saved');
          refreshAccountInfo();
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [
    email,
    phone,
    firstName,
    lastName,
    handleResponse,
    refreshAccountInfo,
  ]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.settings.title')}</title>
      </Helmet>
      <h1 className="mb-4">{t('pages.settings.title')}</h1>

      <div className="d-block mb-4">
        <Tfa/>
      </div>

      <div className="card with-button wd-100p wd-md-450 mr-md-5 d-inline-block mb-5">
        <div className="card-body">
          <h2 className="mb-4">{t('pages.settings.content.personal-information')}</h2>

          <label className="tx-bold mb-1" htmlFor="phone">
            {t('input.phone')}
          </label>
          <PhoneInput
            country="ru"
            value={phone}
            onChange={value => setPhone(value)}
            inputClass="form-control"
            containerClass="form-group"
            disabled={isLoading}

            inputProps={{name: 'phone', id: 'phone', autoComplete: 'tel'}}
          />

          <label className="tx-bold mb-1" htmlFor="email">
            {t('input.email')}
          </label>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <div className="input-group-text"><FontAwesomeIcon icon={faEnvelope}/></div>
            </div>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              disabled={true}
              readOnly={true}
            />
          </div>

          <label className="tx-bold mb-1" htmlFor="firstName">
            {t('input.first-name')}
          </label>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <div className="input-group-text"><FontAwesomeIcon icon={faUser}/></div>
            </div>
            <input
              type="text"
              className="form-control"
              id="firstName"
              value={firstName}
              disabled={isLoading}
              onChange={event => setFirstName(event.target.value)}
            />
          </div>

          <label className="tx-bold mb-1" htmlFor="lastName">
            {t('input.last-name')}
          </label>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <div className="input-group-text"><FontAwesomeIcon icon={faUser}/></div>
            </div>
            <input
              type="text"
              className="form-control"
              id="lastName"
              value={lastName}
              disabled={isLoading}
              onChange={event => setLastName(event.target.value)}
            />
          </div>

          {!account && <Preloader/>}
        </div>
        <div className="card-button">
          <button
            className="btn btn-gold"
            type="button"
            onClick={submitAccountUpdate}
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={isLoading ? faCircleNotch : faSave} spin={isLoading} className="mr-2"/>
            {t('button.save')}
          </button>
        </div>
      </div>

      <PasswordChange/>
    </>
  );
}
