import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ContractTemplate, getContractTemplates} from "@services/contracts-api";
import useResponseHandler from "@hooks/useResponseHandler";
import Slider from "react-slick";
import Preloader from "@components/Preloader";
import ContractTemplateItem from "@pages/account/contracts/components/ContractTemplateItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft} from "@fortawesome/pro-light-svg-icons/faArrowCircleLeft";
import {faArrowCircleRight} from "@fortawesome/pro-light-svg-icons/faArrowCircleRight";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import {getWalletList, Wallet} from "@services/wallet-api";
import {Ticker} from "@services/api";
import FormattedNumber from "@components/FormattedNumber";
import Translate from "@components/Translate";

export default function BuyContractPage() {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [templates, setTemplates] = useState<ContractTemplate[]>([]);
  const contractsSlider = useRef<Slider>(null);

  useEffect(() => {
    getWalletList()
      .then((response) => {
        if (response.success && response.data) {
          setWallets(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response);
      });
  }, [handleResponse]);

  const usdtWallet = useMemo(() => {
    return wallets.find((w) => w.currency === Ticker.USDT);
  }, [wallets]);

  const asgWallet = useMemo(() => {
    return wallets.find((w) => w.currency === Ticker.ASG);
  }, [wallets]);

  const prev = useCallback(() => {
    contractsSlider.current!.slickPrev();
  }, [contractsSlider]);

  const next = useCallback(() => {
    contractsSlider.current!.slickNext();
  }, [contractsSlider]);

  useEffect(() => {
    getContractTemplates()
      .then((response) => {
        if (response.success && response.data) {
          setTemplates(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch(handleResponse);
  }, [handleResponse]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('button.buy-contract')}</title>
      </Helmet>

      <div className="d-flex justify-content-between">
        <h1 className="mb-4">{t('button.buy-contract')}</h1>

        <div className="d-lg-none mb-2">
          <button className="slick-prev mr-3" onClick={prev}>
            <FontAwesomeIcon icon={faArrowCircleLeft} className="tx-50"/>
          </button>
          <button className="slick-next" onClick={next}>
            <FontAwesomeIcon icon={faArrowCircleRight} className="tx-50"/>
          </button>
        </div>
      </div>

      <div className="card wd-100p wd-md-300 d-block mb-3">
        <div className="card-body">
          <div>
            {t('pages.withdrawal.content.balance')}
            {': '}
            <div className="tx-bold tx-gold tx-right">
              <FormattedNumber
                value={usdtWallet?.balance}
                decimals={2}
                floor={true}
                postfix={usdtWallet?.currency}
              />
            </div>

            <div className="tx-bold tx-gold tx-right">
              <FormattedNumber
                value={asgWallet?.balance}
                decimals={2}
                floor={true}
                postfix={asgWallet?.currency}
              />
            </div>
          </div>
        </div>
      </div>

      {!templates.length && <Preloader inline={true}/>}
      {!!templates.length && (
        <div className="wd-xl-950">
          <Slider
            ref={contractsSlider}
            dots={false}
            arrows={false}
            infinite={false}
            speed={300}
            slidesToShow={3}
            slidesToScroll={1}
            responsive={[
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                }
              },
              {
                breakpoint: 1050,
                settings: {
                  slidesToShow: 2,
                }
              },
            ]}
          >
            {templates.map((c) => (
              <ContractTemplateItem template={c} key={c.id} wallet={asgWallet}/>
            ))}
          </Slider>
        </div>
      )}

      <div className="mt-4">
        <Translate i18nKey={'pages.contracts.info'}/>
      </div>
    </>
  );
}
