import React, {useCallback, useMemo, useState} from 'react';
import bg from '@assets/images/bg.jpg';
import {Link, Redirect} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {
  RefInfo,
  register,
  resendCode,
} from "@services/api";
import {AccountLinks, LandingLinks} from "@pages/pages";
import {Helmet} from "react-helmet-async";
import useResponseHandler from "@hooks/useResponseHandler";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import useInterval from "@hooks/useInterval";
import {useRecoilValue} from "recoil";
import {loginState, refState} from "@stores/account";
import {useTranslation} from "react-i18next";
import {faUser} from "@fortawesome/pro-regular-svg-icons/faUser";
import Translate from "@components/Translate";

export interface RegisterFormProps {
  refInfo: RefInfo;
}

export default function RegisterForm({refInfo}: RegisterFormProps) {
  const {t} = useTranslation();
  const ref = useRecoilValue(refState);
  const isLoggedIn = useRecoilValue(loginState);
  const handleResponse = useResponseHandler();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSucceed, setSucceed] = useState<boolean>(false);
  const [refAgree, setRefAgree] = useState<boolean>(false);
  const [agree, setAgree] = useState<boolean>(false);
  const [isProcessingCode, setProcessingCode] = useState<boolean>(false);

  const emailRx = useMemo(() => {
    return RegExp(/(.+)@(.+){2,}\.(.+){2,}/)
  }, []);

  const isEmailValid = useCallback(() => {
    if (email.length < 6 || !(emailRx.test(email))) {
      handleResponse('error.email-not-valid');
      return false;
    }

    return true;
  }, [email, emailRx, handleResponse]);

  useInterval(() => {
    setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
  }, timeLeft > 0 ? 1000 : null);

  const submitForm = useCallback(() => {
    if (!isEmailValid()) {
      return;
    }

    if (!password || !firstName || !lastName) {
      handleResponse('error.empty-fields');
      return;
    }

    register({email, password, firstName, lastName, invitedBy: ref!})
      .then((response) => {
        if (response.success) {
          setSucceed(true);
          setTimeLeft(59);
        } else {
          handleResponse(response, true);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response, true);
        setLoading(false);
      });
  }, [isEmailValid, ref, email, password, firstName, lastName, handleResponse]);

  const sendCodeHandler = useCallback(() => {
    setProcessingCode(true);

    resendCode({email})
      .then(response => {
        if (response.success) {
          setTimeLeft(59);
          handleResponse('message.code-sent');
        } else {
          handleResponse(response);
        }
        setProcessingCode(false);
      })
      .catch((response) => {
        handleResponse(response);
        setProcessingCode(false);
      });
  }, [email, handleResponse]);

  if (isLoggedIn) {
    return (
      <Redirect to={AccountLinks.WALLET}/>
    )
  }

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.register.title')}</title>
      </Helmet>
      <form id="form-register" className="flex-1 bg-image-center bg-image-cover bg-overlay p-4 p-md-5 pt-navbar"
            style={{backgroundImage: `url(${bg})`}}
            onSubmit={(e) => {e.preventDefault(); submitForm();}}>
        <div className="container">
          <div id="login-form" className="wd-100p wd-md-500">
            <h2>{t('pages.register.content.heading')}</h2>

            {isSucceed && (
              <>
                <span className="tx-semibold tx-24">
                  {t('pages.register.content.code-sent')}
                </span>

                <div className="mt-2">
                  {timeLeft > 0 && (
                    <div className="form-group">
                      {t('pages.login.content.no-code')}{timeLeft < 10 ? '0' + timeLeft : timeLeft}
                    </div>
                  )}
                  {timeLeft === 0 && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={sendCodeHandler}
                    >
                      {isProcessingCode && (<FontAwesomeIcon icon={faCircleNotch} className="mr-2" spin/>)}
                      {t('button.get-code')}
                    </button>
                  )}
                </div>
              </>
            )}

            {!isSucceed && (
              <>
                <div className="input-group input-group-lg mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text"><FontAwesomeIcon icon={faEnvelope}/></div>
                  </div>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    placeholder={t('input.email')}
                    value={email}
                    id="email"
                    onChange={event => {
                      const value = event.target.value;

                      if (value.length - email.length < 3) {
                        setEmail(value)
                      } else {
                        if (emailRx.test(value)) {
                          setEmail(value);
                        } else {
                          setEmail(email);
                        }
                      }
                    }}
                    disabled={isLoading}
                  />
                </div>

                <div className="input-group input-group-lg mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text"><FontAwesomeIcon icon={faKey}/></div>
                  </div>
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    placeholder={t('input.password')}
                    value={password}
                    disabled={isLoading}
                    onChange={event => setPassword(event.target.value)}
                  />
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text"><FontAwesomeIcon icon={faUser}/></div>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="firstName"
                    placeholder={t('input.first-name')}
                    value={firstName}
                    disabled={isLoading}
                    onChange={event => setFirstName(event.target.value)}
                  />
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <div className="input-group-text"><FontAwesomeIcon icon={faUser}/></div>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="lastName"
                    placeholder={t('input.last-name')}
                    value={lastName}
                    disabled={isLoading}
                    onChange={event => setLastName(event.target.value)}
                  />
                </div>

                <div className="mt-3 mb-3">
                  <label className="ckbox" id="agree">
                    <input type="checkbox" onChange={(event) => setRefAgree(event.target.checked)}/>
                    <span className="ml-2">
                      {
                        t('input.invite-agree')
                          .replace('%firstName', refInfo.firstName)
                          .replace('%lastName', refInfo.lastName)
                      }
                    </span>
                  </label>
                </div>

                <div className="mt-3 mb-3">
                  <label className="ckbox" id="agree">
                    <input type="checkbox" onChange={(event) => setAgree(event.target.checked)}/>
                    <span className="ml-2">
                      <Translate i18nKey="alert.terms-of-use"/>
                    </span>
                  </label>
                </div>

                <button
                  className="btn btn-lg btn-primary wd-100p mt-3 mb-3"
                  type="submit"
                  disabled={isLoading || !agree || !refAgree}
                >
                  {isLoading && (<FontAwesomeIcon icon={faCircleNotch} className="mr-2" spin/>)}
                  {t('button.register')}
                </button>

                <div className="mb-2">
                  {t('pages.register.content.have-account')} <Link to={LandingLinks.LOGIN}>{t('button.login')}</Link>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
}
