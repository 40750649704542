import React, {useCallback, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";
import {faEyeSlash} from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {useTranslation} from "react-i18next";
import {IconDefinition} from "@fortawesome/pro-regular-svg-icons";

export interface PasswordInputProps {
  initValue?: string;
  onChange: (value: string) => void;
  placeholder?: string | false;
  className?: string;
  groupClassName?: string;
  disabled?: boolean;
  isLg?: boolean;
  icon?: IconDefinition | false;
  id?: string;
}

const PasswordInput = (props: PasswordInputProps) => {
  const {t} = useTranslation();
  const {initValue, onChange, placeholder, className, groupClassName, disabled, isLg, icon, id} = props;
  const [value, setValue] = useState<string>(initValue || '');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const classList = useMemo(() => {
    return [
      'form-control',
      isLg ? 'form-control-lg' : '',
      className ? className : '',
    ];
  }, [isLg, className]);

  const containerClassList = useMemo(() => {
    return [
      'input-group',
      isLg ? 'input-group-lg' : '',
      groupClassName ? groupClassName : '',
    ];
  }, [isLg, groupClassName]);

  const onChangeHandler = useCallback((newValue: string) => {
    setValue(newValue);
    onChange(newValue);
  }, [onChange]);

  const handlePlaceholder = useMemo(() => {
    if (!placeholder && placeholder !== false) {
      return t('input.password');
    } else if (!placeholder) {
      return '';
    }

    return placeholder;
  }, [placeholder, t]);

  const togglePassword = () => setShowPassword((prevState => !prevState));

  return (
    <div className={containerClassList.join(' ')}>
      {icon !== false && (
        <div className="input-group-prepend">
          <div className="input-group-text">
            <FontAwesomeIcon icon={icon ? icon : faKey}/>
          </div>
        </div>
      )}

      <input
        id={id}
        type={showPassword ? 'text' : 'password'}
        className={classList.join(' ')}
        placeholder={handlePlaceholder}
        value={value}
        disabled={disabled}
        onChange={event => onChangeHandler(event.target.value)}
      />

      <div className="input-group-append">
        <button type="button" className="btn btn-link" onClick={togglePassword}>
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
        </button>
      </div>
    </div>
  );
};

export default React.memo(PasswordInput);
