import React, {useCallback, useState} from 'react';
import {
  changePassword,
  ChangePasswordData,
} from "@services/settings-api";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faKey,
  faLock,
  faSave,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import useResponseHandler from "@hooks/useResponseHandler";
import {useRecoilValueLoadable} from "recoil";
import {accountInfo} from "@stores/account";
import {useTranslation} from "react-i18next";
import PasswordInput from "@components/common/inputs/PasswordInput";

export default function PasswordChange() {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const {contents: account} = useRecoilValueLoadable(accountInfo);
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPassword2, setNewPassword2] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const submitPasswordChange = useCallback(() => {
    if (newPassword !== newPassword2) {
      handleResponse('error.new-password-doesnt-match', true);
      return;
    }

    if (password === newPassword) {
      handleResponse('error.same-password', true);
      return;
    }

    if (newPassword.length < 8) {
      handleResponse('error.password-length-less-then-8', true);
      return;
    }

    const changePasswordData: ChangePasswordData = {
      oldPassword: password,
      newPassword: newPassword,
    };

    if (code) {
      changePasswordData.code = code;
    }

    setLoading(true);
    changePassword(changePasswordData)
      .then((response) => {
        if (response.success) {
          setPassword('');
          setNewPassword('');
          setNewPassword2('');
          handleResponse('alert.password-changed');
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [code, password, newPassword, newPassword2, handleResponse]);

  return (
    <div className="card with-button wd-100p wd-md-450 d-inline-block valign-top">
      <div className="card-body">
        <h2 className="mb-4">{t('pages.settings.content.change-password')}</h2>

        <label className="tx-bold mb-1" htmlFor="password">
          {t('input.current-password')}
        </label>
        <PasswordInput
          id="password"
          groupClassName="mb-3"
          icon={faLock}
          disabled={isLoading}
          onChange={setPassword}
          placeholder={false}
        />

        <label className="tx-bold mb-1" htmlFor="newPassword">
          {t('input.new-password')}
        </label>
        <PasswordInput
          id="newPassword"
          groupClassName="mb-3"
          icon={faKey}
          disabled={isLoading}
          onChange={setNewPassword}
          placeholder={false}
        />

        <label className="tx-bold mb-1" htmlFor="newPassword2">
          {t('input.new-password2')}
        </label>
        <PasswordInput
          id="newPassword2"
          groupClassName="mb-3"
          icon={faSync}
          disabled={isLoading}
          onChange={setNewPassword2}
          placeholder={false}
        />

        {account?.tfaEnabled && (
          <>
            <label className="tx-bold mb-1" htmlFor="tfa-code">
              {t('input.auth-code')}
            </label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <div className="input-group-text"><FontAwesomeIcon icon={faKey}/></div>
              </div>
              <input
                type="number"
                className="form-control"
                id="tfa-code"
                value={code}
                disabled={isLoading}
                onChange={event => setCode(event.target.value)}
              />
            </div>
          </>
        )}
      </div>

      <div className="card-button">
        <button
          className="btn btn-gold"
          type="button"
          onClick={submitPasswordChange}
          disabled={!password || !newPassword || !newPassword2 || isLoading}
        >
          <FontAwesomeIcon icon={isLoading ? faCircleNotch : faSave} spin={isLoading} className="mr-2"/>
          {t('button.save')}
        </button>
      </div>
    </div>
  );
}
