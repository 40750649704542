import React from "react";
import {OrderStatus} from "@services/p2p-api";
import {OrdersTableRowProps} from "@pages/account/p2p/orders/OrdersTableRow";
import CancelOrderButton from "@pages/account/p2p/orders/buttons/CancelOrderButton";
import ExecuteOrderButton from "@pages/account/p2p/orders/buttons/ExecuteOrderButton";

export interface OrderActionProps {
  props: OrdersTableRowProps;
}

export default function OrderAction({props}: OrderActionProps) {
  const {account, entry} = props;

  let button;

  if (entry.status === OrderStatus.CANCELED || entry.status === OrderStatus.EXECUTED) {
    return null;
  }

  if (account.nickname === entry.creatorNickname) {
    button = <CancelOrderButton props={props}/>;
  } else {
    button = <ExecuteOrderButton props={props}/>;
  }

  return (
    <span className="transaction-button">
      {button}
    </span>
  )
}
