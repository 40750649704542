import FormattedDate from "@util/FormattedDate";
import {Referral} from "@services/referral-api";
import FormattedNumber from "@components/FormattedNumber";
import CopyButton from "@components/common/CopyButton";
import {useHistory} from "react-router-dom";
import useLoadableValue from "@hooks/useLoadableValue";
import {accountInfo} from "@stores/account";

export interface ReferralListRowProps {
  entry: Referral;
}

export default function ReferralListRow({entry}: ReferralListRowProps) {
  const history = useHistory();
  const account = useLoadableValue(accountInfo);

  return (
    <tr>
      <td><FormattedDate date={entry.registrationDate}/></td>
      <td>{entry.line}</td>
      <td>
        <button
          className="btn btn-link p-0 m-0 tx-center tx-semibold"
          onClick={() => {
            history.push({
              pathname: '/referrals',
              state: {referralAccountId: entry.accountId, name: entry.fullName},
            });
          }}
        >
        {entry.fullName}
        </button>
      </td>
      <td>
        {account && account.id !== entry.accountId ? (
          <button
            className="btn btn-link p-0 m-0 tx-center tx-semibold"
            onClick={() => {
              history.push({
                pathname: '/referrals',
                state: {referralAccountId: entry.invitedByAccountId, name: entry.invitedByFullName},
              });
            }}
          >
            {entry.invitedByFullName}
          </button>
        ) : (
          <>
            {entry.invitedByFullName}
          </>
        )}
      </td>
      <td>
        <FormattedNumber
          value={entry.personalDeposit}
          decimals={2}
          postfix={'$'}
        />
      </td>
      <td>
        <FormattedNumber
          value={entry.referralsDeposit}
          decimals={2}
          postfix={'$'}
        />
      </td>
      <td>
        <FormattedNumber
          value={entry.totalTurnover}
          decimals={2}
          postfix={'$'}
        />
      </td>
      <td><CopyButton text={entry.refId}/></td>
    </tr>
  );
}
