import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import useResponseHandler from "@hooks/useResponseHandler";
import {getOrderList, Order, OrderListFilters, OrderStatus, P2PAccount} from "@services/p2p-api";
import ReactPaginate from "react-paginate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {faTable} from "@fortawesome/free-solid-svg-icons/faTable";
import {faThList} from "@fortawesome/free-solid-svg-icons/faThList";
import Preloader from "@components/Preloader";
import Placeholder from "@components/Placeholder";
import Translate from "@components/Translate";
import {Element} from "react-scroll/modules";
import OrdersTableRow from "@pages/account/p2p/orders/OrdersTableRow";
import {faSync} from "@fortawesome/pro-regular-svg-icons/faSync";
import OrderFilters from "@pages/account/p2p/orders/OrderFilters";
import {OrEmpty, SortDirection, Ticker} from "@services/api";
import {useRecoilState, useRecoilValue} from "recoil";
import {
  orderAmountFilter,
  orderAmountSort,
  orderCurrencyFromFilter,
  orderDateSort,
  orderPriceFilter,
  orderPriceSort,
  orderStatusFilter
} from "@stores/orders";
import {faSortAlt} from "@fortawesome/pro-solid-svg-icons/faSortAlt";
import {faSortAmountDown} from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import {faSortAmountUp} from "@fortawesome/free-solid-svg-icons/faSortAmountUp";
import {convertStringToNumber} from "@helpers/bignumber";

export interface OrdersTableProps {
  statuses?: OrderStatus[];
  id?: string;
  className?: string;
  account: P2PAccount;
  updateWallets: () => void;
}

export interface OrdersTableRef {
  refresh: () => void;
}

export enum OrdersTableColumnMode {
  ONLY_ALL = 'ONLY_ALL',
  ONLY_MY = 'ONLY_MY',
}

export const orderHeaders = [
  {
    id: 'common.date',
  },
  {
    id: 'common.id',
  },
  {
    id: 'common.executor',
    displayMode: OrdersTableColumnMode.ONLY_MY,
  },
  {
    id: 'common.sender',
  },
  {
    id: 'common.type',
  },
  {
    id: 'common.coin-amount',
  },
  {
    id: 'pages.p2p.content.price-per-1-asg',
  },
  {
    id: 'common.total',
  },
  {
    id: 'common.status',
    displayMode: OrdersTableColumnMode.ONLY_MY,
  },
  {
    id: 'common.actions',
    displayMode: OrdersTableColumnMode.ONLY_ALL,
  },
];

const OrdersTable = forwardRef<OrdersTableRef, OrdersTableProps>(
  ({statuses, id = 'orders-table', className, account, updateWallets}, ref) => {
    const {t} = useTranslation();
    const handleResponse = useResponseHandler();
    const [orders, setOrders] = useState<Order[]>([]);
    const [elements, setElements] = useState<number>(0);

    const currencyFrom = useRecoilValue(orderCurrencyFromFilter);
    const status = useRecoilValue(orderStatusFilter);
    const [dateSort, setDateSort] = useRecoilState(orderDateSort);
    const [amountSort, setAmountSort] = useRecoilState(orderAmountSort);
    const [priceSort, setPriceSort] = useRecoilState(orderPriceSort);
    const amountFilter = useRecoilValue(orderAmountFilter);
    const priceFilter = useRecoilValue(orderPriceFilter);

    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [ordersLoaded, setOrdersLoaded] = useState<boolean>(false);
    const [listView, setListView] = useState<boolean>(false);
    const [showMyOrders, setShowMyOrders] = useState<boolean>(false);

    const fromTo = (arr: string[]) => {
      let valFrom = convertStringToNumber(arr[0]);
      let valTo = convertStringToNumber(arr[1]);

      return [isNaN(valFrom) ? null : valFrom, isNaN(valTo) ? null : valTo];
    };

    const loadOrders = useCallback((nextPage: number) => {
      setLoading(true);

      const [priceFrom, priceTo] = fromTo(priceFilter);
      const [amountFrom, amountTo] = fromTo(amountFilter);

      const filter: OrderListFilters = {statuses: [OrderStatus.OPEN]};

      if (currencyFrom) {
        filter.currencyFrom = currencyFrom;
      }

      const sort = [];

      if (dateSort) {
        sort.push('sort=creationTimestamp,' + dateSort);
      }

      if (showMyOrders) {
        filter.nickname = account.nickname;
        filter.statuses = status ? [status] : [OrderStatus.OPEN, OrderStatus.CANCELED, OrderStatus.EXECUTED];
      } else {
        if (amountSort) {
          sort.push('sort=' + (currencyFrom === Ticker.USDT ? 'currencyToAmount' : 'currencyFromAmount') + ',' + amountSort);
        }
        if (priceSort) {
          sort.push('sort=usdPrice,' + priceSort);
        }

        if (priceFrom) {
          filter.minPrice = priceFrom;
        }
        if (priceTo) {
          filter.maxPrice = priceTo;
        }
        if (amountFrom) {
          if (currencyFrom === Ticker.USDT) {
            filter.minCurrencyToAmount = amountFrom;
          } else {
            filter.minCurrencyFromAmount = amountFrom;
          }
        }
        if (amountTo) {
          if (currencyFrom === Ticker.USDT) {
            filter.maxCurrencyToAmount = amountTo;
          } else {
            filter.maxCurrencyFromAmount = amountTo;
          }
        }
      }

      getOrderList(nextPage, filter, sort)
        .then((response) => {
          if (response.success && response.data) {
            setOrders(response.data.content);
            setTotal(response.data.totalElements);
            setElements(response.data.numberOfElements);
          } else {
            handleResponse(response);
          }
          setLoading(false);
          setOrdersLoaded(true);
        })
        .catch((response) => {
          handleResponse(response);
          setLoading(false);
          setOrdersLoaded(true);
        });
    }, [
      status,
      currencyFrom,
      dateSort,
      amountSort,
      priceSort,
      amountFilter,
      priceFilter,
      account.nickname,
      showMyOrders,
      handleResponse,
    ]);

    const refreshOrders = () => loadOrders(page);

    useImperativeHandle(
      ref,
      () => ({
        refresh() {
          refreshOrders();
        }
      }),
    );

    useEffect(() => {
      if (!isLoading && !ordersLoaded && !orders.length) {
        loadOrders(0);
      }
    }, [loadOrders, isLoading, ordersLoaded, orders.length]);

    useEffect(() => {
      refreshOrders();
    }, [showMyOrders]);

    const handlePageChange = useCallback((newPage: number) => {
      setPage(newPage);
      loadOrders(newPage);
    }, [loadOrders]);

    useEffect(() => {
      handlePageChange(0);
    }, [currencyFrom, status, dateSort, amountSort, priceSort, priceFilter, amountFilter, handlePageChange]);

    useEffect(() => {
      if (elements === 0) {
        setPage((prevState) => prevState > 0 ? prevState - 1 : 0);
        refreshOrders();
      }
    }, [elements]);

    const filteredHeaders = orderHeaders
      .filter(h => !h.displayMode || h.displayMode === (showMyOrders ? OrdersTableColumnMode.ONLY_MY : OrdersTableColumnMode.ONLY_ALL));

    const renderSort = (id: string) => {
      let render = false;
      let sort: OrEmpty<SortDirection> = '';
      let setter: (a: OrEmpty<SortDirection>) => any;

      if (id === 'common.date') {
        render = true;
        sort = dateSort;
        setter = setDateSort;
      } else if (!showMyOrders) {
        if (id === 'common.coin-amount') {
          render = true;
          sort = amountSort;
          setter = setAmountSort;
        } else if (id === 'pages.p2p.content.price-per-1-asg') {
          render = true;
          sort = priceSort;
          setter = setPriceSort;
        }
      }

      if (render) {
        return (
          <button
            className="btn btn-link tx-white tx-bold p-0 m-0"
            disabled={isLoading}
            onClick={() => {
              if (!sort) {
                setter(SortDirection.DESC);
              } else if (sort === SortDirection.DESC) {
                setter(SortDirection.ASC);
              } else {
                setter('');
              }
            }}
          >
            <span className="mr-2">{t(id)}</span>
            {!sort && <FontAwesomeIcon icon={faSortAlt}/>}
            {sort !== '' && <FontAwesomeIcon icon={sort === SortDirection.DESC ? faSortAmountDown : faSortAmountUp}/>}
          </button>
        )
      } else {
        return t(id);
      }
    };

    return (
      <div className={className}>
        <div>
          <button
            className={'btn tx-bold tx-14-f mr-3 mb-2 btn-' + (!showMyOrders ? 'primary' : 'gray')}
            disabled={isLoading}
            onClick={() => {
              setShowMyOrders(false);
            }}
          >
            {t('pages.p2p.content.all-orders')}
          </button>

          <button
            className={'btn tx-bold tx-14-f mr-3 mb-2 btn-' + (showMyOrders ? 'primary' : 'gray')}
            disabled={isLoading}
            onClick={() => {
              setShowMyOrders(true);
            }}
          >
            {t('pages.p2p.content.my-orders')}
          </button>
        </div>

        <div className="d-md-flex flex-md-row align-items-start justify-content-end">
          <h2 className="mb-2 mb-md-0 mr-auto">
            <button
              className="btn btn-link p-0 tx-30-f mr-3 d-none d-lg-inline"
              onClick={() => setListView(!listView)}
            >
              <FontAwesomeIcon icon={faTable} className={'mr-2 ' + (listView ? 'tx-gray-600' : 'tx-primary')}/>
              <FontAwesomeIcon icon={faThList} className={!listView ? 'tx-gray-600' : 'tx-primary'}/>
            </button>

            {t('common.orders')}
          </h2>

          <button
            className="btn btn-sm btn-warning ml-auto mr-0 mb-2"
            disabled={isLoading}
            onClick={refreshOrders}
          >
            <FontAwesomeIcon icon={faSync} className="mr-2" spin={isLoading}/>
            {t('button.refresh')}
          </button>
        </div>

        <OrderFilters
          isLoading={isLoading}
          isMyOrders={showMyOrders}
          isListView={listView}
        />

        <Element name={id} id={id}>
          <div className={'table-wrapper-responsive' + (listView ? ' list-view' : '')}>
            {isLoading && orders.length > 0 && <Preloader/>}
            <table className="table table-striped mb-0">
              <thead>
              <tr>
                {filteredHeaders.map((header, index) => {
                  const classList = ['bd-t-0-f'];

                  if (header.id === 'common.status' || header.id === 'common.actions') {
                    classList.push('wd-lg-250');
                  }

                  return (
                    <th className={classList.join(' ')} key={'transactions-th-' + index}>
                      {renderSort(header.id)}
                    </th>
                  )
                })}
              </tr>
              </thead>
              <tbody>
              {!ordersLoaded && (<>
                <tr>
                  {filteredHeaders.map((header, index) => (
                    <td key={'orders-placeholder-1-' + index}><Placeholder width={190}/></td>
                  ))}
                </tr>
                <tr>
                  {filteredHeaders.map((header, index) => (
                    <td key={'orders-placeholder-2-' + index}><Placeholder width={190}/></td>
                  ))}
                </tr>
              </>)}
              {orders.length > 0 && orders.map((entry, index) => (
                <OrdersTableRow
                  entry={entry}
                  account={account}
                  updateWallets={updateWallets}
                  mode={showMyOrders ? OrdersTableColumnMode.ONLY_MY : OrdersTableColumnMode.ONLY_ALL}
                  key={'table-orders-' + index + '-' + entry.creationTimestamp}
                />
              ))}
              {ordersLoaded && orders.length === 0 && (
                <tr>
                  <td colSpan={filteredHeaders.length}>
                    <Translate i18nKey={'pages.p2p.content.no-orders'}/>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </Element>

        {total > 10 && (
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faArrowLeft}/>}
            nextLabel={<FontAwesomeIcon icon={faArrowRight}/>}
            pageCount={total / 10}
            forcePage={page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            containerClassName={'pagination mt-4 justify-content-center'}
            activeClassName={'active'}
            onPageChange={(data) => handlePageChange(data.selected)}
            eventListener={isLoading ? '' : 'onClick'}
          />
        )}
      </div>
    )
  }
);

export default React.memo(OrdersTable);
