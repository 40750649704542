import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en/en.json';
import ru from './locales/ru/ru.json';
import zh from './locales/zh/zh.json';
import es from './locales/es/es.json';
import fr from './locales/fr/fr.json';
import hi from './locales/hi/hi.json';
import ar from './locales/ar/ar.json';
import pt from './locales/pt/pt.json';
import de from './locales/de/de.json';
import it from './locales/it/it.json';
import tr from './locales/tr/tr.json';
import {Language} from "@stores/language";

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  zh: {
    translation: zh,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  hi: {
    translation: hi,
  },
  ar: {
    translation: ar,
  },
  pt: {
    translation: pt,
  },
  de: {
    translation: de,
  },
  it: {
    translation: it,
  },
  tr: {
    translation: tr,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,

    fallbackLng: Language.EN,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
