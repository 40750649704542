import React from "react";
import {Ticker} from "@services/api";
import {Wallet} from "@services/wallet-api";
import {useTranslation} from "react-i18next";
import FormattedNumber from "@components/FormattedNumber";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/pro-regular-svg-icons";

export interface WalletListProps {
  allowedWallets?: Ticker[];
  wallets: Wallet[];
}

const WalletList = ({allowedWallets, wallets}: WalletListProps) => {
  const {t} = useTranslation();

  const getCardColor = (ticker: Ticker) => {
    let color = 'gold';

    switch (ticker) {
      case Ticker.USDT:
        color = 'success';
        break;
      case Ticker.ASG:
        color = 'warning';
        break;
      case Ticker.USDT_PROFIT:
        color = 'gold';
        break;
    }

    return color;
  };

  return (
    <>
      {wallets.filter(w => allowedWallets ? allowedWallets.indexOf(w.currency) !== -1 : true).map((w, index) => (
        <div className="col-md-6 col-lg-3 mb-4" key={'wallet-card-' + index}>
          <div className={'card card-gradient-' + getCardColor(w.currency) + ' ht-100p'}>
            <div className="card-body">
              <h5 className="mb-3">{t('wallet.title.' + w.currency)}</h5>
              <div className="wd-100p tx-right tx-18">
                <div>
                  <FormattedNumber
                    value={w.balance}
                    decimals={2}
                    floor={true}
                    postfix={w.currency}
                    postfixClass={'tx-bold'}
                  />
                </div>

                <div className="tx-gray-300">
                  <FontAwesomeIcon icon={faLock} className="mr-1"/>

                  <FormattedNumber
                    value={w.lockedBalance}
                    decimals={2}
                    floor={true}
                    postfix={w.currency}
                    postfixClass={'tx-bold'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default React.memo(WalletList);
