import React from "react";
import {Trans, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {AccountLinks} from "@pages/pages";
import terms from "@assets/files/AssetG-terms_of_use.pdf";

export interface TranslateProps {
  i18nKey: string;
}

function Translate({i18nKey}: TranslateProps) {
  return (
    <Trans
      i18nKey={i18nKey}
      components={{
        primary: <span className="tx-primary"/>,
        gold: <span className="tx-gold"/>,
        bold: <span className="tx-bold"/>,
        primaryBold: <span className="tx-bold tx-primary"/>,
        big: <span className="tx-24"/>,
        linkSettings: <Link to={AccountLinks.SETTINGS}/>,
        linkTgChat: <a href="https://t.me/aurous_finance_official" target="_blank" rel="noreferrer">Link</a>,
        linkDepositFaq: <a href="https://www.youtube.com/watch?v=aHcGgJ73WSA" target="_blank" rel="noreferrer">Link</a>,
        linkFaqPdf1: <a href="https://files.carrotquest.io/attachments/44027/782837b5-bad4-4aab-909a-55d781d72481/assetg_whitepaper_ru.pdf" target="_blank" rel="noreferrer">Link</a>,
        linkFaqPdf2: <a href="https://files.carrotquest.io/attachments/44027/be25e0e6-c820-4f62-836f-7765324bf90f/ru_assetg_presentation_3_.pdf" target="_blank" rel="noreferrer">Link</a>,
        linkWithdrawalFaq: <a href="https://www.youtube.com/watch?v=aHcGgJ73WSA" target="_blank" rel="noreferrer">Link</a>,
        linkTerms: <a href={terms} target="_blank" rel="noreferrer" className="tx-center">Link</a>,
        assetgFinance: <a href="https://aurous.finance/" target="_blank" rel="noreferrer" className="tx-center">Link</a>,
      }}
    />
  );
}

export default React.memo(withTranslation()(Translate));
