import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import useResponseHandler from "@hooks/useResponseHandler";
import confirmAlert from "@components/ConfirmAlert";
import {OrdersTableRowProps} from "@pages/account/p2p/orders/OrdersTableRow";
import {cancelOrder} from "@services/p2p-api";
import {faTimes} from "@fortawesome/pro-regular-svg-icons/faTimes";

export interface CancelOrderButtonProps {
  props: OrdersTableRowProps;
}

export default function CancelOrderButton({props}: CancelOrderButtonProps) {
  const {entry, updateWallets} = props;

  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleCancelWithdrawal = useCallback(() => {
    setLoading(true);

    confirmAlert({
      confirmation: t('alert.cancel-order'),
      okLabel: t('button.cancel-order'),
      okIcon: faTimes,
      okVariant: 'danger',
      cancelLabel: t('button.close'),
      cancelIcon: null,
      cancelVariant: 'secondary',
    })
      .then((success) => {
        if (!!success) {
          cancelOrder(entry.id)
            .then((response) => {
              setLoading(false);
              if (response.success) {
                handleResponse('alert.order-canceled');
                updateWallets();
              } else {
                handleResponse(response);
              }
            })
            .catch((response) => {
              setLoading(false);
              handleResponse(response);
            });
        } else {
          setLoading(false);
        }
      });
  }, [updateWallets, entry.id, handleResponse, t]);

  return (
    <>
      <button className="btn btn-sm btn-danger" onClick={handleCancelWithdrawal} disabled={isLoading}>
        <FontAwesomeIcon icon={isLoading ? faCircleNotch : faTimesCircle} spin={isLoading} className="mr-1"/>
        <span>{t('button.cancel-order')}</span>
      </button>
    </>
  );
}
