import fetchWithTimeout from "@util/fetchWithTimeout";
import {API_URL, handleGet, Paged, PromiseStatus, Ticker,} from "@services/api";

export interface P2PAccount {
  accountId: number;
  nickname: string;
}

export interface OrderCreateData {
  currencyFrom: Ticker;
  currencyFromAmount: number;
  currencyTo: Ticker;
  currencyToAmount: number;
}

export enum OrderStatus {
  OPEN = 'OPEN',
  EXECUTED = 'EXECUTED',
  CANCELED = 'CANCELED',
}

export interface Order {
  id: string;
  status: OrderStatus;
  creationTimestamp: string;
  updateTimestamp: string;
  creatorNickname: string;
  executorNickname: string | null;
  currencyFrom: Ticker;
  currencyFromAmount: number;
  currencyFromFeeAmount: number;
  currencyTo: Ticker;
  currencyToAmount: number;
  currencyToFeeAmount: number;
  currencyToAmountWithFee: number;
  currencyFromAmountWithFee: number;
  usdPrice: number;
}

export interface OrderListFilters {
  currencyFrom?: Ticker;
  currencyTo?: Ticker;
  minCurrencyFromAmount?: number;
  maxCurrencyFromAmount?: number;
  minCurrencyToAmount?: number;
  maxCurrencyToAmount?: number;
  minPrice?: number;
  maxPrice?: number;
  nickname?: string;
  statuses?: OrderStatus[];
}

export function getP2pAccount():PromiseStatus<P2PAccount | null> {
  return fetchWithTimeout(API_URL + '/api/v1/p2p/account/me', {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function saveP2pAccount(nickname: string):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/p2p/account/me', {
    method: 'POST',
    body: JSON.stringify({nickname}),
  }).then(data => handleGet(data));
}

export function createOrder(data: OrderCreateData):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/p2p/order/create', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleGet(data));
}

export function cancelOrder(id: string):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/p2p/order/cancel/' + id, {
    method: 'POST',
  }).then(data => handleGet(data));
}

export function executeOrder(id: string):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/p2p/order/execute/' + id, {
    method: 'POST',
  }).then(data => handleGet(data));
}

export function getOrderList(page: number, filters?: OrderListFilters, sort?: string[]):PromiseStatus<Paged<Order>> {

  let params = [
    'size=10',
    'page=' + page,
  ].join('&');

  if (sort && sort.length) {
    params += '&' + sort.join('&');
  } else {
    params += '&sort=creationTimestamp,desc';
  }

  const url = API_URL + '/api/v1/p2p/order/search?' + params;

  return fetchWithTimeout(url, {
    method: 'POST',
    body: JSON.stringify(filters),
  }).then(data => handleGet(data));
}
