import {useTranslation} from "react-i18next";
import {useCallback} from "react";
import {toast} from "react-hot-toast";
import {useHistory} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {loginState} from "@stores/account";
import {StatusResponse} from "@services/api";

export default function useResponseHandler() {
  const {t} = useTranslation();
  const setLoggedIn = useSetRecoilState(loginState);
  const history = useHistory();

  return useCallback((response?: StatusResponse<any> | string, isError?: boolean) => {
    const toastOptions: {duration: number, id?: string} = {duration: 5000};
    let redirect = false;
    let isSessionExpired = false;
    const messages = [];

    if (response && typeof response !== 'string') {
      if (response.errors?.length) {
        isError = true;

        response.errors.forEach((error) => {
          if (error.code === 'error.account-not-enabled') {
            redirect = true;
          }

          messages.push(error.code);
        });
      }
      if (response.code === 403) {
        isError = true;
        messages.push('alert.session-expired');
        isSessionExpired = true;
        setLoggedIn(false);
      }
      if (!isError && response.code !== 200) {
        isError = true;
      }
    } else if (typeof response === 'string') {
      isError = isError || response.toLocaleLowerCase().indexOf('error') > -1 || response.toLocaleLowerCase().indexOf('err.') > -1;
      messages.push(response);
    }

    if (isSessionExpired) {
      toastOptions.id = 'session-expired';
    }

    if (!messages.length) {
      isError = true;
      messages.push('error.default')
    }

    if (isError) {
      if (!redirect) {
        messages.forEach((message) => {
          toast.error(t(message) + '', toastOptions);
        });
      } else {
        history.push({
          pathname: '/error',
          state: {messages},
        });
      }
    } else {
      messages.forEach((message) => {
        toast.success(t(message) + '', toastOptions);
      });
    }
  }, [t, setLoggedIn, history]);
}
