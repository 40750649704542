import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
  getReferralInfo,
  getReferralLineList,
  getReferralParents,
  ReferralInfo,
  ReferralLine, ReferralParent
} from "@services/referral-api";
import useResponseHandler from "@hooks/useResponseHandler";
import PageHeader from "@pages/account/components/PageHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReferralLevelStatistics from './components/ReferralLevelStatistics';
import FormattedNumber from "@components/FormattedNumber";
import ReferralNextLevelConditions from "@pages/account/referrals/components/ReferralNextLevelConditions";
import {useHistory, useLocation} from "react-router-dom";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import ReferralInfoView from "@pages/account/referrals/components/ReferralInfoView";
import Preloader from "@components/Preloader";
import ReferralRewards from "@pages/account/referrals/components/ReferralRewards";

interface ReferralsPageRedirectLocation {
  referralAccountId?: number;
  name?: string;
}

export default function ReferralsPage() {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation<ReferralsPageRedirectLocation>();
  const handleResponse = useResponseHandler();
  const [referralInfo, setReferralInfo] = useState<ReferralInfo>();
  const [referralLines, setReferralLines] = useState<ReferralLine[]>();
  const [parents, setParents] = useState<ReferralParent[]>();
  const [name, setName] = useState<string>('');

  useEffect(() => {
    const referralAccountId = location.state ? location.state.referralAccountId : undefined;

    if (location.state && location.state.name) {
      setName(location.state.name);
    }

    getReferralInfo(referralAccountId)
      .then((response) => {
        if (response.success && response.data) {
          setReferralInfo(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch(handleResponse);

    getReferralLineList(referralAccountId)
      .then((response) => {
        if (response.success && response.data) {
          setReferralLines(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch(handleResponse);

    if (referralAccountId) {
      getReferralParents(referralAccountId)
        .then((response) => {
          if (response.success && response.data) {
            setParents(response.data);
            console.log(response);
          } else {
            setParents([]);
          }
        })
        .catch(() => setParents([]));
    } else {
      setParents([]);
    }
  }, [location.state, handleResponse]);

  const header = <PageHeader
    title={
      !parents || !parents.length
        ? t('pages.referrals.title')
        : t('pages.referrals.title-of') + ' ' + name
    }/>

  if (!parents || !referralInfo || !referralLines) {
    return (
      <>
        {header}

        <Preloader inline={true}/>
      </>
    );
  }

  return (
    <>
      {header}

      {parents.length > 0 && (
        <div className="mt-3 mb-2">
          {parents.filter((p, i) => p.refId !== 'root' && i !== 0).map((parent, index) => {
            return (
              <span key={'referrals-tree-' + index + '-' + parent.accountId}>
                  <button
                    className="btn btn-link p-0 no-underline tx-left tx-bold"
                    onClick={() => {
                      history.push({
                        pathname: '/referrals',
                        state: index === 0 ? {} : {referralAccountId: parent.accountId, name: parent.fullName},
                      });
                    }}
                  >
                    {parent.fullName}
                  </button>

                  <FontAwesomeIcon icon={faChevronRight} className="mx-2"/>
                </span>
            )
          })}

          <span className="tx-normal">
            {name}
          </span>
        </div>
      )}

      {!parents.length && <ReferralInfoView referralInfo={referralInfo} isRoot={!parents.length}/>}

      <div className="d-md-flex flex-column flex-lg-row">
        <ReferralLevelStatistics referralInfo={referralInfo}/>
        {!parents.length && <ReferralRewards/>}
      </div>

      {!!referralInfo.parentFullName && !!referralInfo.parentRefId && referralInfo.parentRefId !== 'root' && (
        <h5>
          <span className="mr-1">
            {t(parents.length ? 'pages.referrals-level.table.invited-by' : 'common.invited-by')}:
          </span>
          <span className="tx-normal">
            {referralInfo.parentFullName} ({referralInfo.parentRefId})
          </span>
        </h5>
      )}

      <ReferralNextLevelConditions nextLevelRule={referralInfo.nextLevelRule}/>

      <div className="table-wrapper mt-3">
        <table className="table table-striped table-hover tx-center mb-0">
          <thead>
          <tr>
            <th className="bd-t-0-f">{t('pages.referrals.table.level')}</th>
            <th className="bd-t-0-f">{t('pages.referrals.table.percent')}</th>
            <th className="bd-t-0-f">{t('pages.referrals.table.referrals')}</th>
            <th className="bd-t-0-f">{t('common.turnover')}</th>
          </tr>
          </thead>
          <tbody>
          {referralLines.map((entry) => (
            <tr
              key={'referral-line-' + entry.line}
              className={entry.line <= referralInfo?.level ? 'table-active cur-pointer' : 'cur-pointer'}
              onClick={() => {
                history.push({
                  pathname: '/referrals/level/' + entry.line,
                  state: {referralInfo, referralAccountId: parents.length ? referralInfo.accountId : undefined},
                });
              }}
            >
              <td>{entry.line}</td>
              <td>{entry.rewardPercents}%</td>
              <td>{entry.referralsCount}</td>
              <td>
                <FormattedNumber
                  value={entry.turnover}
                  decimals={2}
                  floor={true}
                  postfix={'$'}
                />
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
