export default async function fetchWithTimeout(resource: string, options: any) {
  const updatedOptions = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    ...options,
  };

  const { timeout = 15 * 1000 } = options;
  const token = localStorage.getItem('authToken');

  if (token) {
    updatedOptions.headers = {
      ...updatedOptions.headers,
      'x-authorization': 'Bearer ' + token,
    };
  }

  if (typeof AbortController === 'function') {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
      ...updatedOptions,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  }

  return await fetch(resource, {
    ...updatedOptions,
  });
}
