import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {
  registerConfirm,
} from "@services/api";
import {AccountLinks, LandingLinks} from "@pages/pages";
import {Helmet} from "react-helmet-async";
import useResponseHandler from "@hooks/useResponseHandler";
import useQuery from "@hooks/useQuery";
import Preloader from "@components/Preloader";
import {useRecoilState, useSetRecoilState} from "recoil";
import {loginState, tokenState} from "@stores/account";

export default function RegisterConfirm() {
  const [isLoggedIn, setLoggedIn] = useRecoilState(loginState);
  const setAuthToken = useSetRecoilState(tokenState);
  const query = useQuery();
  const handleResponse = useResponseHandler();
  const [token, setToken] = useState<string>('');
  const [isSucceed, setSucceed] = useState<boolean | null>(null);

  useEffect(() => {
    const queryToken = query.get('token');
    if (queryToken && !token) {
      setToken(queryToken);
    }
  }, [query, token]);

  useEffect(() => {
    if (token) {
      registerConfirm({token})
        .then(response => {
          if (response.success && response.data?.token) {
            setLoggedIn(true);
            setAuthToken(response.data.token);
            setSucceed(true);
          } else {
            handleResponse(response, true);
            setSucceed(false);
          }
        })
        .catch((response) => {
          handleResponse(response, true);
          setSucceed(false);
        });
    }
  }, [token, setLoggedIn, setAuthToken, handleResponse]);

  if (isLoggedIn) {
    return (
      <Redirect to={AccountLinks.WALLET}/>
    )
  }

  if (isSucceed === false) {
    return (
      <Redirect to={LandingLinks.HOME}/>
    )
  }

  return (
    <>
      <Helmet>
        <title>Aurous | Подтверждение регистрации</title>
      </Helmet>
      <Preloader inline={true}/>
    </>
  );
}
