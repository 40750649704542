import React, {useContext, useMemo} from "react";
import Translate from "@components/Translate";
import {Accordion, AccordionContext, useAccordionToggle} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-light-svg-icons/faPlus";

export interface FaqBlock {
  title: string | JSX.Element;
  text: string | JSX.Element;
}

interface FaqToggleProps {
  children: string | JSX.Element;
  eventKey: string;
  callback?: any;
}

function FaqToggle({children, eventKey, callback}: FaqToggleProps) {
  const activeEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      className="btn btn-link accordion-link"
      onClick={decoratedOnClick}
    >
      <div className="d-flex justify-content-between tx-left">
        {children}
        <FontAwesomeIcon icon={faPlus} transform={{rotate: isCurrentEventKey ? 45 : 0.1}}/>
      </div>
    </button>
  );
}

export default function Faq() {
  const faqBlocks = useMemo(() => {
    let i = 1;
    const blocks: FaqBlock[] = []

    while (i <= 9) {
      blocks.push({
        title: <Translate i18nKey={'pages.faq.block-' + i + '.title'}/>,
        text: <Translate i18nKey={'pages.faq.block-' + i + '.text'}/>,
      })
      i++;
    }

    return blocks;
  }, []);

  return (
    <section className="container pb-5" id="faq">
      <h1 className="mb-6 tx-center">FAQ</h1>

      <div>
        <Accordion>
          {faqBlocks.map((block, key) => (
            <div className="accordion-item" key={key}>
              <FaqToggle eventKey={key + ''}>
                {block.title}
              </FaqToggle>
              <Accordion.Collapse eventKey={key + ''}>
                <div className="tx-16 tx-normal pb-4">
                  {block.text}
                </div>
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      </div>
    </section>
  );
}
