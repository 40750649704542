import React, {useCallback, useEffect, useMemo, useState} from 'react';
import bg from '@assets/images/bg.jpg';
import {Link, Redirect, useLocation} from 'react-router-dom';
import {AccountLinks, LandingLinks} from "@pages/pages";
import {
  AuthData,
  login, loginTfa, TFAAuthData
} from "@services/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet-async";
import useResponseHandler from "@hooks/useResponseHandler";
import {RedirectLocation} from "@helpers/types";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";
import {useRecoilState, useSetRecoilState} from "recoil";
import {loginState, tokenState} from "@stores/account";
import {useTranslation} from "react-i18next";
import PasswordInput from "@components/common/inputs/PasswordInput";

export default function Login() {
  const {t} = useTranslation();
  const [isLoggedIn, setLoggedIn] = useRecoilState(loginState);
  const setAuthToken = useSetRecoilState(tokenState);
  const handleResponse = useResponseHandler();
  const location: RedirectLocation = useLocation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [isProcessingLogin, setProcessingLogin] = useState<boolean>(false);
  const [tfaEnabled, setTfaEnabled] = useState<boolean>(false);

  const emailRx = useMemo(() => {
    return RegExp(/(.+)@(.+){2,}\.(.+){2,}/)
  }, []);

  useEffect(() => {
    if (location.state && location.state.phone) {
      setEmail(location.state.phone);
    }
  }, [location]);

  const isEmailValid = useCallback(() => {
    if (email.length < 6 || !(emailRx.test(email))) {
      handleResponse('error.email-not-valid');
      return false;
    }

    return true;
  }, [email, emailRx, handleResponse]);

  const loginHandler = useCallback(() => {
    if (!isEmailValid()) {
      return;
    }

    if (!password || (tfaEnabled && !code)) {
      handleResponse('error.empty-fields');
      return;
    }

    setProcessingLogin(true);

    const authData: TFAAuthData | AuthData = tfaEnabled
      ? {email, password, code}
      : {email, password};

    const loginFunction = tfaEnabled ? loginTfa : login;

    loginFunction(authData as TFAAuthData)
      .then((response) => {
        setProcessingLogin(false);

        if (response.success) {
          if (response.data?.token) {
            setAuthToken(response.data.token);
            setLoggedIn(true);
          } else if (response.data?.tfaEnabled) {
            setTfaEnabled(true);
          }
        } else {
          handleResponse(response, true);
        }
      })
      .catch((response) => {
        handleResponse(response, true);
        setProcessingLogin(false);
      });
  }, [code, tfaEnabled, email, password, setLoggedIn, setAuthToken, handleResponse, isEmailValid]);

  if (isLoggedIn) {
    return (
      <Redirect to={AccountLinks.WALLET}/>
    )
  }

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.login.title')}</title>
      </Helmet>
      <div id="login" className="flex flex-1 bg-image-center bg-image-cover bg-overlay p-md-5 pt-navbar align-items-center pb-5"
           style={{backgroundImage: `url(${bg})`}}>

        <div className="container">
          <div id="login-form" className="wd-100p wd-md-500">
            <h2 className="mb-4">{t('pages.login.content.heading')}</h2>

            <form onSubmit={(e) => {e.preventDefault(); loginHandler()}}>
              <div className="input-group input-group-lg mb-4">
                <div className="input-group-prepend">
                  <div className="input-group-text"><FontAwesomeIcon icon={faEnvelope}/></div>
                </div>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  placeholder={t('input.email')}
                  value={email}
                  name="email"
                  id="email"
                  onChange={event => {
                    const value = event.target.value;

                    if (value.length - email.length < 3) {
                      setEmail(value)
                    } else {
                      if (emailRx.test(value)) {
                        setEmail(value);
                      } else {
                        setEmail(email);
                      }
                    }
                  }}
                  disabled={isProcessingLogin || tfaEnabled}
                  autoComplete="email"
                />
              </div>

              <PasswordInput
                groupClassName="mb-4"
                isLg={true}
                onChange={setPassword}
                disabled={isProcessingLogin || tfaEnabled}
              />

              {tfaEnabled && (
                <div className="input-group input-group-lg mb-4">
                  <div className="input-group-prepend">
                    <div className="input-group-text"><FontAwesomeIcon icon={faKey}/></div>
                  </div>
                  <input
                    type="number"
                    className="form-control form-control-lg"
                    placeholder={t('input.auth-code')}
                    value={code}
                    disabled={isProcessingLogin || !tfaEnabled}
                    onChange={event => setCode(event.target.value)}
                  />
                </div>
              )}

              <button
                className="btn btn-lg btn-primary wd-100p mb-4"
                type="submit"
                disabled={isProcessingLogin}
              >
                {isProcessingLogin && (<FontAwesomeIcon icon={faCircleNotch} className="mr-2" spin/>)}
                {t('button.login')}
              </button>

              <div className="mb-2">
                {t('pages.login.content.no-account')} <Link to={LandingLinks.REGISTER}>{t('button.register')}</Link>
              </div>

              <Link to={LandingLinks.FORGOT}>{t('button.forgot-password')}</Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
