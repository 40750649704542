import React, {useMemo} from "react";
import formatNumber from "@util/format-number";
import Placeholder from "@components/Placeholder";
import {Ticker} from "@services/api";

export interface TranslateProps {
  value: string | number | undefined;
  decimals?: number;
  floor?: boolean;
  thousandSeparator?: string;
  decimalsSeparator?: string;
  className?: string;
  sign?: boolean;
  postfix?: string;
  postfixClass?: string;
  dropZeros?: boolean;
  suffix?: string;
  suffixClass?: string;
}

const FormattedNumber = (props: TranslateProps) => {
  const {
    value,
    decimals,
    floor,
    thousandSeparator = ',',
    decimalsSeparator = '.',
    className,
    sign,
    postfix,
    postfixClass,
    dropZeros = false,
    suffix,
    suffixClass,
  } = props;

  const postfixToSign = useMemo(() => {
    let result = postfix;

    switch (postfix) {
      case Ticker.USDT_PROFIT:
        result = Ticker.USDT;
        break;
    }

    return result;
  }, [postfix]);

  if (value === undefined) {
    return (<Placeholder/>);
  }

  return (
    <span className={className}>
      {!!suffix && (
        <span className={suffixClass}>
          {suffix}
        </span>
      )}

      {sign && value > 0 && '+'}
      {formatNumber(value, decimals, floor, thousandSeparator, decimalsSeparator, dropZeros)}
      {!!postfixToSign && (
        <span className={postfixClass}>
          {['$', '%'].indexOf(postfixToSign) > -1 ? '' : ' '}
          {postfixToSign}
        </span>
      )}
    </span>
  );
}

export default React.memo(FormattedNumber);
