import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import TransactionTooltip from "./components/TransactionTooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import {toast} from "react-hot-toast";
import {tableHeaders} from "./TransactionsTable";
import {Ticker, Transaction, TransactionType} from "@services/api";
import FormattedNumber from "@components/FormattedNumber";
import FormattedDate from "@util/FormattedDate";
import TransactionDetails from "@pages/account/components/transactions/components/TransactionDetails";

export interface TransactionsTableRowProps {
  entry: Transaction;
  updateTable: () => void;
}

export default function TransactionsTableRow(props: TransactionsTableRowProps) {
  const {t} = useTranslation();
  const {entry} = props;
  const negativeTypes = [
    TransactionType.WITHDRAWAL,
    TransactionType.BUY_CONTRACT,
    TransactionType.LOCK_BALANCE,
    TransactionType.SUBTRACT,
  ];
  const p2pTypes = [
    TransactionType.LOCK_BALANCE,
    TransactionType.UNLOCK_BALANCE,
    TransactionType.ADD,
    TransactionType.SUBTRACT,
  ];

  const renderQuantity = () => {
    const elements:Array<JSX.Element> = [];
    const multiplier = negativeTypes.indexOf(entry.type) > -1 ? -1 : 1;
    const isP2p = p2pTypes.indexOf(entry.type) > -1;

    if (entry.amount && entry.currency) {
      elements.push(
        <FormattedNumber
          value={entry.amount * multiplier}
          decimals={2}
          floor={true}
          sign={true}
          postfix={entry.currency}
        />
      );
    }
    if (!isP2p && entry.usd && entry.currency && entry.currency !== Ticker.USDT) {
      elements.push(
        <FormattedNumber
          value={entry.usd * multiplier}
          sign={true}
          postfix={'$'}
        />
      );
    }

    return (
      <div className={multiplier > 0 ? 'tx-success' : 'tx-danger'}>
        {elements.map((el, index) => (
          <div key={entry.creationTimestamp + entry.type + index}>
            {el}
          </div>
        ))}
      </div>
    );
  };

  const handleCopy = useCallback(() => {
    toast.success(t('alert.copied') + '', {duration: 5000});
  }, [t]);

  const metaInfo = () => {
    let result = null;

    if (entry.type === TransactionType.LOCK_BALANCE && entry.metadata?.lockReason) {
      result = <>({t('transaction.lockReason.' + entry.metadata.lockReason)})</>;
    }

    if (entry.type === TransactionType.UNLOCK_BALANCE && entry.metadata?.unlockReason) {
      result = <>({t('transaction.unlockReason.' + entry.metadata.unlockReason)})</>;
    }

    return <div className="tx-gray-500">{result}</div>;
  };

  return (
    <tr>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[0])}
        </div>
        <FormattedDate date={entry.creationTimestamp}/>
        {/*<TransactionButton commonProps={props} type={TransactionButtonType.WITHDRAWAL_CANCEL}/>*/}
      </td>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[1])}
        </div>
        <span className="text-overflow-sm wd-150">
          {t('transaction.type.' + entry.type)}
          {metaInfo()}
        </span>
      </td>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[2])}
        </div>
        {renderQuantity()}
      </td>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[3])}
        </div>

        {t('wallet.title.' + entry.walletCurrency)}
      </td>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[4])}
        </div>
        <CopyToClipboard text={entry.id} onCopy={handleCopy}>
          <button className="btn btn-link p-0 tx-left">
            <FontAwesomeIcon icon={faCopy}/> {entry.id.split('-')[0]}
          </button>
        </CopyToClipboard>
      </td>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[5])}
        </div>
        <div className="d-flex flex-row justify-content-lg-between d-lg-inline">
          <TransactionTooltip type={entry.type} status={entry.status}/>
          <span>{t('transaction.status.' + entry.status)}</span>
          {/*<TransactionButton commonProps={props} type={TransactionButtonType.WITHDRAWAL_STATUS_INFO}/>*/}
          {/*<TransactionButton commonProps={props} type={TransactionButtonType.DETAILS}/>*/}
        </div>
      </td>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[6])}
        </div>

        <TransactionDetails entry={entry}/>
      </td>
    </tr>
  );
}
