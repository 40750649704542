import React from "react";
import {useTranslation} from "react-i18next";
import RoadMapArrow from "@pages/landing/home/components/components/RoadMapArrow";
import Translate from "@components/Translate";

export default function RoadMap() {
  const {t} = useTranslation();

  return (
    <section className="container-fluid px-md-0 pt-lg-0 pb-lg-4 pt-md-2 pb-md-4 pt-sm-4 pb-sm-4 pt-4 pb-4" id="road-map">
      <h2 className="tx-56 tx-center">{t('pages.home.content.road-map.title')}</h2>
      <h3 className="tx-16 tx-center mb-5">
        <Translate i18nKey={'pages.home.content.road-map.text'}/>
      </h3>

      <div className="d-flex justify-content-end mt-3 mt-md-5 mt-sm-5 mt-sm-5">
        <RoadMapArrow/>
      </div>
    </section>
  );
}
