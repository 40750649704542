import {Helmet} from "react-helmet-async";
import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";

export interface PageHeaderProps {
  title: string;
  header?: ReactNode;
}

export default function PageHeader({title, header}: PageHeaderProps) {
  const {t} = useTranslation();

  return (
    <>
      <Helmet>
        <title>Aurous | {t(title)}</title>
      </Helmet>

      <h1 className="mb-4">{header ? header : t(title)}</h1>
    </>
  )
}
