import fetchWithTimeout from "@util/fetchWithTimeout";
import {ResetData} from "@services/settings-api";

export const API_URL: string = (process.env.REACT_APP_API_URL as string);

export enum Status {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type OrEmpty<T> = T | '';
export type OrNull<T> = T | null;
export type PromiseStatus<T> = Promise<StatusResponse<T | null>>;

export interface ServerError {
  code: string;
  message: string;
}

export interface ServerResponse<OrNull> {
  success: boolean;
  data?: OrNull;
  errors?: Array<ServerError> | null;
}

export interface StatusResponse<OrNull> extends ServerResponse<OrNull> {
  code: number;
}

export interface Email {
  email: string;
}

export interface Code {
  code: string;
}

export interface Password {
  password: string;
}

export interface Hash {
  hash: string;
}

export interface FullName {
  firstName: string;
  lastName: string;
}

export interface AuthData extends Email, Password {}

export interface TFAAuthData extends AuthData, Code {}

export interface TFAData extends Code, Password {}

export interface RegisterData extends AuthData, FullName {
  invitedBy: string;
}

export interface TokenData {
  token: string;
}

export interface LoginData {
  token?: string;
  tfaEnabled?: true;
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Sort {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export enum TransactionType {
  DEPOSIT = 'DEPOSIT',
  ADMIN_DEPOSIT = 'ADMIN_DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  // WITHDRAWAL_GMT = 'WITHDRAWAL_GMT',
  // PUT_ON_STAKE = 'PUT_ON_STAKE',
  // BUY = 'BUY',
  // REFERRAL_PAYOUT = 'REFERRAL_PAYOUT',
  // STAKE_PAYOUT = 'STAKE_PAYOUT',
  // MANUAL_DEPOSIT_USD = 'MANUAL_DEPOSIT_USD',
  // INTERNAL_DEPOSIT_USD = 'INTERNAL_DEPOSIT_USD',
  // MANUAL_DEPOSIT_AVAILABLE_TOKENS = 'MANUAL_DEPOSIT_AVAILABLE_TOKENS',
  // MANUAL_DEPOSIT_STAKED_TOKENS = 'MANUAL_DEPOSIT_STAKED_TOKENS',
  // MANUAL_DEPOSIT_LOCKED_REWARD_TOKENS = 'MANUAL_DEPOSIT_LOCKED_REWARD_TOKENS',
  // DEPOSIT_TOKENS = 'DEPOSIT_TOKENS',
  // WITHDRAW_TOKENS = 'WITHDRAW_TOKENS',
  // WAITING_CONFIRMATION = 'WAITING_CONFIRMATION',
  // UNSTAKE_TOKENS = 'UNSTAKE_TOKENS',
  // TICKET_BUY = 'TICKET_BUY',
  // RANK_BONUS = 'RANK_BONUS',
  // DEBIT_PRIORITY_REWARD_TOKENS = 'DEBIT_PRIORITY_REWARD_TOKENS',
  // DEBIT_REWARD_TOKENS = 'DEBIT_REWARD_TOKENS',
  // DEBIT_STAKE_TOKENS = 'DEBIT_STAKE_TOKENS',
  // DEBIT_AVAILABLE_TOKENS = 'DEBIT_AVAILABLE_TOKENS',
  // DEBIT_USD = 'DEBIT_USD',
  BUY_CONTRACT = 'BUY_CONTRACT',
  CONTRACT_PAYOUT = 'CONTRACT_PAYOUT',
  REFERRAL_REWARD = 'REFERRAL_REWARD',
  LOCK_BALANCE = 'LOCK_BALANCE',
  UNLOCK_BALANCE = 'UNLOCK_BALANCE',
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT',
}

export enum TransactionStatus {
  COMPLETE = 'Complete',
  ERROR = 'Error',
  IN_PROGRESS = 'In progress',
  WAITING_FOR_CONFIRMATIONS = 'Waiting for confirmation',
}

export enum Network {
  BITCOIN = 'BITCOIN',
  ETHEREUM = 'ETHEREUM',
  LITECOIN = 'LITECOIN',
  BITCOIN_CASH = 'BITCOIN_CASH',
  ETHEREUM_CLASSIC = 'ETHEREUM_CLASSIC',
}

export enum Ticker {
  ASG = 'ASG',
  BNB = 'BNB',
  GMT = 'GMT',
  BTC = 'BTC',
  LTC = 'LTC',
  BCH = 'BCH',
  ETH = 'ETH',
  ETC = 'ETC',
  USDT = 'USDT',
  USDT_PROFIT = 'USDT_PROFIT',
}

export enum TranscationLockReason {
  ORDER = 'ORDER',
}

export enum TranscationUnockReason {
  ORDER_CANCELLATION = 'ORDER_CANCELLATION',
}

export interface Crypto {
  title: string;
  decimals: number;
  ticker: Ticker;
  withdrawalFee: number;
  minWithdrawalAmount: number;
  orderFee: number;
}

export interface TransactionMetadata {
  withdrawalAddress?: string;
  transactionHash?: string;
  contractTemplateName?: string;
  rewardLine?: number;
  usdBuyPrice?: number;
  lockReason?: TranscationLockReason;
  unlockReason?: TranscationUnockReason;
}

export interface Transaction {
  id: string;
  hash: string;
  type: TransactionType;
  status: TransactionStatus;
  creationTimestamp: string;
  updateTimestamp: string;
  amount: number | null;
  usd: number | null;
  currency: Ticker | null;
  balanceType: string;
  walletId: string | null;
  walletCurrency: Ticker;
  metadata: TransactionMetadata | null;
}

export interface Transactions {
  content: Transaction[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface PagedProps {
  page?: number;
  size?: number;
  sort?: string;
}

export interface Paged<T> {
  content: T[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface Bank {
  id: string;
  title: string;
}

export interface Limit {
  crypto: Crypto;
  fiat: string;
  minSellPrice: number | null;
  minBuyPrice: number | null;
  maxSellPrice: number | null;
  maxBuyPrice: number | null;
}

export interface RefInfo {
  accountId: number;
  firstName: string;
  lastName: string;
}

const defaultError = [{code: 'error.default', message: 'Error!'}];

export function handleData<T>(data: Response) {
  return data.json()
    .then((json: ServerResponse<T>) => {return {
      code: data.status,
      ...json,
    }})
    .catch(() => {return {success: false, data: null, errors: defaultError, code: data.status}});
}

export function handleGet<T>(data: Response):PromiseStatus<T> {
  return data.json()
    .then((json: T) => {return {
      data: json,
      success: data.ok,
      code: data.status,
      errors: null,
      ...json,
    }})
    .catch(() => {return {success: false, data: null, errors: defaultError, code: data.status}});
}

export function handleEmptyResponse(data: Response) {
  if (data.ok && data.status === 200) {
    return {success: data.ok, code: data.status, data: null, ...data};
  }

  return {success: false, code: data.status, errors: defaultError, data: null, ...data};
}

export function register(data: RegisterData):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/account/registration', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}

export function checkReferral(refId: string):PromiseStatus<RefInfo> {
  return fetchWithTimeout(API_URL + '/api/v1/referral/' + refId + '/search', {
    method: 'POST',
  }).then(data => handleData(data));
}

export function registerConfirm(data: TokenData):PromiseStatus<TokenData> {
  return fetchWithTimeout(API_URL + '/api/v1/account/registration/confirm', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}

export function resendCode(data: {email: string}):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/account/registration/resend', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}

export function login(data: AuthData):PromiseStatus<LoginData> {
  return fetchWithTimeout(API_URL + '/api/v1/account/login', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}

export function loginTfa(data: TFAAuthData):PromiseStatus<LoginData> {
  return fetchWithTimeout(API_URL + '/api/v1/account/login/tfa', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}

export function emailSubscribe(email: string):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/subscribe', {
    method: 'POST',
    body: JSON.stringify({email})
  }).then(data => handleData(data));
}

export function resetPassword(data: ResetData):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/account/reset', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}

export function getTransactions(page: number, types: TransactionType[] = []):PromiseStatus<Paged<Transaction>> {
  let url = API_URL + '/api/v1/wallet/transaction?size=10&sort=creationTimestamp,desc&page=' + page;

  if (types.length) {
    url += '&type=' + types.join(',');
  }

  return fetchWithTimeout(url, {
    method: 'GET',
  }).then(data => handleGet(data));
}

export function requestReset(data: Email):PromiseStatus<null> {
  return fetchWithTimeout(API_URL + '/api/v1/account/reset/send', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then(data => handleData(data));
}
