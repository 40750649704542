import React from 'react';
import formatNumber from "@util/format-number";
import Placeholder from "@components/Placeholder";
import {useTranslation} from "react-i18next";

export interface ValueOfProps {
  value?: number;
  of?: number;
  text?: string;
  sign?: string;
  format?: boolean;
  fixed?: number;
  floor?: boolean;
  asLi?: boolean;
  hideValue?: boolean;
  className?: string;
  valueClass?: string;
}

export default function ValueOf(
  {value, of, text, sign = '', format, fixed = 2, floor = false, asLi, hideValue = false, className, valueClass}: ValueOfProps
) {
  const {t} = useTranslation();
  const formattedValue = format && value ? formatNumber(value, fixed, floor) : value;
  const formattedOf = format && of ? formatNumber(of, fixed, floor) : of;
  const content = (
    <span>
      {text ? text + ' ' : null}
      <span className={'tx-bold ' + (valueClass || '')}>
        {!hideValue && (<>{formattedValue}{sign}</>)}
        {
          of
            ? (!hideValue ? ' ' + t('common.of') + ' ' : '') + formattedOf + sign
            : ''
        }
      </span>
    </span>
  );

  if (value === undefined) {
    return (<Placeholder/>);
  }

  return asLi ? (
    <li className={of && value >= of ? className + ' tx-primary' : className}>
      {content}
    </li>
  ) : (
    <div className={of && value >= of ? className + ' tx-primary' : className}>
      {content}
    </div>
  );
}
