import React from "react";
import Translate from "@components/Translate";
import pic from "@assets/images/video.jpg";
// import {Link} from "react-router-dom";
// import {LandingLinks} from "@pages/pages";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {loginState} from "@stores/account";

import {SRLWrapper} from 'simple-react-lightbox';
import docNew from "@assets/images/documents/doc-new.jpg";
import doc1 from "@assets/images/documents/doc1.jpeg";
import doc2 from "@assets/images/documents/doc2.jpeg";
import doc3 from "@assets/images/documents/doc3.jpeg";
import doc4 from "@assets/images/documents/doc4.jpeg";
import doc5 from "@assets/images/documents/doc5.jpeg";
import doc6 from "@assets/images/documents/doc6.jpeg";
import doc7 from "@assets/images/documents/doc7.jpeg";
import doc8 from "@assets/images/documents/doc8.jpeg";
// import doc9 from "@assets/images/documents/doc9.jpeg";
import docEco from "@assets/images/documents/doc-eco.jpg";
// import certificate from "@assets/images/documents/certificate.jpg";

export default function AboutUs() {
  const {t} = useTranslation();
  const isLoggedIn = useRecoilValue(loginState);

  const elements = [
    {src: doc1},
    {src: doc2},
    {src: docNew},
    {src: doc3},
    {src: doc4},
    {src: doc5},
    {src: doc6},
    {src: doc7},
    {src: doc8},
    // {src: doc9},
    {src: docEco},
    // {src: certificate},
  ];

  return (
    <section className="pt-lg-7 pb-lg-4 pt-md-2 pb-md-4 pt-sm-4 pb-sm-4 pt-4 pb-4 pos-relative" id="about-us">
      <div className="container pb-6 padding-top-dv">
        <h2 className="mb-md-5 mt-md-5 pt-md-3 tx-lg-48 padding-bottom-title">
        <Translate i18nKey={'pages.home.content.docAndVideo.title'}/>
        </h2>
        <div className="row gap-for-video">
            

            <div className="col-md-6 order-2 order-md-1">
                {/* <h2 className="mb-md-5 mt-md-5 pt-md-3 tx-lg-48">
                <Translate i18nKey={'pages.home.content.docAndVideo.title'}/>
                </h2> */}
                <div className="mb-5 wd-md-550" style={{ width: '100%' }}>
                <Translate i18nKey={'pages.home.content.docAndVideo.text'}/>
                </div>
                <SRLWrapper>
                    {/* <a href={elements[0].src} className="wd-150 btn btn-lg btn-primary pos-relative" target="_blank" rel="noreferrer">
                        <Translate i18nKey={'common.documents'}/>
                        <img src={elements[0].src} className="absolute-stretch" alt={'Document 1'}/>
                    </a> */}

                    {elements.filter((image, i) => i != 0).map((image, key) => (
                        <a href={image.src} key={'document-' + key} className="d-none">
                        <img src={image.src} className="absolute-stretch" alt={'Document ' + (key + 1)}/>
                        </a>
                    ))}
                </SRLWrapper>
            </div>


            <div className='col-md-6 order-1 order-md-2 align-items-center d-flex d-flex justify-content-center'>
                <div className="docAndVideoMain">
                    <div className="docAndVideo" id="main-video">

                      <iframe
                        src={t('pages.home.content.video')}
                        frameBorder="0"
                        title="AssetG Global"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                      />

                        {/* <img src={pic}/>
                        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2034 38.4087C29.6279 38.4087 38.0787 29.958 38.0787 19.5335C38.0787 9.10895 29.6279 0.658203 19.2034 0.658203C8.77887 0.658203 0.328125 9.10895 0.328125 19.5335C0.328125 29.958 8.77887 38.4087 19.2034 38.4087ZM18.8437 15.393C17.5104 14.6232 15.8437 15.5855 15.8437 17.1251L15.8437 22.0858C15.8437 23.6254 17.5104 24.5877 18.8437 23.8179L23.1399 21.3375C24.4732 20.5677 24.4732 18.6432 23.1399 17.8734L18.8437 15.393Z" fill="#ED930B" fill-opacity="0.4"/>
                        </svg> */}
                    </div>
                </div>
            </div>
            

        </div>
      </div>
    </section>
  );
}