import {atom} from "recoil";
import {OrEmpty, SortDirection, Ticker} from "@services/api";
import {OrderStatus} from "@services/p2p-api";

export const orderCurrencyFromFilter = atom<OrEmpty<Ticker>>({
  key: 'orderCurrencyFromFilter',
  default: Ticker.USDT,
});

export const orderStatusFilter = atom<OrEmpty<OrderStatus>>({
  key: 'orderStatusFilter',
  default: '',
});

export const orderDateSort = atom<OrEmpty<SortDirection>>({
  key: 'orderDateSort',
  default: '',
});

export const orderAmountSort = atom<OrEmpty<SortDirection>>({
  key: 'orderAmountSort',
  default: '',
});

export const orderPriceSort = atom<OrEmpty<SortDirection>>({
  key: 'orderPriceSort',
  default: '',
});

export const orderAmountFilter = atom<string[]>({
  key: 'orderAmountFilter',
  default: ['0', '5000'],
});

export const orderPriceFilter = atom<string[]>({
  key: 'orderPriceFilter',
  default: ['0', '2.5'],
});
