import React, {useCallback, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import useResponseHandler from "@hooks/useResponseHandler";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import {tfaDisable, tfaEnable, tfaSetup, TFASetupInfo} from "@services/settings-api";
import {faKey} from "@fortawesome/free-solid-svg-icons/faKey";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faUnlock} from "@fortawesome/free-solid-svg-icons/faUnlock";
import logo from "@assets/images/logo-bw.png";
import QRCode from "qrcode.react";
import {useAccountInfoRefresher} from "@hooks/recoil-hooks";
import {accountInfo} from "@stores/account";
import useLoadableValue from "@hooks/useLoadableValue";
import {useTranslation} from "react-i18next";
import CopyButton from "@components/common/CopyButton";

export default function Tfa() {
  const {t} = useTranslation();
  const handleResponse = useResponseHandler();
  const refreshAccountInfo = useAccountInfoRefresher();
  const account = useLoadableValue(accountInfo);
  const [show, setShow] = useState(false);
  const [secret, setSecret] = useState<TFASetupInfo | null>();
  const [code, setCode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const enabled = account ? account.tfaEnabled : null;

  const resetForm = useCallback(() => {
    setSecret(null);
    setCode('');
    setPassword('');
    setLoading(false);
  }, []);

  const handleClose = useCallback(() => {
    resetForm();
    setShow(false);
  }, [resetForm]);

  const handleShow = () => setShow(true);

  const handleSetup = useCallback(() => {
    setLoading(true);
    tfaSetup({password})
      .then((response) => {
        if (response.success && response.data) {
          setSecret(response.data);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [password, handleResponse]);

  const handleSave = useCallback(() => {
    setLoading(true);
    tfaEnable({code})
      .then((response) => {
        if (response.success) {
          resetForm();
          setShow(false);
          refreshAccountInfo();
          handleResponse(t('alert.tfa-enabled'));
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [code, handleResponse, resetForm, refreshAccountInfo, t]);

  const handleDisable = useCallback(() => {
    setLoading(true);
    tfaDisable({password, code})
      .then((response) => {
        if (response.success) {
          resetForm();
          setShow(false);
          refreshAccountInfo();
          handleResponse(t('alert.tfa-disabled'));
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response);
        setLoading(false);
      });
  }, [password, code, handleResponse, resetForm, refreshAccountInfo, t]);

  return (
    <>
      {!account && (
        <Button variant="secondary">
          <FontAwesomeIcon icon={faCircleNotch} className="mr-2" spin/> {t('common.loading')}
        </Button>
      )}
      {enabled === false && (
        <Button variant="success" onClick={handleShow}>
          <FontAwesomeIcon icon={faKey} className="mr-2"/> {t('pages.settings.tfa.enable')}
        </Button>
      )}
      {enabled === true && (
        <Button variant="danger" onClick={handleShow}>
          <FontAwesomeIcon icon={faKey} className="mr-2"/> {t('pages.settings.tfa.disable')}
        </Button>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {!enabled ? t('pages.settings.tfa.enable') : t('pages.settings.tfa.disable')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!secret && (
            <>
              <div className="input-group input-group-lg mb-0">
                <div className="input-group-prepend">
                  <div className="input-group-text"><FontAwesomeIcon icon={faLock}/></div>
                </div>
                <input
                  type="password"
                  className="form-control form-control-lg"
                  id="tfa-password"
                  value={password}
                  disabled={isLoading}
                  placeholder={t('input.password')}
                  onChange={event => setPassword(event.target.value)}
                />
              </div>
            </>
          )}
          {!enabled && secret && (
            <>
              <div className="tx-semibold tx-center tx-18 mb-3">
                {t('pages.settings.tfa.scan-qr')}
              </div>
              <div className="wd-100p tx-center">
                <QRCode value={secret.qrContent} size={250} imageSettings={{src: logo, width: 35, height: 35}}/>
              </div>
              <div className="mt-1 tx-center">
                <div className="tx-bold mb-1">{t('common.code')}:</div>
                <CopyButton text={secret.code}/>
              </div>
            </>
          )}
          {(enabled || secret) && (
            <div className="input-group input-group-lg mt-3 mb-0">
              <div className="input-group-prepend">
                <div className="input-group-text"><FontAwesomeIcon icon={faKey}/></div>
              </div>
              <input
                type="number"
                className="form-control form-control-lg"
                id="tfa-password"
                value={code}
                disabled={isLoading}
                placeholder={t('input.auth-code')}
                onChange={event => setCode(event.target.value)}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('button.close')}
          </Button>
          {!enabled && !secret && (
            <Button variant="success" onClick={handleSetup} disabled={!password || isLoading}>
              <FontAwesomeIcon icon={isLoading ? faCircleNotch : faPaperPlane} className="mr-2" spin={isLoading}/>
              {t('button.send')}
            </Button>
          )}
          {(!enabled && secret) && (
            <Button variant="success" onClick={handleSave} disabled={!password || !code || isLoading}>
              <FontAwesomeIcon icon={isLoading ? faCircleNotch : faSave} className="mr-2" spin={isLoading}/>
              {t('button.enable')}
            </Button>
          )}
          {enabled && (
            <Button variant="danger" onClick={handleDisable} disabled={!password || !code || isLoading}>
              <FontAwesomeIcon icon={isLoading ? faCircleNotch : faUnlock} className="mr-2" spin={isLoading}/>
              {t('button.disable')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
