import React, {useCallback, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {
  resetPassword,
} from "@services/api";
import {AccountLinks, LandingLinks} from "@pages/pages";
import {Helmet} from "react-helmet-async";
import useResponseHandler from "@hooks/useResponseHandler";
import useQuery from "@hooks/useQuery";
import bg from "@assets/images/bg.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {useRecoilValue} from "recoil";
import {loginState} from "@stores/account";
import {useTranslation} from "react-i18next";
import PasswordInput from "@components/common/inputs/PasswordInput";

export default function PasswordReset() {
  const {t} = useTranslation();
  const isLoggedIn = useRecoilValue(loginState);
  const query = useQuery();
  const handleResponse = useResponseHandler();
  const [token, setToken] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);

  const [newPassword, setNewPassword] = useState<string>('');
  const [newPassword2, setNewPassword2] = useState<string>('');

  useEffect(() => {
    const queryToken = query.get('token');
    if (queryToken && !token) {
      setToken(queryToken);
    }
  }, [query, token]);

  const submitForm = useCallback(() => {
    if (newPassword !== newPassword2) {
      handleResponse('error.new-password-doesnt-match', true);
      return;
    }

    if (newPassword.length < 8) {
      handleResponse('error.password-length-less-then-8', true);
      return;
    }

    setLoading(true);

    resetPassword({token, newPassword})
      .then((response) => {
        if (response.success) {
          setRedirectToLogin(true);
        } else {
          handleResponse(response, true);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response, true);
        setLoading(false);
      });
  }, [token, newPassword, newPassword2, handleResponse]);

  if (redirectToLogin) {
    return (
      <Redirect to={LandingLinks.LOGIN}/>
    )
  }

  if (isLoggedIn) {
    return (
      <Redirect to={AccountLinks.WALLET}/>
    )
  }

  return (
    <>
      <Helmet>
        <title>Aurous | Восстановить пароль</title>
      </Helmet>

      <form id="form-register" className="flex flex-1 bg-image-center bg-image-cover bg-overlay p-4 p-md-5 pt-navbar align-items-center pb-5"
            style={{backgroundImage: `url(${bg})`}}
            onSubmit={(e) => {e.preventDefault(); submitForm();}}>
        <div className="container">
          <div id="login-form" className="wd-100p wd-md-500">
            <h2 className="mb-4">{t('pages.forgot.content.heading')}</h2>

            <PasswordInput
              groupClassName="mb-4"
              isLg={true}
              disabled={isLoading}
              onChange={setNewPassword}
              placeholder={t('input.new-password') + ''}
            />

            <PasswordInput
              groupClassName="mb-4"
              isLg={true}
              disabled={isLoading}
              onChange={setNewPassword2}
              placeholder={t('input.new-password2') + ''}
            />

            <button
              className="btn btn-lg btn-primary wd-100p"
              type="submit"
              disabled={isLoading}
            >
              {isLoading && (<FontAwesomeIcon icon={faCircleNotch} className="mr-2" spin/>)}
              {t('button.recover-access')}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
