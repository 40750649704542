import {useSetRecoilState} from "recoil";
import {useCallback} from "react";
import {lastAccountUpdate} from "@stores/account";

export function useAccountInfoRefresher() {
  const setLastAccountInfoUpdate = useSetRecoilState(lastAccountUpdate);
  return useCallback(() => {
    setLastAccountInfoUpdate(Date.now());
  }, [setLastAccountInfoUpdate]);
}
