import React, {useMemo} from "react";
import {Contract, ContractStatus} from "@services/contracts-api";
import {useTranslation} from "react-i18next";
import FormattedNumber from "@components/FormattedNumber";
import FormattedDate from "@util/FormattedDate";
import {faHourglassHalf, faPlayCircle, faStop} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ContractItemProps {
  entry: Contract;
}

const ContractItem = ({entry}: ContractItemProps) => {
  const {t} = useTranslation();

  const statusIcon = useMemo(() => {
    switch (entry.status) {
      case ContractStatus.ACTIVE:
      case ContractStatus.MORATORIUM:
        return faPlayCircle;
      case ContractStatus.RETURN_IN_PROGRESS:
        return faHourglassHalf;
      case ContractStatus.FINISHED:
        return faStop;
    }
  }, [entry.status]);

  return (
    <tr>
      <td>{entry.name}</td>
      <td>
        <FontAwesomeIcon icon={statusIcon} className="mr-1"/>
        {t('contract.status.' + entry.status)}
      </td>
      <td>
        <FormattedNumber
          value={entry.usdPrice}
          postfix={'$'}
        />
      </td>
      <td>
        <FormattedDate date={entry.startDate}/>
      </td>
      <td>
        <FormattedDate date={entry.endDate}/>
      </td>
      <td>
        <FormattedNumber
          value={entry.monthlyProfitPercents}
          postfix={'%'}
        />
      </td>
    </tr>
  );
};

export default React.memo(ContractItem);
