import React, {useCallback} from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";

export interface CopyButtonProps {
  text: string;
  alertMessage?: string;
  element?: React.ReactNode;
}

export default function CopyButton({text, element, alertMessage = 'alert.copied'}: CopyButtonProps) {
  const {t} = useTranslation();

  const handleCopy = useCallback(() => {
    toast.success(t(alertMessage) + '', {duration: 5000});
  }, [t, alertMessage]);

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <button className="btn btn-link p-0 tx-left">
        <FontAwesomeIcon icon={faCopy}/> {element ? element : text}
      </button>
    </CopyToClipboard>
  );
}
