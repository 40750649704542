import {useEffect} from "react";
import {atom, selector, useRecoilState} from "recoil";
import {useTranslation} from "react-i18next";

export enum Language {
  RU = 'ru',
  EN = 'en',
  ZH = 'zh',
  ES = 'es',
  FR = 'fr',
  HI = 'hi',
  AR = 'ar',
  PT = 'pt',
  DE = 'de',
  IT = 'it',
  TR = 'tr',
}

export const languageAtom = atom<Language>({
  key: 'language',
  default: localStorage.getItem('i18nextLng')
    ? localStorage.getItem('i18nextLng') as Language
    : Language.EN,
});

export const currentLanguage = selector<Language>({
  key: 'currentLanguageState',
  get: ({get}) => {
    return get(languageAtom);
  },
  set: ({set}, newValue) => {
    set(languageAtom, newValue);
    localStorage.setItem('i18nextLng', newValue as Language);
  },
});

export default function LanguageStore() {
  const {i18n} = useTranslation();
  const [language, setLanguage] = useRecoilState(currentLanguage);

  useEffect(() => {
    const resolved = i18n.resolvedLanguage as Language;
    if (resolved !== language) {
      setLanguage(resolved);
    }
  }, [language, setLanguage, i18n]);

  return (
    <></>
  );
}
